import { TimelineCardProps } from "../../types/TimelineCard";
import SampleImg from "../../assets/sample_timeline.png";
import t1999 from "../../assets/timeline/1999.jpg";
import t2007 from "../../assets/timeline/2007.jpg";
import t2010 from "../../assets/timeline/2010.jpg";
import t2015 from "../../assets/timeline/2015.jpg";
import t2016 from "../../assets/timeline/2016.jpg";
import t2021 from "../../assets/timeline/2021.jpg";
import t2022 from "../../assets/timeline/2022.jpg";
//TODO : Rename image properly as above
import aos from "../../assets/timeline/hito.jpg";

const data: TimelineCardProps[] = [
  {
    image: `${aos}`,
    content: "Descubre nuestros inicios en el siguiente ",
    year: "No partimos de cero",
    link: "https://drive.google.com/file/d/1W7VyTWJgN3EfYQHHU0puXK6ZqGuzzy8I/view?usp=sharing",
  },
  {
    image: `${t1999}`,
    content:
      "Fortalecer la participación de las mujeres en el nivel local. | PADEM con el apoyo de COSUDE",
    year: "1999",
  },
  {
    image: `${t2007}`,
    content: "Municipio con rostro de mujer | PADEM con el apoyo de COSUDE",
    year: "2007",
  },
  {
    image: `${t2010}`,
    content: "Ojo con la violencia | PADEM con el apoyo de COSUDE",
    year: "2010",
  },
  {
    image: `${t2015}`,
    content:
      "Redes de lucha contra la violencia | Solidar Suiza Bolivia con el apoyo de COSUDE",
    year: "2015",
  },
  {
    image: `${t2016}`,
    content:
      "Proyecto: Vida Sin Violencia | Solidar Suiza Bolivia con el apoyo de COSUDE",
    year: "2016",
  },
  {
    image: `${t2021}`,
    content:
      "Proyecto: Vida Sin Violencia | Solidar Suiza Bolivia con el apoyo de Asdi",
    year: "2021",
  },
  {
    image: `${t2022}`,
    content:
      "Proyecto: Vida Digna Sin Violencia | Solidar Suiza Bolivia con el apoyo de Asdi",
    year: "2022",
  },
  {
    image: `${SampleImg}`,
    content:
      "Proyecto: Vida Digna Sin Violencia | Solidar Suiza Bolivia con el apoyo de Asdi",
    year: "2022",
  },
];

export default data;
