import { v4 as uuidv4 } from "uuid";
import { printLog } from "../../../../shared/utils";
import { BannerDetalle } from "../BannerInterface";

export const bannersFake = [
  {
    id: 1,
    titulo: "Descarga la guía Eduactivistas",
    urlRecurso: "https://docs.google.com/archivos/guia-eduactivistas.pdf",
    esDestacado: true,
    adjunto: null,
  },
  {
    id: 2,
    titulo: "Descarga el archivo de mujeres, una vida sin violencia",
    urlRecurso: "https://docs.google.com/archivos/mujeres.pdf",
    esDestacado: false,
    adjunto: null,
  },
  {
    id: 3,
    titulo: "Descarga nuestra última guía de trabajo",
    urlRecurso: "https://docs.google.com/archivos/guia-trabajo.pdf",
    esDestacado: false,
    adjunto: null,
  },
  {
    id: 4,
    titulo: "Descarga la guía Eduactivistas",
    urlRecurso: "https://docs.google.com/archivos/guia-eduactivistas-final.pdf",
    esDestacado: false,
    adjunto: null,
  },
];

export function useGetBanners(): () => Promise<any> {
  // const http = useHttp();
  // return async () => http.get("/banners", {});
  return async () => {
    const _banners = localStorage.getItem("banners");
    printLog("Banners obtenidos de localstorage > ", _banners);
    let banners;
    if (!_banners) {
      localStorage.setItem("banners", JSON.stringify(bannersFake));
      banners = bannersFake;
    } else {
      banners = JSON.parse(_banners);
    }
    return banners;
  };
}

export function useGetBannerById(): (bannerId: string) => Promise<any> {
  // const http = useHttp();
  // return async (bannerId: string) => http.get(`/banners/${bannerId}`, {});
  return async (bannerId: string) => {
    const _banners = localStorage.getItem("banners");
    printLog("Banners obtenidos de localstorage > ", _banners);
    const banners = JSON.parse(_banners!);
    return banners.find((item: any) => item.id === bannerId);
  };
}

export function useCreateBanner(): (payload: BannerDetalle) => Promise<void> {
  // const http = useHttp();
  return async (payload: BannerDetalle) => {
    const newBanner = {
      ...payload,
      id: uuidv4(),
    };
    printLog("[create:banner] >>> ", newBanner);
    const _banners = localStorage.getItem("banners");
    printLog("Campañas obtenidos de localstorage > ", _banners);
    const banners = _banners ? JSON.parse(_banners) : [];
    banners.push(newBanner);
    printLog("Campañas >>> ", banners);
    localStorage.setItem("banners", JSON.stringify(banners));
    // return await http.post(`/banners`, {
    //   payload: formData,
    //   withFiles: true,
    // });
  };
}

export function useUpdateBanner(): (
  payload: BannerDetalle,
  bannerId: string
) => Promise<any> {
  // const http = useHttp();
  return async (payload: BannerDetalle, bannerId: string) => {
    const _banners = localStorage.getItem("banners");
    printLog("banners obtenidos de localstorage > ", _banners);
    const banners = _banners ? JSON.parse(_banners) : [];
    const idxBanner = banners.findIndex((item: any) => item.id === bannerId);
    printLog("indice de banner >> ", idxBanner);
    banners[idxBanner] = { ...payload };
    printLog("banners actualizados >>> ", banners);
    localStorage.setItem("banners", JSON.stringify(banners));
    // return await http.put(`/banners/${bannerId}`, {
    //   payload: formData,
    //   withFiles: true,
    // });
  };
}

export function useDeleteBanner(): (bannerId: string) => Promise<void> {
  // const http = useHttp();
  return async (bannerId: string) => {
    const _banners = localStorage.getItem("banners");
    printLog("banners obtenidos de localstorage > ", _banners);
    const banners = _banners ? JSON.parse(_banners) : [];
    const idxBanner = banners.findIndex((item: any) => item.id === bannerId);
    printLog("indice de banner >> ", idxBanner);
    banners.splice(idxBanner, 1);
    localStorage.setItem("banners", JSON.stringify(banners));
    // return await http.delete(`/banners/${bannerId}`, {});
  };
}
