import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { InternUnit, useCourseContext } from "../context";
import { useEffect, useState } from "react";
import {
  EditRounded,
  DeleteOutlineRounded,
  AddRounded,
} from "@mui/icons-material";
import swal from "sweetalert";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { UnitData, UnidadFormType } from "./UnitData";
import { AdjuntosContenido, DialogContenidoForm } from "./DialogContenidoForm";
import { InstanciaUnidadCursoContenido } from "../interface";
import {
  CircleBox,
  CourseNeutralButton,
  CoursePrimaryButton,
  CoursePrimaryButtonText,
} from "../shared/Elements";
import unidadIcon from './../../../assets/course/subir_archivo.png';
import { printLog } from "../../../shared/utils";

type UnitsCourseContentProps = {
  onBack: () => void;
  onClose: () => void;
  onCreateOrUpdateCurso: (unidades: InternUnit[]) => void;
};

function UnitsCourseContent({
  onBack,
  onClose,
  onCreateOrUpdateCurso,
}: UnitsCourseContentProps) {
  const theme = useTheme();
  const [modalUnidadOpen, setModalUnidadOpen] = useState<boolean>(false);
  const [modalContenidoOpen, setModalContenidoOpen] = useState<boolean>(false);
  // Unidad que actualmente se esta editando
  const [idxUnidad, setIdxUnidad] = useState<number | null>(null);
  const [idxContenido, setIdxContenido] = useState<number | null>(null);
  // const [loadingBtn, setLoadingBtn] = useState(false);

  const [course, dispatch] = useCourseContext();
  const { unidades } = course;

  printLog("rendering component contenido");
  useEffect(() => {
    printLog("Montando Contenido Curso");
    return () => printLog("Desmontando Contenido Curso");
  }, []);

  const alertFail = (title: string, message: string) =>
    swal({
      buttons: ["Ok"],
      icon: "error",
      title: title,
      text: message,
      // btnText: "Ok",
      // message: message,
    });

  const openModalNuevaUnidad = () => {
    setModalUnidadOpen(true);
  };

  const openModalUnidadExistente = (idxUnidad: number) => {
    setIdxUnidad(idxUnidad);
    setModalUnidadOpen(true);
    printLog("editando valores unidad :: ", unidades[idxUnidad]);
  };

  const handleEliminarUnidad = (_idxUnidad: number) => {
    swal(`¿Desea eliminar la unidad?.`, {
      buttons: ["Cancelar", "Confirmar"],
    }).then((value) => {
      if (value) {
        dispatch({ type: "DELETE_UNIDAD", idxUnidad: _idxUnidad });
      }
    });
  };

  const handleEditContenido = (_idxUnidad: number, _idxContenido: number) => {
    printLog(
      `ContenidoCurso::handleEditContenido. Unidad ${_idxUnidad}, Contenido: ${_idxContenido}`
    );
    setIdxUnidad(_idxUnidad);
    setIdxContenido(_idxContenido);
    setModalContenidoOpen(true);
  };

  const handleDeleteContenido = (_idxUnidad: number, _idxContenido: number) => {
    swal(`¿Desea eliminar el contenido?.`, {
      buttons: ["Cancelar", "Confirmar"],
    }).then((value) => {
      if (value) {
        dispatch({
          type: "DELETE_CONTENIDO",
          idxUnidad: _idxUnidad,
          idxContenido: _idxContenido,
        });
      }
    });
  };

  const handleOpenModalContenido = (_idxUnidad: number) => {
    setIdxUnidad(_idxUnidad);
    setIdxContenido(null);
    setModalContenidoOpen(true);
  };

  const handleCreateOrUpdateCurso = () => {
    // TODO: llevar a componente padre
    onCreateOrUpdateCurso(unidades);
  };

  const closeModalUnidad = () => {
    setModalUnidadOpen(false);
    setIdxUnidad(null);
  };

  const handleConfirmModalUnidad = (valoresUnidad: UnidadFormType) => {
    printLog("indice unidad :: ", idxUnidad);
    printLog("Valores unidad >>> ", valoresUnidad);
    const errorTitle = "No se puede generar la unidad";

    if (!valoresUnidad.titulo || valoresUnidad.titulo.trim() === "") {
      alertFail(errorTitle, "Al menos el título de la unidad es requerido");
      return;
    }

    if (idxUnidad !== null) {
      printLog("Editando unidad :: ", idxUnidad);
      dispatch({
        type: "EDIT_VALORES_UNIDAD",
        idxUnidad,
        payload: valoresUnidad,
      });
    } else {
      printLog("Agregando Unidad");
      dispatch({ type: "ADD_UNIDAD", payload: valoresUnidad });
    }

    closeModalUnidad();
  };

  const handleCloseModalContenido = () => {
    setModalContenidoOpen(false);
    setIdxUnidad(null);
    setIdxContenido(null);
  };

  const handleDialogResult = (
    contenido: InstanciaUnidadCursoContenido,
    adjuntos: AdjuntosContenido
  ) => {
    printLog("Contenido Recibido:", contenido);
    if (idxContenido === null) {
      handleCrearContenido(contenido, idxUnidad!, adjuntos);
    } else {
      handleEditarContenido(contenido, idxUnidad!, idxContenido, adjuntos);
    }
  };

  const handleCrearContenido = (
    data: InstanciaUnidadCursoContenido,
    _idxUnidad: number,
    _adjuntos: AdjuntosContenido
  ) => {
    printLog("Contenido Recibido:", data);
    printLog("Adjuntos recibidos", _adjuntos);

    dispatch({
      type: "SET_CONTENIDO",
      idxUnidad: idxUnidad!,
      contenido: data,
      adjuntos: _adjuntos,
    });
  };

  const handleEditarContenido = (
    data: InstanciaUnidadCursoContenido,
    _idxUnidad: number,
    _idxContenido: number,
    _adjuntos: AdjuntosContenido
  ) => {
    printLog("contenido modificado :: ", data);
    printLog("Adjuntos del contenido:", _adjuntos);
    dispatch({
      type: "SET_CONTENIDO",
      idxUnidad: _idxUnidad,
      idxContenido: _idxContenido,
      contenido: data,
      adjuntos: _adjuntos,
    });
  };

  return (
    <>
      <Box p={3}>
        <Box sx={{ textAlign: "center" }}>
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
          >
            <Box sx={{ height: '5rem', borderRadius: '10px', overflow: 'auto', display: 'flex' }}>
              <img src={unidadIcon} width={'100%'} height={'100%'} alt="unidad-icon" />
            </Box>
            <CoursePrimaryButtonText onClick={openModalNuevaUnidad}>
              <CircleBox sx={{ mr: 0.8 }}>
                <AddRounded sx={{ fontSize: "14pt" }} />
              </CircleBox>
              Nueva unidad
            </CoursePrimaryButtonText>
          </Box>
          <Box my={2}>
            <Box sx={{ pt: 3 }}>
              {unidades.length > 0 &&
                unidades.map((unidad, idxUnidad: number) => (
                  <Box key={idxUnidad}>
                    <Box display="flex" flexDirection="column">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            flex: 1,
                            color: theme.palette.info.main,
                            fontSize: "1.3rem",
                            textAlign: "justify",
                          }}
                        >
                          Unidad {idxUnidad + 1}: {unidad.titulo}
                        </Typography>
                        <IconButton
                          sx={{ fill: "red" }}
                          onClick={() => openModalUnidadExistente(idxUnidad)}
                        >
                          <EditRounded />
                        </IconButton>
                        <IconButton
                          sx={{ fill: "red" }}
                          onClick={() => handleEliminarUnidad(idxUnidad)}
                        >
                          <DeleteOutlineRounded />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          borderBottom: `1px solid ${theme.palette.neutral.main}`,
                        }}
                        pb={2}
                      >
                        <Typography
                          component={"p"}
                          sx={{ textAlign: "justify" }}
                        >
                          Objetivo: {unidad.objetivo}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" sx={{ pt: 2 }}>
                        <Typography component="p" sx={{ textAlign: "left" }}>
                          CONTENIDO
                        </Typography>
                        <Timeline
                          sx={{
                            display: "flex",
                            padding: 0,
                            margin: 0,
                            paddingTop: 2,
                          }}
                        >
                          {unidad.contenido &&
                            unidad.contenido.length > 0 &&
                            unidad.contenido.map(
                              ({ instancia: contenido }, idxContenido) => (
                                <TimelineItem
                                  key={idxContenido}
                                  sx={{
                                    // alignSelf: "flex-start",
                                    paddingLeft: 1.5,
                                    width: "100%",
                                    "&:before": {
                                      content: "initial",
                                    },
                                  }}
                                >
                                  <TimelineSeparator>
                                    <TimelineDot
                                      sx={{
                                        backgroundColor:
                                          theme.palette.info.main,
                                      }}
                                    />
                                    <TimelineConnector
                                      sx={{
                                        backgroundColor:
                                          theme.palette.info.main,
                                      }}
                                    />
                                  </TimelineSeparator>
                                  <TimelineContent
                                    sx={{ flexBasis: "auto", width: "100%" }}
                                  >
                                    <Box
                                      sx={{
                                        borderRadius: "8px",
                                        border: "1px solid #cacaca",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          component="p"
                                          sx={{ paddingLeft: 1 }}
                                        >
                                          {contenido.titulo}
                                        </Typography>
                                        <Box>
                                          <IconButton
                                            size="small"
                                            sx={{ fill: "red" }}
                                            onClick={() =>
                                              handleEditContenido(
                                                idxUnidad,
                                                idxContenido
                                              )
                                            }
                                          >
                                            <EditRounded />
                                          </IconButton>
                                          <IconButton
                                            size="small"
                                            sx={{ fill: "red" }}
                                            onClick={() =>
                                              handleDeleteContenido(
                                                idxUnidad,
                                                idxContenido
                                              )
                                            }
                                          >
                                            <DeleteOutlineRounded />
                                          </IconButton>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </TimelineContent>
                                </TimelineItem>
                              )
                            )}
                        </Timeline>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignContent: "flex-end",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        justifyItems: "flex-end",
                      }}
                    >
                      <CoursePrimaryButtonText
                        onClick={() => handleOpenModalContenido(idxUnidad)}
                      >
                        <CircleBox sx={{ mr: 0.8 }}>
                          <AddRounded sx={{ fontSize: "14pt" }} />
                        </CircleBox>
                        Contenido
                      </CoursePrimaryButtonText>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>

        <Box display="flex" gap={2} justifyContent="flex-end" mt={5}>
          <CourseNeutralButton onClick={onClose}>Cancelar</CourseNeutralButton>
          <CourseNeutralButton onClick={onBack}>Atrás</CourseNeutralButton>
          <CoursePrimaryButton onClick={handleCreateOrUpdateCurso}>
            {course && course.id ? "Guardar" : "Crear"}
          </CoursePrimaryButton>
        </Box>
      </Box>

      {/* Form Unidad */}
      {modalUnidadOpen && (
        <UnitData
          open={modalUnidadOpen}
          unidad={idxUnidad !== null ? unidades[idxUnidad] : undefined}
          onClose={closeModalUnidad}
          onConfirm={handleConfirmModalUnidad}
        ></UnitData>
      )}

      {/* Form Contenido */}
      {modalContenidoOpen && (
        <DialogContenidoForm
          open={modalContenidoOpen}
          onClose={handleCloseModalContenido}
          onSaveContenido={handleDialogResult}
          contenido={
            idxContenido !== null && idxUnidad !== null
              ? unidades[idxUnidad].contenido[idxContenido].instancia
              : undefined
          }
          adjuntosContenido={
            idxContenido !== null && idxUnidad !== null
              ? unidades[idxUnidad].contenido[idxContenido].adjuntos
              : {}
          }
        />
      )}
    </>
  );
}

export default UnitsCourseContent;
