import { Box, Typography, alpha, styled } from "@mui/material";
import bgTop from "../../../assets/HomeCourses/course_three.jpg";
import theme from "../../../themes";
import {
  CoursePrimaryButton,
  CourseWhiteButton,
} from "../../Courses/shared/Elements";
import AuthDialog, { AuthType } from "../../Auth";
import { useState } from "react";
import { useAuthContext } from "../../../shared/auth/AuthContext";
import { useHistory } from "react-router-dom";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  // height: 400,
  height: "70vh",
  backgroundImage: `url(${bgTop})`,
  backgroundAttachment: "fixed",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "fit-content",
    height: 500,
  },
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 1.5em 1.5em 1.5em",
  backgroundColor: alpha(theme.palette.info.dark, 0.65),
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const MainBanner = () => {
  const [authForm, setAuthForm] = useState(false);
  const [typeAuth, setTypeAuth] = useState<AuthType>();
  const authContext = useAuthContext();
  const history = useHistory();

  const handleOpenAuthForm = (type: AuthType) => {
    setTypeAuth(type);
    setAuthForm(true);
  };

  const handleCloseAuthForm = () => {
    setAuthForm(false);
  };

  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledDivMediaCaption>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            alignItems={"start"}
            gap={3}
            sx={{ height: "100%" }}
          >
            <Box mb={2}>
              <Typography
                variant="h1"
                style={{
                  lineHeight: "1",
                  fontFamily: "Barlow",
                  fontWeight: 400,
                  fontSize: 42,
                  color: theme.palette.common.white,
                }}
              >
                Cursos
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                Nuestros cursos están diseñados para brindarte herramientas
                prácticas y conocimientos fundamentales que te ayudarán a
                comprender, abordar de manera integral las causas y efectos de
                la violencia contra las mujeres y tomar acción.
              </Typography>
            </Box>
            {!authContext.user && (
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                mt={"1em"}
                gap={1.5}
                alignItems={"start"}
              >
                <CoursePrimaryButton
                  onClick={() => handleOpenAuthForm(AuthType.REGISTER)}
                >
                  Crear cuenta
                </CoursePrimaryButton>
                <CourseWhiteButton
                  onClick={() => handleOpenAuthForm(AuthType.LOGIN)}
                >
                  Iniciar sesión
                </CourseWhiteButton>
              </Box>
            )}
            {authContext.user && (
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                gap={1.5}
                alignItems={"start"}
              >
                <CoursePrimaryButton
                  onClick={() => {
                    const windowHeight = window.innerHeight;
                    const scrollPosition = windowHeight * 0.7;
                    window.scrollTo({
                      top: scrollPosition,
                      behavior: "smooth",
                    });
                  }}
                >
                  Explorar los cursos
                </CoursePrimaryButton>
                <CourseWhiteButton onClick={() => history.push("/cursos")}>
                  Mis cursos inscritos
                </CourseWhiteButton>
              </Box>
            )}
          </Box>
        </StyledDivMediaCaption>
      </Box>
      {authForm && (
        <AuthDialog
          open={authForm}
          onClose={handleCloseAuthForm}
          type={typeAuth}
        />
      )}
    </StyledBox>
  );
};

export default MainBanner;
