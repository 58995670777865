import { useEffect } from "react";
import Footer from "../../components/Footer";
import Layout from "../../components/Layout";
import MainBanner from "./components/MainBanner";
import News from "./components/News";
import useAppContext from "../../shared/hooks/useAppContext";
import { useLocation } from "react-router-dom";

const Hemeroteca = () => {
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [pathname, hash, key]);

  const { getNewsMedia, getNewsCategories, getNewsList } = useAppContext();

  useEffect(() => {
    getNewsMedia();
    getNewsCategories();
    // getPressList('');
    getNewsList("");
  }, [getNewsMedia, getNewsCategories, getNewsList]);

  return (
    <Layout>
      <MainBanner />
      {/* <Categories /> */}
      {/* <News /> */}
      <Footer />
    </Layout>
  );
};

export default Hemeroteca;
