import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
// import { colorsBase, DesignSystem } from '../theme';
import { colorsBase, DesignSystem } from '../../themes/theme';


export const ConfirmButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  fontSize: 16,
  padding: '10px 40px',
  border: '1px solid',
  color: "#FFFFFF",
  borderRadius: "40px",
  lineHeight: 1.5,
  cursor: 'pointer',
  backgroundColor: colorsBase.Verde1,
  borderColor: colorsBase.Verde1,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  '&:hover': {
    backgroundColor: colorsBase.Verde,
    borderColor: colorsBase.Verde,
    // boxShadow: 'none',
  },
  '&:active': {
    // boxShadow: 'none',
    backgroundColor: colorsBase.Verde,
    borderColor: colorsBase.Verde,
  },
  '&:disabled': {
    // boxShadow: 'none',
    backgroundColor: colorsBase.Gray,
    borderColor: colorsBase.Gray,
    cursor: 'not-allowed'
  },

  /// para responsive
  [theme.breakpoints.down('md')]: {
    padding: '4px 10px',
    ...DesignSystem?.cuerpo2,
    color: "#FFFFFF",
  },
}));


export const CancelButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  fontSize: 16,
  padding: '10px 20px',
  border: '1px solid',
  color: "#FFFFFF",
  lineHeight: 1.5,
  borderRadius: "40px",
  backgroundColor: colorsBase.Rojo1,
  borderColor: colorsBase.Rojo1,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  '&:hover': {
    backgroundColor: colorsBase.Rojo,
    borderColor: colorsBase.Rojo,
    // boxShadow: 'none',
  },
  '&:active': {
    // boxShadow: 'none',
    backgroundColor: colorsBase.Rojo,
    borderColor: colorsBase.Rojo,
  },

  /// para responsive
  [theme.breakpoints.down('md')]: {
    padding: '4px 10px',
    ...DesignSystem?.cuerpo2,
    color: "#FFFFFF",
  },
}));

export const CancelButtonGray = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  fontSize: 16,
  padding: '10px 20px',
  border: '1px solid',
  color: colorsBase.Texto2,
  lineHeight: 1.5,
  borderRadius: "40px",
  backgroundColor: colorsBase.DrawerGray,
  borderColor: colorsBase.DrawerGray,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  '&:hover': {
    backgroundColor: colorsBase.BorderGray,
    borderColor: colorsBase.TextoGray,
    // boxShadow: 'none',
  },
  '&:active': {
    // boxShadow: 'none',
    backgroundColor: colorsBase.BorderGray,
    borderColor: colorsBase.BorderGray,
  },

  /// para responsive
  [theme.breakpoints.down('md')]: {
    padding: '4px 10px',
    ...DesignSystem?.cuerpo2,
    color: colorsBase.TextoGray,
  },
}));

export const ActionButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  padding: '10px 30px',
  border: '1px solid',
  borderRadius: "8px",
  lineHeight: 1.5,
  cursor: 'pointer',
  backgroundColor: colorsBase.Verde1,
  borderColor: colorsBase.Verde1,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  ...DesignSystem?.cuerpo1,
  color: colorsBase.Blanco,
  '&:hover': {
    backgroundColor: colorsBase.Verde,
    borderColor: colorsBase.Verde,
  },
  '&:active': {
    backgroundColor: colorsBase.Verde,
    borderColor: colorsBase.Verde,
  },
  '&:disabled': {
    backgroundColor: colorsBase.Gray,
    borderColor: colorsBase.Rojo,
    cursor: 'not-allowed'
  },
  '&.MuiButton-sizeSmall': {
    padding: '6px 20px',
    ...DesignSystem?.cuerpo2,
    color: colorsBase.Blanco,
  },
  // MuiButton-containedSizeSmall
  /// para responsive
  [theme.breakpoints.down('md')]: {
    padding: '4px 10px',
    ...DesignSystem?.cuerpo2,
    color: colorsBase.Blanco,
  },
}));

export const FilterButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  padding: '10px 30px',
  border: '1px solid',
  borderRadius: "8px",
  lineHeight: 1.5,
  cursor: 'pointer',
  borderColor: colorsBase.DrawerGray,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  backgroundColor: colorsBase.DrawerGray,
  ...DesignSystem?.cuerpo1,
  color: "#FFFFFF",
  '&:hover': {
    backgroundColor: colorsBase.BorderGray,
    borderColor: colorsBase.BorderGray,
  },
  '&:active': {
    backgroundColor: colorsBase.BorderGray,
    borderColor: colorsBase.BorderGray,
  },
  '&:disabled': {
    backgroundColor: colorsBase.TextoGray,
    borderColor: colorsBase.TextoGray,
    cursor: 'not-allowed'
  },
  '&.MuiButton-sizeSmall': {
    backgroundColor: colorsBase.DrawerGray,
    padding: '8px 10px',
    ...DesignSystem?.cuerpo3,
  },
  '&.MuiButton-sizeSmall:hover': {
    backgroundColor: colorsBase.BorderGray,
    padding: '8px 10px',
    ...DesignSystem?.cuerpo3,
  },
  '&.MuiButton-sizeSmall.MuiButton-contained': {
    backgroundColor: colorsBase.Negro2,
    padding: '10px',
    ...DesignSystem?.cuerpo2,
    color: colorsBase.Blanco,
  },

  /// para responsive
  [theme.breakpoints.down('md')]: {
    padding: '4px 10px',
    ...DesignSystem?.cuerpo2,
  },
}));

