import {
  EditRounded,
  KeyboardBackspaceRounded as KeyboardBackspaceRoundedIcon,
} from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DesignSystem } from "../../../themes/theme";
import { printLog } from "../../../shared/utils";
import { useGetCampaigns } from "../campaign/data";
import { Campaign } from "../campaign/interface";

export const CommunicationResourcesPage = () => {
  const navigate = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const getCampaigns = useGetCampaigns();

  async function fetchCampaigns() {
    setLoading(true);
    const fetchedCampaigns = await getCampaigns("OTRO");
    printLog("campañas fetcheadas :: ", fetchedCampaigns);
    setCampaigns(fetchedCampaigns as unknown as []);
    setLoading(false);
  }

  useEffect(() => {
    fetchCampaigns();
    return () => {
      setCampaigns([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={6}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate.push("/admin/comunicacion", {
                replace: true,
              })
            }
          >
            <KeyboardBackspaceRoundedIcon />
          </IconButton>
          <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
            Otros recursos
          </Typography>
        </Box>
      </Box>
      <Box my={3} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
        px={3}
      >
        {loading && <LinearProgress />}
        {!loading &&
          campaigns.map((campaign, idxCampaign) => (
            <Card
              key={idxCampaign}
              elevation={0}
              sx={{
                p: 2,
                width: "100%",
                borderRadius: 2,
              }}
            >
              <Box display={"flex"} alignItems={"center"} gap={3}>
                <Typography
                  variant="body1"
                  fontWeight={"medium"}
                  sx={{ flex: 1 }}
                >
                  {campaign.titulo}
                </Typography>
                <Box>
                  <IconButton
                    size="small"
                    color="warning"
                    onClick={async () => {
                      printLog("editar contenido de recurso");
                      navigate.push("/admin/comunicacion/campana/detalle", {
                        campaignId: campaign.id,
                      });
                    }}
                  >
                    <EditRounded />
                  </IconButton>
                </Box>
              </Box>
            </Card>
          ))}
      </Box>
    </React.Fragment>
  );
};
