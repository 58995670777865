import { KeyboardBackspaceRounded as KeyboardBackspaceRoundedIcon } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DesignSystem } from "../../themes/theme";

export const AdminComunicacionPage = () => {
  const navigate = useHistory();
  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={6}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate.push("/", {
                replace: true,
              })
            }
          >
            <KeyboardBackspaceRoundedIcon />
          </IconButton>
          <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
            Comunicación
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} lg={4}>
          <Card
            sx={{
              "&:hover": {
                transform: "scale3d(1.05, 1.05, 1)",
                cursor: "pointer",
                backgroundColor: "background.paper",
              },
              transition: "0.1s",
              borderRadius: 3,
            }}
            elevation={0}
            onClick={() => {
              navigate.push("/admin/comunicacion/banner", {});
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" my={2}>
                Banner destacado
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card
            elevation={0}
            sx={{
              "&:hover": {
                transform: "scale3d(1.05, 1.05, 1)",
                cursor: "pointer",
                backgroundColor: "background.paper",
              },
              transition: "0.1s",
              borderRadius: 3,
            }}
            onClick={() => {
              navigate.push("/admin/comunicacion/campana", {});
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" my={2}>
                Campaña Principal
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card
            elevation={0}
            sx={{
              "&:hover": {
                transform: "scale3d(1.05, 1.05, 1)",
                cursor: "pointer",
                backgroundColor: "background.paper",
              },
              transition: "0.1s",
              borderRadius: 3,
            }}
            onClick={() => {
              navigate.push("/admin/comunicacion/recurso", {});
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" my={2}>
                Otros recursos
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
