import { Box, CircularProgress } from "@mui/material"

const CSplash = () => {
  return <Box
  component="main"
  sx={{
    background: '#f8f5f7af',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  }}
>
  <Box sx={{
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    alignContent: 'center',
  }}>
    <CircularProgress color="primary" size={90} />
  </Box>
</Box>
}

export default CSplash;