import {
  Avatar,
  Box,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import React from "react";
import { PhoneAndroidRounded } from "@mui/icons-material";
import { TIPOS_DIRECTORIOS } from "../data/directorios";
import slimIcon from "./../../../assets/Directory/S_D.png";
import alcaldiaIcon from "./../../../assets/Directory/alcaldia.png";
import desarrolloIcon from "./../../../assets/Directory/desarrolloIcono.png";
import policiaIcon from "./../../../assets/Directory/felcv.png";
import saludIcon from "./../../../assets/Directory/centro-salud.png";
import forenseIcon from "./../../../assets/Directory/forense.png";
import juzgadoIcon from "./../../../assets/Directory/juzgado.png";
import fiscalIcon from "./../../../assets/Directory/fiscal.png";

export type CardDirectoryProps = {
  titulo: string;
  direcciones?: string[];
  contacto?: string;
  emergencia?: string;
  atencion?: string;
  tipo: string;
  diasAtencion?: {
    active: boolean;
    label: string;
    description: string;
  }[];
};

export const CardDirectory: React.FC<CardDirectoryProps> = ({
  titulo,
  direcciones,
  contacto,
  emergencia,
  atencion,
  diasAtencion,
  tipo,
}) => {
  const theme = useTheme();
  const renderIcono = (_tipo: string) => {
    switch (_tipo) {
      case TIPOS_DIRECTORIOS.ALCADIA:
        return (
          <img
            src={alcaldiaIcon}
            width={"32px"}
            height={"32px"}
            alt="alcaldia-icon"
          />
        );
      case TIPOS_DIRECTORIOS.SLIM:
        return <img src={slimIcon} alt="slim-icon" />;
      case TIPOS_DIRECTORIOS.DESARROLLO_HUMANO_SOCIAL:
        return (
          <img
            src={desarrolloIcon}
            width={"32px"}
            height={"32px"}
            alt="desarrollo-icon"
          />
        );
      case TIPOS_DIRECTORIOS.POLICIA:
        return <img src={policiaIcon} alt="policia-icon" />;
      case TIPOS_DIRECTORIOS.SALUD:
        return <img src={saludIcon} alt="salud-icon" />;
      case TIPOS_DIRECTORIOS.FORENSE:
        return <img src={forenseIcon} alt="forense-icon" />;
      case TIPOS_DIRECTORIOS.JUZGADO:
        return <img src={juzgadoIcon} alt="juzgado-icon" />;
      case TIPOS_DIRECTORIOS.FISCALIA:
        return <img src={fiscalIcon} alt="fiscal-icon" />;
      default:
        return <img src={slimIcon} alt="slim-icon" />;
    }
  };
  return (
    <Box
      sx={{
        background: theme.palette.common.white,
        borderRadius: "10px",
      }}
      py={3}
      my={3}
    >
      {/* Título */}
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={3}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            color: theme.palette.common.white,
            background: theme.palette.grey[100],
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
          }}
          p={0.9}
        >
          {renderIcono(tipo)}
          {/* <Avatar
            sx={{
              width: 32,
              height: 32,
              background: "#e29500",
            }}
          >
            {titulo === "DNA" ? (
              <img src={iconSD} alt={"icon-sd"} />
            ) : (
              <PlaceIcon sx={{ color: theme.palette.common.white }} />
            )}
          </Avatar> */}
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography
            variant="h5"
            fontWeight={"normal"}
            sx={{ flex: 1, color: "#e29500" }}
          >
            {tipo === TIPOS_DIRECTORIOS.ALCADIA ? titulo.split("-")[0] : titulo}
          </Typography>
          <Typography
            variant="body1"
            fontWeight={"normal"}
            sx={{ flex: 1, color: alpha(theme.palette.common.black, 0.4) }}
          >
            {tipo === TIPOS_DIRECTORIOS.ALCADIA ? titulo.split("-")[1] : ""}
          </Typography>
        </Box>
      </Box>
      {/* Direcciones */}
      {direcciones != null && direcciones.length > 0 && (
        <Box px={3} py={0} sx={{ color: theme.palette.grey[600] }}>
          <ul
            style={{
              listStyleType: direcciones.length === 1 ? "none" : "initial",
            }}
          >
            {direcciones.map((_direccion: string, idxDireccion: number) => (
              <li key={`direc-${idxDireccion}`}>{_direccion}</li>
            ))}
          </ul>
        </Box>
      )}
      {/* Datos contacto */}
      <React.Fragment>
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          {contacto != null && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                flex: 1,
                // border: "1px solid red"
                borderTop: `.8px solid ${alpha(
                  theme.palette.common.black,
                  0.1
                )}`,
                borderBottom: `.8px solid ${alpha(
                  theme.palette.common.black,
                  0.1
                )}`,
              }}
              p={1}
              pl={2}
              gap={1}
              alignItems={"center"}
            >
              <Typography fontSize={".8rem"} sx={{ color: "#66c628" }}>
                TELÉFONO DE CONTACTO 1
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"row"}
                sx={{ flex: 1 }}
                alignItems={"center"}
                gap={1}
              >
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    background: "#66c628",
                  }}
                >
                  <LocalPhoneOutlinedIcon sx={{ fontSize: "1rem" }} />
                </Avatar>
                <Typography fontSize={".9rem"} sx={{ flex: 1 }}>
                  {contacto}
                </Typography>
              </Box>
            </Box>
          )}
          {emergencia != null && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{ background: "#e29500", flex: 1 }}
              p={1}
              pl={2}
              gap={1}
              alignItems={"center"}
            >
              <Typography
                fontSize={".8rem"}
                sx={{ color: theme.palette.common.white }}
              >
                TELÉFONO DE CONTACTO 2
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"row"}
                sx={{ flex: 1 }}
                alignItems={"center"}
                gap={1}
              >
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    background: theme.palette.common.white,
                  }}
                >
                  <PhoneAndroidRounded
                    sx={{ color: "#e29500", fontSize: "1rem" }}
                  />
                </Avatar>
                <Typography
                  fontSize={"1rem"}
                  sx={{ flex: 1, color: theme.palette.common.white }}
                >
                  {emergencia}
                </Typography>
              </Box>
            </Box>
          )}
          {atencion != null && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{ background: "#e77d00", flex: 1 }}
              p={1}
              alignItems={"center"}
            >
              <Typography
                fontSize={".8rem"}
                sx={{ color: theme.palette.common.white }}
              >
                ATENCIÓN
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"row"}
                sx={{ flex: 1 }}
                alignItems={"center"}
                gap={1}
              >
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    background: theme.palette.common.white,
                  }}
                >
                  <WatchLaterIcon sx={{ color: "#e77d00", fontSize: "1rem" }} />
                </Avatar>
                <Typography
                  fontSize={".9rem"}
                  sx={{
                    flex: 1,
                    color: theme.palette.common.white,
                    fontWeight: 500,
                  }}
                >
                  {atencion}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </React.Fragment>
      {/* Datos atención */}
      {diasAtencion != null && (
        <React.Fragment>
          <Box py={2} px={1} display={"flex"} flexDirection={"column"} gap={2}>
            <Typography sx={{ color: "#00abe2" }}>DÍAS DE ATENCIÓN</Typography>
            <Box display={"flex"} flexDirection={"row"} gap={1}>
              {diasAtencion.map((_dia, idxDia: number) => (
                <Tooltip key={`dia-${idxDia}`} title={_dia.description}>
                  <Avatar
                    sx={{
                      width: 28,
                      height: 28,
                      background: _dia.active
                        ? "#00abe2"
                        : theme.palette.neutral.main,
                    }}
                  >
                    <Typography fontSize={".7rem"}>{_dia.label}</Typography>
                  </Avatar>
                </Tooltip>
              ))}
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};
