import { Container, Grid } from "@mui/material";
import CInputForm from "../../../../shared/components/form/CInputForm";

type VideoContentEditorProps = {
  link?: string;
  onLinkChange: (link: string) => void;
};
export function VideoContentEditor({ link, onLinkChange }: VideoContentEditorProps) {
  return <Grid
    container
    direction="column"
    spacing={3}
    alignItems="center"
  >
    <Grid item container xs={12}>
      <CInputForm
        type="text"
        placeholder="Escribe la url del vídeo que desea enlazar"
        textoInfo="Ojo: El link del video debe ser válido y empezar con https://www.yout ó https://youtu.be"
        name="content"
        value={link || ""}
        onChange={(e: any) => onLinkChange(e.target.value)} />
    </Grid>
  </Grid>
}
