import produce from "immer";
import React, { useContext, useReducer } from "react";
import { Campaign, CampaignResource } from "../interface";

type CampaignAction =
  | {
      type: "LOAD_CAMPAIGN";
      campaign: Campaign;
    }
  | { type: "NEW_CAMPAIGN" }
  | {
      type: "EDIT_CAMPAIGN";
      payload: Partial<Campaign>;
    }
  | {
      type: "ADD_RESOURCE";
      payload: CampaignResource;
    }
  | {
      type: "EDIT_RESOURCE";
      idxResource: number;
      payload: Partial<CampaignResource>;
    }
  | {
      type: "DELETE_RESOURCE";
      idxResource: number;
    };

function emptyCampaign(): Campaign {
  return {
    titulo: "",
    descripcion: "",
    tipo: "NACIONAL",
    recursos: [],
  };
}

type CampaignReducer = React.Reducer<Campaign, CampaignAction>;

const campaignReducer: CampaignReducer = (prevState, action) => {
  switch (action.type) {
    case "LOAD_CAMPAIGN": {
      return produce(prevState, (draft) => {
        Object.assign(draft, action.campaign);
      });
    }
    case "NEW_CAMPAIGN": {
      return emptyCampaign();
    }
    case "EDIT_CAMPAIGN": {
      return {
        ...prevState,
        ...action.payload,
      };
    }
    case "ADD_RESOURCE": {
      return produce(prevState, (draft) => {
        if (!draft.recursos) {
          draft.recursos = [
            {
              tipo: action.payload.tipo,
              titulo: action.payload.titulo,
              url: action.payload.url,
              adjunto: action.payload.adjunto,
            },
          ];
        } else {
          draft.recursos.push({
            tipo: action.payload.tipo,
            titulo: action.payload.titulo,
            url: action.payload.url,
            adjunto: action.payload.adjunto,
          });
        }
      });
    }
    case "EDIT_RESOURCE": {
      return produce(prevState, (draft) => {
        Object.assign(draft.recursos[action.idxResource], action.payload);
      });
    }
    case "DELETE_RESOURCE": {
      return produce(prevState, (draft) => {
        draft.recursos.splice(action.idxResource, 1);
      });
    }
  }
};

type CampaignContextValue = [Campaign, (action: any) => void];
const CampaignContext = React.createContext<CampaignContextValue | null>(null);

const CampaignProvider = ({ children }: { children: React.ReactNode }) => {
  const [campaign, dispatch] = useReducer(campaignReducer, emptyCampaign());
  return (
    <CampaignContext.Provider value={[campaign, dispatch]}>
      {children}
    </CampaignContext.Provider>
  );
};

export function withCampaignContextProvider(
  WrappedComponent: React.ComponentType
) {
  return () => (
    <CampaignProvider>
      <WrappedComponent></WrappedComponent>
    </CampaignProvider>
  );
}

export function useCampaignContext() {
  const context = useContext(CampaignContext);
  if (context === null) throw new Error("No provider for CampaignContext");
  return context;
}
