import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { MouseEventHandler } from "react";

export const NeutralButton = styled((props: ButtonProps) => (
  <Button variant="outlined" color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral.main}`,
  background: "white",
  borderRadius: "8px",
  minWidth: "initial",
  color: theme.palette.neutral.main,
  py: 1,
}));

export const PrimaryButton = styled((props: ButtonProps) => (
  <Button variant="contained" disableElevation color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  background:
    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
  fontFamily: "Barlow",
  borderRadius: "8px",
  color: "white",
  paddingTop: ".5rem",
  paddingBottom: ".5rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
  // border: "1px solid rgba(0,119,170,1)",
  "&:hover": {
    background:
      "linear-gradient(180deg, rgba(0,191,232,.95) 0%, rgba(0,154,219,.95) 53%, rgba(0,119,170,.95) 100%)",
  },
}));

export const PrimaryButtonText = styled((props: ButtonProps) => (
  <Button variant="text" disableElevation color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  textTransform: "initial",
  color: theme.palette.info.main,
  fontSize: "1.1rem",
}));

export const CircleBox = styled(Box)(({ theme }) => ({
  background:
    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
  borderRadius: "50%",
  color: "white",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

type RecursoDialogProps = {
  withTitle?: boolean;
  ctitle?: string;
};

export const RecursoDialog = styled(
  ({ withTitle = true, ...props }: DialogProps & RecursoDialogProps) => (
    <Dialog maxWidth={"sm"} fullWidth keepMounted {...props}>
      <Box sx={{ position: "relative" }}>
        <Box
          display="flex"
          justifyContent={"end"}
          alignItems={"center"}
          sx={{
            m: 0,
            p: 0.5,
          }}
        >
          <IconButton onClick={props.onClose as MouseEventHandler}>
            <CircleBox sx={{ background: "#818080", p: 0.5 }}>
              <CloseRounded sx={{ fontSize: "1rem" }} />
            </CircleBox>
          </IconButton>
        </Box>
        <DialogContent sx={{ paddingTop: 0, marginTop: 0 }}>
          {withTitle && (
            <Box mb={3}>
              <Typography sx={{ fontSize: "1.6rem" }}>
                {props.ctitle}
              </Typography>
            </Box>
          )}
          <Box>{props.children}</Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    paddingTop: ".5rem",
    background: "#FFFFFF",
  },
}));
