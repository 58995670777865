import { Box, Button, Typography, useTheme } from "@mui/material";
import RegisterForm from "./components/RegisterForm";
import { CourseContentDialog } from "../shared/Elements";


type AuthDialogProps = {
  open: boolean;
  onClose: () => void;
}

const AuthDialog = ({ open, onClose }: AuthDialogProps) => {
  const theme = useTheme()

  return (
    <CourseContentDialog
      open={open}
      onClose={onClose}
      withTitle={false}
      withActions={false}
    >
      <Box>
        <Button
          variant="text"
          sx={{
            fontWeight: 500,
            fontSize: "1.5rem",
            color: theme.palette.success.main
          }}
        >
          COMPARTE TU BUENA PRÁCTICA
        </Button>
      </Box>
      <Box>
          <Box mt={2} mb={3}>
            <Typography sx={{
              fontSize: '16px',
              color: theme.palette.neutral.active
            }}>
              Bienvenido/Bienvenida, estamos emocionados de conocer tus iniciativas 
            </Typography>
            <Typography sx={{ 
              fontSize: '16px',
              color: theme.palette.neutral.active,
              mb:'1.5em'
            }}>
              A continuación por favor déjanos tus datos para comunicarnos contigo: 
            </Typography>
            <RegisterForm onClose={onClose}/>
          </Box>
      </Box>
    </CourseContentDialog>
  )
}

export default AuthDialog