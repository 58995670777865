import { useAuthContext } from "../../shared/auth/AuthContext";
import useHttp from "../../shared/hooks/useHttp";
import { printLog } from "../../shared/utils";
import {
  ActualizacionDeNoticiasParams,
  CreacionDeNoticiasParams,
} from "./NoticiaInterface";

export function useGetMedia(): () => Promise<any> {
  const http = useHttp();
  // const authContext = useAuthContext();
  return async () => {
    return await http.post(`/medio/listado`, {
      // auth: authContext?.user?.token ?? undefined,
    });
  };
}

export function useGetNoticias(): () => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async () => {
    return await http.get(`/admin/publicacion`, {
      auth: authContext?.user?.token ?? undefined,
    });
  };
}

export function useGetNoticiaById(): (noticiaId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (noticiaId: string) => {
    const result: any = await http.get(
      `/admin/publicacion/noticia/${noticiaId}`,
      {
        auth: authContext?.user?.token ?? undefined,
      }
    );
    return result;
  };
}

export function useGetEnlaceById(): (noticiaId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (noticiaId: string) => {
    const result: any = await http.get(
      `/admin/publicacion/enlace/${noticiaId}`,
      {
        auth: authContext?.user?.token ?? undefined,
      }
    );
    return result;
  };
}

export function useCreateNoticia(): (
  params: CreacionDeNoticiasParams
) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (params: CreacionDeNoticiasParams) => {
    const arrayData = new FormData();
    arrayData.append("titulo", params.titulo);
    arrayData.append("tipo", params.tipo);
    arrayData.append("categoria", params.categoria);
    arrayData.append("alcanceGeografico", params.alcanceGeografico);

    if (params.tipo === "NOTICIA")
      arrayData.append("contenido", JSON.stringify(params.contenido));

    arrayData.append("estado", params.estado);

    if (params.tipo === "ENLACE_INTERES") {
      arrayData.append("medio_cod", JSON.stringify(params.medio_cod));
    }

    if (params.tipo === "NOTICIA")
      arrayData.append(
        "esNoticiaSolidar",
        JSON.stringify(Boolean(params.esNoticiaSolidar))
      );

    if (params.tipo === "NOTICIA")
      arrayData.append(
        "esDestacada",
        JSON.stringify(Boolean(params.esDestacada))
      );

    if (params.tipo === "ENLACE_INTERES")
      arrayData.append("url", params.url ? params.url : "");

    if (params.tipo === "ENLACE_INTERES")
      arrayData.append("medio_cod", JSON.stringify(params.medio_cod));
    // debugger;

    for (let index = 0; index < params.archivos.length; index++) {
      arrayData.append("adjuntos", params.archivos[index]);
    }
    printLog("arrayData antes de enviar  :: ", arrayData.get("titulo"));
    // debugger;
    if (params.tipo === "NOTICIA") {
      return await http.post(`/admin/publicacion/noticia`, {
        payload: arrayData,
        auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
    } else {
      return await http.post(`/admin/publicacion/enlace`, {
        payload: arrayData,
        auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
    }
  };
}

export function useDeleteNoticia() {
  const http = useHttp();
  return async (id: string) => {
    return await http.delete(`/admin/publicacion/noticia/${id}`, {});
  };
}

export function useDeleteEnlace() {
  const http = useHttp();
  return async (id: string) => {
    return await http.delete(`/admin/publicacion/enlace/${id}`, {});
  };
}

export function useEditNoticia(): (
  params: ActualizacionDeNoticiasParams,
  noticiaId: string
) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (params: ActualizacionDeNoticiasParams, noticiaId: string) => {
    // debugger;

    const arrayData = new FormData();
    arrayData.append("titulo", params.titulo);
    arrayData.append("tipo", params.tipo);
    arrayData.append("categoria", params.categoria);
    arrayData.append("alcanceGeografico", params.alcanceGeografico);

    if (params.tipo === "NOTICIA")
      arrayData.append("contenido", JSON.stringify(params.contenido));

    arrayData.append("estado", params.estado);

    if (params.tipo === "ENLACE_INTERES") {
      arrayData.append("medio_cod", JSON.stringify(params.medio_cod));
    }

    if (params.tipo === "NOTICIA")
      arrayData.append(
        "esNoticiaSolidar",
        JSON.stringify(Boolean(params.esNoticiaSolidar))
      );

    if (params.tipo === "NOTICIA")
      arrayData.append(
        "esDestacada",
        JSON.stringify(Boolean(params.esDestacada))
      );

    if (params.tipo === "ENLACE_INTERES")
      arrayData.append("url", params.url ? params.url : "");

    if (params.tipo === "ENLACE_INTERES")
      arrayData.append("medio_cod", JSON.stringify(params.medio_cod));

    for (let index = 0; index < params.archivos.length; index++) {
      arrayData.append("adjuntos", params.archivos[index]);
    }

    arrayData.append(
      "adjuntosEliminados",
      JSON.stringify(params.adjuntosEliminados)
    );

    if (params.tipo === "NOTICIA") {
      return await http.put(`/admin/publicacion/noticia/${noticiaId}`, {
        payload: arrayData,
        auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
    } else {
      return await http.put(`/admin/publicacion/enlace/${noticiaId}`, {
        payload: arrayData,
        auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
    }
  };
}
