// import Layout from "../../components/Layout";
import CoursePage from "./CoursePage";
import { Box } from "@mui/material";

function Courses() {
  return (
    <Box pt={4}>
      <CoursePage />
    </Box>
  );
}

export default Courses;
