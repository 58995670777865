import {
  Alert,
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  Grid,
  LinearProgress,
  linearProgressClasses,
  SelectChangeEvent,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CSelectForm from "../../../shared/components/form/CSelectForm";
import useHttp from "../../../shared/hooks/useHttp";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Chart } from "react-google-charts";
import { _categorias, _cifras, _indicadores } from "../data";

const StyledBox = styled(Box)(() => ({
  margin: "0 0 6em 0",
  padding: 0,
  width: "100%",
}));

type IndicadorType = {
  codigo: string;
  nombre: string;
  codCategoria: string;
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#ececec",
        }}
        size={100}
        thickness={2}
        value={100}
      />
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h3" component={"div"} sx={{ color: "#4A0C55" }}>
          {`${Math.round(props.value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

export const Statistics = () => {
  const theme = useTheme();
  const http = useHttp();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [departamento, setDepartamento] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [municipio, setMunicipio] = useState("");
  const [municipioInfo, setMunicipioInfo] = useState<any>(null);
  const [municipios, setMunicipios] = useState([]);

  const [cifrasData, setCifrasData] = useState<any>(null);
  const [indicadorData, setIndicadorData] = useState<any>(null);

  /// Valores de formulario
  const [indicador, setIndicador] = useState("");
  const [indicadores, setIndicadores] = useState<Array<IndicadorType>>([]);
  const [categoria, setCategoria] = useState("");
  const [gestion, setGestion] = useState("");
  const [nivel, setNivel] = useState("NACIONAL");

  const obtenerDepartamentosMunicipios = async () => {
    try {
      const response: any = await http.get(
        "/municipio/porDepartamento?esMunicipioVdsv=true",
        {}
      );
      if (response) {
        setDepartamentos(response.departamentos);
      }
      // console.log(
      //   "JSON.stringify(response.departamentos); > ",
      //   JSON.stringify(response.departamentos)
      // );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    obtenerDepartamentosMunicipios().finally();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowChart = () => {
    // _cifras
    console.log(
      "mostrar resultados con los siguientes valores >>>",
      nivel,
      departamento,
      municipio,
      gestion,
      categoria,
      indicador
    );
    const _indicador = _indicadores.find((item) => item.codigo === indicador);
    setIndicadorData(_indicador);
    if (nivel === "MUNICIPAL") {
      const _data = _cifras.find(
        (item) =>
          item.codCategoria === categoria &&
          item.codIndicador === indicador &&
          item.nivel === nivel &&
          Number(item.codMunicipio) === Number(municipio)
      );
      console.log("view data > ", _data);
      setCifrasData(_data);
    } else {
      console.log(
        ":: NACIONAL :: para categoria e indicador > ",
        categoria,
        indicador
      );
      const _data = _cifras.filter(
        (item) =>
          item.codCategoria === categoria && item.codIndicador === indicador
      );
      const _cifraUnica = {
        ..._data[0],
      };
      const sum = _data.reduce((acc: any, obj) => {
        obj.metadata.forEach((item) => {
          acc[item.label] = (acc[item.label] || 0) + item.value;
        });
        return acc;
      }, {});
      const nuevoObjeto = {
        metadata: [] as { label: string; value: any }[],
      };
      Object.entries(sum).forEach(([label, value]) => {
        nuevoObjeto.metadata.push({ label, value });
      });
      // console.log(nuevoObjeto);
      _cifraUnica.metadata = nuevoObjeto.metadata;
      console.log("Valor de cifra unica obtenida >> ", _cifraUnica);
      setCifrasData(_cifraUnica);
    }
  };

  const obtenerTuplasMetadata = (
    metadata: Array<{ label: string; value: number }>
  ) => {
    const _tuplas = [];
    for (const _meta of metadata) {
      _tuplas.push([`${_meta.label}`, _meta.value]);
    }
    // console.log("tuplas generada >> ", _tuplas);
    return _tuplas;
  };

  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          margin: { xs: "0 1.5em 0 1.5em", md: 0 },
          padding: "3em 0em",
          // border: "1px solid red",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card
              elevation={0}
              sx={{ borderRadius: "10px", background: alpha("#cacaca", 0.7) }}
            >
              <CardContent>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography sx={{ fontWeight: "600" }}>
                    Selecciona una categoría y un indicador
                  </Typography>
                  <Box>
                    <CSelectForm
                      placeholder="Selecciona una categoría"
                      fullWidth
                      value={categoria}
                      items={[
                        ..._categorias.map((item) => ({
                          value: item.codigo,
                          label: item.nombre,
                        })),
                      ]}
                      onChange={(event: SelectChangeEvent) => {
                        setCategoria(event.target.value);
                        setIndicador("");
                        if (event.target.value === "") {
                          setIndicadores([]);
                        } else {
                          const _indicadoresSelected = _indicadores.filter(
                            (item) => item.codCategoria === event.target.value
                          );
                          setIndicadores(_indicadoresSelected);
                        }
                      }}
                    />
                  </Box>
                  {/* <Box my={2} /> */}
                  {indicadores.length > 0 && (
                    <React.Fragment>
                      <Typography>Indicadores</Typography>
                      {indicadores.map((_indicador, idxIndicador: number) => (
                        <Box
                          key={idxIndicador}
                          onClick={() => {
                            setIndicador(_indicador.codigo);
                          }}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          sx={{
                            background: alpha(theme.palette.common.white, 0.9),
                            borderRadius: "10px",
                            cursor: "pointer",
                            "&:hover": {
                              background: theme.palette.common.white,
                            },
                          }}
                          px={1.5}
                          py={0.3}
                        >
                          <Typography
                            variant="body2"
                            color={
                              indicador === _indicador.codigo
                                ? "primary"
                                : "inherit"
                            }
                          >
                            {_indicador.nombre}
                          </Typography>
                          {indicador === _indicador.codigo ? (
                            <RadioButtonCheckedIcon color="primary" />
                          ) : (
                            <RadioButtonCheckedIcon color="disabled" />
                          )}
                        </Box>
                      ))}
                    </React.Fragment>
                  )}
                  <Box my={0.5} />
                  <Typography sx={{ fontWeight: "600" }}>
                    Selecciona el nivel
                  </Typography>

                  <Box
                    display={"flex"}
                    flexDirection="row"
                    justifyContent={"space-around"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      onClick={() => {
                        setNivel("NACIONAL");
                      }}
                    >
                      <Typography
                        variant="body2"
                        color={nivel === "NACIONAL" ? "primary" : "inherit"}
                      >
                        NACIONAL
                      </Typography>
                      {nivel === "NACIONAL" ? (
                        <RadioButtonCheckedIcon color="primary" />
                      ) : (
                        <RadioButtonCheckedIcon color="disabled" />
                      )}
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      onClick={() => {
                        setNivel("MUNICIPAL");
                      }}
                    >
                      <Typography
                        variant="body2"
                        color={nivel === "MUNICIPAL" ? "primary" : "inherit"}
                      >
                        MUNICIPAL
                      </Typography>
                      {nivel === "MUNICIPAL" ? (
                        <RadioButtonCheckedIcon color="primary" />
                      ) : (
                        <RadioButtonCheckedIcon color="disabled" />
                      )}
                    </Box>
                  </Box>

                  {nivel === "MUNICIPAL" && (
                    <Box>
                      <CSelectForm
                        placeholder="Selecciona un departamento"
                        fullWidth
                        value={departamento}
                        items={[
                          ...departamentos.map((item: any) => ({
                            value: item.departamento_cod,
                            label: item.nombre,
                          })),
                        ]}
                        onChange={(event: SelectChangeEvent) => {
                          setDepartamento(event.target.value);
                          if (event.target.value === "") {
                            setMunicipios([]);
                          } else {
                            const _depto: any = departamentos.find(
                              (item: any) =>
                                item.departamento_cod === event.target.value
                            );
                            setMunicipios(_depto.municipios);
                          }
                        }}
                      />
                    </Box>
                  )}
                  {nivel === "MUNICIPAL" && (
                    <Box>
                      <CSelectForm
                        placeholder="Selecciona un municipio"
                        fullWidth
                        value={municipio}
                        items={municipios.map((item: any) => ({
                          value: item.municipio_cod,
                          label: item.municipio_desc,
                        }))}
                        onChange={(event: SelectChangeEvent) => {
                          setMunicipio(event.target.value);
                          const _muniSelected = municipios.find(
                            (item: any) =>
                              item.municipio_cod === event.target.value
                          );
                          console.log("v_muniSelected > ", _muniSelected);
                          setMunicipioInfo(_muniSelected);
                        }}
                      />
                    </Box>
                  )}

                  <Box my={0.5} />
                  <Typography sx={{ fontWeight: "600" }}>
                    Selecciona la gestión
                  </Typography>
                  <Box>
                    <CSelectForm
                      placeholder="Gestión"
                      fullWidth
                      value={gestion}
                      items={[
                        { value: "2023-LB", label: "2023 - Línea base" },
                        { value: "2023-M", label: "2023 - Monitoreo" },
                      ].map((item: any) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      onChange={(event: SelectChangeEvent) => {
                        setGestion(event.target.value);
                      }}
                    />
                  </Box>

                  <Box
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    gap={2}
                    mt={3}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      size={isMobile ? "small" : "medium"}
                      sx={{
                        flex: 1,
                        color: theme.palette.common.white,
                        borderRadius: "10px",
                      }}
                      onClick={handleShowChart}
                    >
                      <SearchIcon color="inherit" />
                      Mostrar resultados
                    </Button>
                    <Button
                      variant="outlined"
                      size={isMobile ? "small" : "medium"}
                      sx={{ borderRadius: "10px" }}
                      onClick={() => {
                        console.log("limpiar formulario >>>");
                        setIndicador("");
                        setDepartamento("");
                        setMunicipio("");
                        setGestion("");
                        setMunicipioInfo(null);
                        setCifrasData(null);
                      }}
                    >
                      Limpiar
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card
              variant="outlined"
              sx={{ borderRadius: "10px", position: "sticky", top: "80px" }}
            >
              <CardContent>
                {/* <Typography>Cajita para graficos</Typography> */}
                {cifrasData != null && indicadorData && (
                  <React.Fragment>
                    <Box my={2} />
                    <Box display={"flex"} alignItems={"center"}>
                      <Box
                        display={"flex"}
                        flexWrap={"wrap"}
                        sx={{ width: "100%" }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Typography
                            variant="h5"
                            fontWeight={"normal"}
                            sx={{ flex: 1, color: "#e29500" }}
                            align="center"
                          >
                            {indicadorData?.nombre ?? ""}
                          </Typography>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                          <Typography
                            variant="h6"
                            fontWeight={"normal"}
                            align="center"
                          >
                            {nivel === "MUNICIPAL"
                              ? municipioInfo
                                ? `- ${(municipioInfo as any).municipio_desc}`
                                : ""
                              : ""}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      {indicadorData.tipoChart === "Dato" && (
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{ width: "100%" }}
                          my={5}
                        >
                          <Box my={2} />
                          <CircularProgressWithLabel
                            sx={{
                              color: "#4A0C55",
                              position: "absolute",
                              left: 0,
                              [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: "round",
                              },
                            }}
                            size={100}
                            thickness={2}
                            value={cifrasData.metadata[0].value}
                          />
                        </Box>
                      )}
                      {indicadorData.tipoChart !== "Dato" && (
                        <Chart
                          chartType={indicadorData.tipoChart}
                          width="100%"
                          height="630px"
                          data={[
                            ["label", "Cantidad"],
                            ...obtenerTuplasMetadata(cifrasData.metadata),
                          ]}
                          options={{
                            legend: indicadorData.leyenda,
                            // title: `${indicadorData.nombre}`,
                            colors: ["#4A0C55", "#a659a8"],
                          }}
                        />
                      )}
                    </Box>
                  </React.Fragment>
                )}
                {cifrasData == null && (
                  <Alert severity="success">
                    {" "}
                    Realiza tu búsqueda mediante los siguientes pasos:
                    <ol>
                      <li>Elige una categoría y un indicador.</li>
                      <li>
                        Selecciona el nivel nacional o municipal para obtener
                        datos específicos.
                      </li>
                      <li>
                        Elige la gestión que prefieras y presiona "Mostrar
                        resultados".
                      </li>
                    </ol>
                  </Alert>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              margin: "3em 0 3em 0",
              padding: { xs: "0em 1.5em 0em 1.5em", md: "0 0 0 1.5em" },
              width: { xs: "100%" },
            }}
          >
            <Typography
              sx={{
                mt: "0em",
                fontFamily: "Barlow",
                fontSize: 16,
                color: theme.palette.neutral.hover,
              }}
            >
              Esta sección contiene información recolectada y sistematizada de
              los 51 municipios donde interviene el Proyecto Vida Digna Sin
              Violencia (cobertura directa).
            </Typography>
          </Box>
        </Grid>
      </Box>
    </StyledBox>
  );
};
