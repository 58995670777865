import { Box, Chip, Typography, styled } from "@mui/material";
import { useHistory } from "react-router-dom";
import theme from "../../../../../../themes";
import { NewsCardProps } from "../../../../../../types/NewsCard";
import useAppContext from "../../../../../../shared/hooks/useAppContext";
import moment from "moment";

const StyledBox = styled(Box)(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer'
  // TODO: mobile breakpoints
  // [theme.breakpoints.up('md')]: {
  //   maxHeight: '330px',
  //   height: '330px'
  // },
  // [theme.breakpoints.up('lg')]: {
  //   maxHeight: '280px',
  //   height: '280px',
  // }
}));

const StyledImg = styled('img')(() => ({
  width: '100%',
  // height: '100%',
  objectFit: 'cover',
  objectPosition: 'top',
  borderRadius: '8px'
}));

const BigNews = ({ image, content, date, clipLabel,isInterestLink, code, urlSufix, title, displayAsColumn, cardWidth, cardHeight, category ,clipLabel2}: NewsCardProps) => {

  let history = useHistory();
  const { setInterestLinkContent, setNewsContent } = useAppContext();

  const handleClick = () => {
    
    if(isInterestLink) {
      setInterestLinkContent(null);
      history.push(`/hemeroteca/link-de-interes/${urlSufix}_${code}`)
    }
    else{
      setNewsContent(null);
      history.push(`/hemeroteca/noticia/${code}`)
    }
      
      
  }

  return (
    <StyledBox display="flex" flexDirection="column" onClick={handleClick}>
      <Box display="flex" flexDirection="column" sx={{ width: '100%', height: '65%' }}>
        <Box display="flex" width={'100%'} height='100%' maxHeight='100%' style={{ padding: '1.5em 1.5em 0 0' }}>
          <StyledImg src={image} alt="img" />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: '100%', padding: '1.5em 1.5em 1.5em 1.5em', height: '35%' }}
      >
        <Box display="flex">
          <Chip label={clipLabel} sx={{ backgroundColor: theme.palette.secondary.active, color: '#FFFFFF' }} />
          {clipLabel2 !== "" && <Chip label={clipLabel2} sx={{ backgroundColor: '#ff0001', color: '#FFFFFF',mx: '0.5em', }} />}
        </Box>
        <Box my={'0.5em'}>
          <Typography sx={{ fontFamily: 'Barlow', fontSize: { xs: 18, lg: 22 }, color: '#54595F', fontWeight: 'bold' }}>
            {content}
          </Typography>
        </Box>
        <Box>
          <Typography color="primary">
            {date ? `${moment(date).format("DD-MM-YYYY")} / ${category}` : ` / ${category}`} 
          </Typography>
        </Box>
        {/* <Box my={'0.5em'}>
          <Typography sx={{ fontFamily: 'Barlow', fontSize: 16, color: '#54595F', }}>
            Más de 140 mujeres reunidas en sus diversidades apostaron por el trabajo hacia una vida digna sin violencia de maneras muy propositivas y transformadoras.
          </Typography>
        </Box> */}
      </Box>
    </StyledBox>
  )
};

export default BigNews;