import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { printLog } from "../../../shared/utils";
import theme from "../../../themes";
import AddRounded from "@mui/icons-material/AddRounded";
import RemoveRounded from "@mui/icons-material/RemoveRounded";
import PlayArrow from "@mui/icons-material/PlayArrowRounded";
import VolumeUp from "@mui/icons-material/VolumeUpRounded";
import {
  CloseRounded,
  DescriptionRounded as DescriptionIcon,
} from "@mui/icons-material";
import { TipoRecurso } from "./MainCampaignCard";
import { CommunicationResourceItem } from "./ResourceItem";
import ReactGA from "react-ga4";

const StyledButton = styled(Button)(() => ({
  background: alpha(theme.palette.grey[500], 0.4),
  borderRadius: "8px",
  ".avatar-icon": {
    width: 26,
    height: 26,
    background: theme.palette.common.white,
    color: "black",
  },
  "&:hover": {
    background: theme.palette.grey[500],
    ".avatar-icon": {
      background: theme.palette.primary.main,
    },
  },
}));

const AnimatedBox = styled(Box)(({ theme }) => ({
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px) scale(1.02)",
    boxShadow: theme.shadows[4],
  },
}));

export type Recurso = {
  bloque: string;
  tipo: string;
  titulo: string;
  enlace: string;
  imagen: string;
};

export type ResourceCardProps = {
  titulo: string;
  descripcion: string;
  color: string;
  recursos: Recurso[];
};

export const ResourceCard: React.FC<ResourceCardProps> = ({
  color,
  titulo,
  descripcion,
  recursos,
}) => {
  const [verArchivos, setVerArchivos] = useState<boolean>(false);
  const [tipoArchivos, setTipoArchivos] = useState<string>("");
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      printLog("esta expandido >>> ", isExpanded);
      setExpanded(isExpanded ? panel : false);
      if (!isExpanded) {
        setVerArchivos(false);
        setTipoArchivos("");
      }
    };

  const verTipoArchivo = (tipoArchivo: string) => {
    setVerArchivos(true);
    setTipoArchivos(tipoArchivo);
    ReactGA.event({
      category: "Recurso",
      action: `Recurso visitado - ${titulo} - ${tipoArchivo}`,
      label: `Recurso - ${titulo} - ${tipoArchivo}`,
    });
  };

  const cancelar = () => {
    setExpanded(false);
    setVerArchivos(false);
    setTipoArchivos("");
  };

  return (
    <AnimatedBox>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        {/* Cabecera */}
        <Box
          sx={{
            background: verArchivos ? alpha(color, 0.2) : color,
            color: "white",
            height: verArchivos ? "330px" : "230px",
            overflow: "hidden",
          }}
        >
          {!verArchivos && (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                p: 3,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4" fontWeight={"bold"}>
                {titulo}
              </Typography>
            </Box>
          )}
          {expanded && verArchivos && (
            <Box
              sx={{
                height: "100%",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Box
                py={1}
                px={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                  position: "sticky",
                  top: 0,
                  background: alpha(color, 0.1),
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer - 1,
                  backdropFilter: "blur(15px)",
                }}
              >
                {tipoArchivos === TipoRecurso.ARCHIVO && (
                  <Avatar
                    className="avatar-icon"
                    sx={{ background: color, width: 26, height: 26 }}
                  >
                    <DescriptionIcon fontSize="small" />
                  </Avatar>
                )}
                {tipoArchivos === TipoRecurso.VIDEO && (
                  <Avatar
                    className="avatar-icon"
                    sx={{ background: color, width: 26, height: 26 }}
                  >
                    <PlayArrow fontSize="small" />
                  </Avatar>
                )}
                {tipoArchivos === TipoRecurso.AUDIO && (
                  <Avatar
                    className="avatar-icon"
                    sx={{ background: color, width: 26, height: 26 }}
                  >
                    <VolumeUp fontSize="small" />
                  </Avatar>
                )}
                <Typography
                  variant="h6"
                  textAlign={"center"}
                  fontWeight={"bold"}
                  color={color}
                  sx={{ flex: 1 }}
                >
                  {titulo}
                </Typography>
                <IconButton onClick={cancelar}>
                  <CloseRounded />
                </IconButton>
              </Box>
              <Box mt={1} px={3} pb={3}>
                {!recursos.filter((item) => item.tipo === tipoArchivos)
                  .length && (
                  <Alert severity="warning">
                    No existe recursos de tipo {tipoArchivos}.
                  </Alert>
                )}
                {recursos.length > 0 &&
                  recursos
                    .filter((item) => item.tipo === tipoArchivos)
                    .map((recurso, idxRecurso) => (
                      <Box key={idxRecurso}>
                        <CommunicationResourceItem
                          titulo={recurso.titulo}
                          enlace={recurso.enlace}
                          imagen={recurso.imagen}
                          tipo={recurso.tipo}
                          color={color}
                        />
                      </Box>
                    ))}
              </Box>
            </Box>
          )}
        </Box>
        {/* Accion */}
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={
              expanded ? (
                <Avatar
                  className="avatar-icon"
                  sx={{
                    background: theme.palette.common.black,
                    color: theme.palette.common.white,
                    width: 26,
                    height: 26,
                  }}
                >
                  <RemoveRounded />
                </Avatar>
              ) : (
                <Avatar
                  className="avatar-icon"
                  sx={{
                    background: theme.palette.common.black,
                    color: theme.palette.common.white,
                    width: 26,
                    height: 26,
                  }}
                >
                  <AddRounded />
                </Avatar>
              )
            }
            sx={{
              background: alpha(color, 0.2),
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              justifyContent: "start",
              px: 3,
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ textTransform: "uppercase" }}
            >
              Detalles
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <StyledButton
              variant="contained"
              disableElevation
              startIcon={
                <Avatar className="avatar-icon">
                  <DescriptionIcon fontSize="small" />
                </Avatar>
              }
              sx={{
                background: tipoArchivos === TipoRecurso.ARCHIVO ? color : null,
                color: tipoArchivos === TipoRecurso.ARCHIVO ? "#fff" : null,
              }}
              onClick={() => verTipoArchivo(TipoRecurso.ARCHIVO)}
            >
              Archivos
            </StyledButton>
            <StyledButton
              variant="contained"
              startIcon={
                <Avatar className="avatar-icon">
                  <PlayArrow fontSize="small" />
                </Avatar>
              }
              disableElevation
              sx={{
                background: tipoArchivos === TipoRecurso.VIDEO ? color : null,
                color: tipoArchivos === TipoRecurso.VIDEO ? "#fff" : null,
              }}
              onClick={() => verTipoArchivo(TipoRecurso.VIDEO)}
            >
              Vídeos
            </StyledButton>
            <StyledButton
              variant="contained"
              disableElevation
              startIcon={
                <Avatar className="avatar-icon">
                  <VolumeUp fontSize="small" />
                </Avatar>
              }
              sx={{
                background: tipoArchivos === TipoRecurso.AUDIO ? color : null,
                color: tipoArchivos === TipoRecurso.AUDIO ? "#fff" : null,
              }}
              onClick={() => verTipoArchivo(TipoRecurso.AUDIO)}
            >
              Audio
            </StyledButton>
          </AccordionDetails>
        </Accordion>
        {/* Detalle */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={0.5}
          sx={{ background: alpha(color, 0.2), p: 3 }}
        >
          <Typography variant="h6" fontWeight={"bold"}>
            {titulo}
          </Typography>
          <Typography variant="body1">{descripcion}</Typography>
        </Box>
      </Box>
    </AnimatedBox>
  );
};
