import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import Footer from "../../components/Footer";
import MainBanner from "./components/MainBanner";
import { Statistics } from "./components/Statistics";
import ReactGA from "react-ga4";

const NumbersLocality = () => {
  const { pathname, hash, key } = useLocation();
  // const {
  //   getInnovativeExperiences
  // } = useAppContext();

  // useEffect(() => {
  //   getInnovativeExperiences();
  // }, [getInnovativeExperiences]);

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }

    window.scrollTo(0, 0);
  }, [pathname, hash, key]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/cifras-municipio",
      title: "Cifras Municipio",
    });
  }, []);

  return (
    <Layout>
      <MainBanner />
      <Statistics />
      <Footer />
    </Layout>
  );
};

export default NumbersLocality;
