import { StyledEngineProvider, ThemeProvider } from "@mui/material";
// import AppRouter from "./AppRouter";
import AuthContextProvider from "./shared/auth/AuthContextProvider";
import theme from "./themes";
import Main from "./views/Main";
import { FullScreenLoadingProvider } from "./context/ui/FullScreenLoadingProvider";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <FullScreenLoadingProvider>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <AuthContextProvider>
              {/* <AppRouter /> */}
              <Main />
            </AuthContextProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </FullScreenLoadingProvider>
    </QueryClientProvider>
  );
}

export default App;

