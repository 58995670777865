import {Alert, Box, Card, Grid, LinearProgress, Typography, useTheme,} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import imageCursoFake from "./../../../assets/course/cursoFake.svg";
import {useGetStudentCourses} from "./data/StudentCourseClient";
import {printLog} from "../../../shared/utils";
import {useAuthContext} from "../../../shared/auth/AuthContext";
// import { QuizViewer } from "../../../shared/components/curso/QuizViewer";
import {TipoPregunta} from "../interface";
import {QuizViewerSection} from "../../../shared/components/curso/QuizViewerSection";
import {machometroQuestions} from "./data/testMachometro";
import {CDialog} from "../../../shared/components/CDialog";

function StudentCoursePage() {
  const theme = useTheme();
  const history = useHistory();
  const {user} = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [testDialog, setTestDialog] = useState(false);
  const [cursosParticipante, setCursosParticipante] = useState<any | null>(null);
  // const getPlantillasCurso = useGetPlantillasCurso();
  const getCourses = useGetStudentCourses();

  async function fetchCursos() {
    try {
      setLoading(true);
      const fetchedCursos = await getCourses();
      printLog("Cursos fetcheados :: ", fetchedCursos);
      setCursosParticipante(fetchedCursos);
    } catch (error) {
      console.log("Error al obtener cursos >>> ", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCursos();
    return () => {
      setCursosParticipante(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCursosParticipante]);

  const procesarMachometro = () => {
    setTestDialog(true);
  };

  useEffect(() => {
    if (user !== null && (user as any)["testInicial"]) {
    }
    // Alerts.showInfo({
    //   title: "Bienvenida/o!",
    //   description:
    //     "Antes de comenzar con el contenido de tus cursos, completa este test para medir tu nivel de machismo. Sólo tendrás una oportunidad para hacerlo, así que asegúrate de contestar todas las preguntas.",
    //   confirmText: "Iniciar el TEST",
    //   onConfirm: () => {
    //     printLog("Iniciar el test");
    //     procesarMachometro();
    //   },
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoDetalleCurso = (_courseId: string) => {
    // history.push(`/cursos/participante/${_courseId}`)
    history.push(`/cursos/participante/detalle`, {courseId: _courseId});
  };

  return (<>
    <CDialog
      isOpen={testDialog}
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown
      handleClose={() => {
        setTestDialog(false);
      }}
    >
      <QuizViewerSection
        tituloQuiz="Machómetro"
        actionsPosition="static"
        idxContenido={undefined}
        tipo={"QUIZ"}
        loading={false}
        preguntas={machometroQuestions.map((item, idx: number) => ({
          indice: idx,
          titulo: item,
          tipo: TipoPregunta.SELECCION_UNICA,
          opcion1: {label: "Estoy de acuerdo", texto: "1"},
          opcion2: {label: "No estoy de acuerdo", texto: "0"},
        }))}
        onFinalizarQuiz={(data) => {
          printLog("Finalizar el quiz!!!! ", data);
        }}
        isEvaluated={false}
        onCancel={() => {
          setTestDialog(false);
        }}
      />
    </CDialog>
    <Grid container justifyContent="center" sx={{px: 3, pt: 3}}>
      <Grid item xs={12} md={9} sx={{pb: 2}}>
        <Typography
          variant="h1"
          sx={{
            fontFamily: "Barlow", fontSize: 42, color: theme.palette.neutral.hover, [theme.breakpoints.down("sm")]: {
              fontSize: 36,
            },
          }}
        >
          Mis cursos
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        {loading && <LinearProgress/>}
        {cursosParticipante !== null && cursosParticipante.length > 0 && cursosParticipante.map((cursoParticipante: any, idxCursoParticipante: number) => {
          const contenidoFinalizado = cursoParticipante.unidadesCompletadas === cursoParticipante.unidadesTotales;
          const mostrarMensajeTestSalida = contenidoFinalizado && !cursoParticipante.testSalidaCompletado;
          const mostrarMensajeCertificadoDisponible = contenidoFinalizado && cursoParticipante.testSalidaCompletado;

          return (
            <Card
              key={idxCursoParticipante}
              elevation={0}
              sx={{
                paddingX: 1.5,
                paddingTop: 2,
                paddingBottom: 3, // backgroundColor: 'rgba(177,32,180, .19)',
                border: `1px solid ${theme.palette.courseSecondary.main}`,
                borderRadius: "10px",
                display: "flex",
                gap: 2,
                justifyContent: "space-between",
                alignItems: "start",
                my: 2,
                cursor: "pointer",
                [theme.breakpoints.down("md")]: {
                  padding: 1, gap: 2,
                },
                "&:hover": {
                  backgroundColor: "rgba(177,32,180, .1)",
                },
              }}
              onClick={() => gotoDetalleCurso(cursoParticipante.id)}
            >
              <Box
                sx={{
                  width: "85px", height: "85px", // [theme.breakpoints.down('md')]: {
                  //   width: '60px',
                  //   height: '60px',
                  // },
                }}
              >
                <img
                  src={imageCursoFake}
                  // src={
                  //   cursoParticipante.urlImagen
                  //     ? cursoParticipante.urlImagen
                  //     : imageCursoFake
                  // }
                  style={{
                    borderRadius: "10px", height: "100%", width: "100%", objectFit: "cover",
                  }}
                  alt={`imgc-${idxCursoParticipante}`}
                />
              </Box>
              <Box
                flex="1"
                sx={{
                  display: "flex", flexDirection: "row", [theme.breakpoints.down("sm")]: {
                    flexDirection: "column", gap: 1,
                  },
                }}
              >
                <Box flex="1" display="flex" flexDirection="column" gap={1}>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: "14pt", fontWeight: 800, color: theme.palette.courseSecondary.main,
                    }}
                  >
                    {cursoParticipante.nombre}
                  </Typography>
                  {cursoParticipante.descripcion && <Typography
                    variant="body1"
                    sx={{
                      fontSize: "12pt", fontWeight: 500, color: theme.palette.grey[500],
                    }}
                  >
                    {cursoParticipante.descripcion}
                  </Typography>}
                  <Typography>
                    {`${cursoParticipante.unidadesCompletadas} de ${cursoParticipante.unidadesTotales} unidades finalizadas`}
                  </Typography>
                  {mostrarMensajeTestSalida && <Typography>
                    Completa el test de salida para poder acceder a tu certificado
                  </Typography>}
                  {mostrarMensajeCertificadoDisponible &&
                    <Typography fontWeight={"bold"}> Certificado disponible </Typography>}

                </Box>
              </Box>
            </Card>);
        })}
        {cursosParticipante !== null && cursosParticipante.length === 0 && (<Alert severity="warning">
          No se encontraron cursos disponibles
        </Alert>)}
      </Grid>
    </Grid>
  </>);
}

export default StudentCoursePage;
