import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CInputForm from "../../../../shared/components/form/CInputForm";
import { colorsBase, DesignSystem } from "../../../../themes/theme";
import { CFilePicker } from "../../../../shared/components/form/CFilePicker";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  CancelButton,
  ConfirmButton,
} from "../../../../shared/components/CButton";
import { ReactComponent as RolIcon } from "../../../../assets/icons/rol.svg";
import { ReactComponent as CancelCircleIcon } from "../../../../assets/icons/cancelCircle.svg";
import { isValidUrl, printLog } from "../../../../shared/utils";
import { AdjuntoBanner, IBannerForm } from "../BannerInterface";
import { useCreateBanner, useGetBannerById, useUpdateBanner } from "../data";

export type BannerPayload = IBannerForm & {
  // archivos: File[];
  adjuntoEliminado: any[];
};

type FormBannerComponentProps = {
  bannerId: string | null;
  bannerDialog: boolean;
  onProcesarBanner: () => void;
  onClose: () => void;
  loading: boolean;
};

export const FormBannerComponent = ({
  bannerId,
  bannerDialog,
  onProcesarBanner,
  onClose,
  loading,
}: FormBannerComponentProps) => {
  const [adjuntos, setAdjuntos] = useState<File[]>([]);
  const [currentAdjunto, setCurrentAdjunto] = useState<AdjuntoBanner | null>();
  const [deletedAdjuntos, setDeletedAdjuntos] = useState<AdjuntoBanner[]>([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IBannerForm>();
  // const http = useHttp();
  // const authContext = useAuthContext();

  const getBanner = useGetBannerById();
  const updateBanner = useUpdateBanner();
  const createBanner = useCreateBanner();

  const handleChangePicker = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files.length) {
      printLog(
        "[BannerForm] >  Archivos seleccionados de FilePicker :: target.files[0]",
        target.files![0]
      );
      setAdjuntos([...target.files]);
    }
  };

  const handleSubmitPublicar: SubmitHandler<IBannerForm> = async (data) => {
    printLog("Adjuntos del banner :: ", adjuntos);
    if (bannerId) {
      printLog("actualizar banner :: ", data);
      await updateBanner({ ...data }, bannerId);
    } else {
      printLog("guardar banner :: ", data);
      await createBanner({ ...data });
    }
    onClose();
    onProcesarBanner();
  };

  const handleCloseDialogBanner = () => {
    setAdjuntos([]);
    // setEditorState(() => EditorState.createEmpty());
    reset();
    onClose();
  };

  const fetchBanner = useCallback(async (bannerId: string) => {
    const fetchedBanner = await getBanner(bannerId);
    printLog("Banner fetched :: ", fetchedBanner);
    reset({
      titulo: fetchedBanner.titulo,
      esDestacado: fetchedBanner.esDestacado,
      urlRecurso: fetchedBanner.urlRecurso,
      // adjunto: fetchedBanner.adjunto,
    });
    setAdjuntos([]);
    // dispatch({ type: "LOAD_CAMPAIGN", campaign: fetchedCampaign });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bannerId) {
      fetchBanner(bannerId);
    }
  }, [fetchBanner, bannerId]);

  return (
    <Dialog
      open={bannerDialog}
      fullWidth
      maxWidth="md"
      onClose={handleCloseDialogBanner}
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: `1px solid ${colorsBase.BorderGray}` }}
        >
          <Typography component={"span"} sx={{ ...DesignSystem?.titulo1 }}>
            Banner destacado
          </Typography>
          <IconButton onClick={handleCloseDialogBanner}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box component={"form"} onSubmit={handleSubmit(handleSubmitPublicar)}>
        <DialogContent>
          <Grid container spacing={3} sx={{ px: 2 }}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Título*
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="titulo"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El campo Titulo es requerido",
                    validate: async (value) => {
                      if (value.trim() === "")
                        return "El campo Titulo es inválido";
                    },
                  }}
                  render={({ field }) => (
                    <CInputForm
                      inputIcon={<RolIcon />}
                      type="text"
                      placeholder="Escribe el título del banner destacado"
                      {...field}
                      ref={null}
                      error={errors.titulo}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  URL del recurso*
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="urlRecurso"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El campo URL Recurso es requerido",
                    validate: async (value) => {
                      if (!isValidUrl(value)) return "No es una url válida";
                      if (value.trim() === "")
                        return "El campo URL Recurso es inválido";
                    },
                  }}
                  render={({ field }) => (
                    <Box>
                      <CInputForm
                        inputIcon={<RolIcon />}
                        type="text"
                        placeholder="Escribe la URL del recurso"
                        {...field}
                        ref={null}
                        error={errors.urlRecurso}
                      />
                    </Box>
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Es destacado:
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="esDestacado"
                  defaultValue={false}
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} ref={null} />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Añadir adjunto
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Box display="flex" flexDirection="column">
                  <CFilePicker
                    multiple={false}
                    placeholder="Carga el archivo adjunto"
                    handleChange={(ev: ChangeEvent<HTMLInputElement>) =>
                      handleChangePicker(ev)
                    }
                  />
                  <Box>
                    {adjuntos.map((adjunto: File, indiceAdjunto: number) => (
                      <Chip
                        deleteIcon={
                          <IconButton
                            size="small"
                            sx={{ stroke: colorsBase.Rojo }}
                          >
                            <CancelCircleIcon width="20" height="20" />
                          </IconButton>
                        }
                        onDelete={() => {
                          const _adj = [...adjuntos];
                          _adj.splice(indiceAdjunto, 1);
                          setAdjuntos(_adj);
                        }}
                        label={adjunto.name ? `${adjunto.name}` : ""}
                        key={indiceAdjunto}
                        sx={{
                          margin: 0.5,
                          border: `1px solid ${colorsBase.Verde3}`,
                        }}
                      />
                    ))}
                  </Box>
                  <Box>
                    {!!currentAdjunto &&
                    currentAdjunto &&
                    adjuntos.length === 0 ? (
                      <Chip
                        deleteIcon={
                          <IconButton
                            size="small"
                            sx={{ stroke: colorsBase.Rojo }}
                          >
                            <CancelCircleIcon width="20" height="20" />
                          </IconButton>
                        }
                        onDelete={() => {
                          console.log("eliminando adjunto!!");
                          const _adj = { ...currentAdjunto };
                          setCurrentAdjunto(null);
                          const _deletedAdj = [...deletedAdjuntos];
                          _deletedAdj.push(_adj);
                          setDeletedAdjuntos(_deletedAdj);
                        }}
                        label={`${currentAdjunto.nombre}`}
                        sx={{
                          margin: 0.5,
                          border: `1px solid ${colorsBase.Verde3}`,
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            margin: 2,
            marginTop: 0,
            paddingTop: 1,
            borderTop: `1px solid ${colorsBase.BorderGray}`,
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <CancelButton onClick={handleCloseDialogBanner}>
              Cancelar
            </CancelButton>
            <ConfirmButton type="submit" sx={{ ml: 2 }} loading={loading}>
              Guardar
            </ConfirmButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
