import React, { useState } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Box,
  Container,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  Divider,
  ListItemText,
  ListItemIcon,
  Avatar,
  Typography,
  Menu,
  alpha,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import {
  ArrowForwardIos,
  DashboardRounded,
  FacebookRounded,
  HelpRounded,
  Instagram,
  KeyRounded,
  LogoutRounded,
  YouTube,
} from "@mui/icons-material";
import { LayoutProps } from "../../types/LayoutType";
import theme from "../../themes";
import { useHistory, useRouteMatch } from "react-router-dom";
import MenuIconBox from "./MenuIconBox";
import { useAuthContext } from "../../shared/auth/AuthContext";
import { Alerts } from "../../shared/hooks/useAlert";
import { printLog } from "../../shared/utils";

const pages = [
  { name: "Quienes somos", url: "/quienes-somos" },
  // { name: "Cursos", url: "/cursos" }, // cursos admin y estudiante
  { name: "Cursos", url: "/catalogo-cursos" }, // catalogo de cursos
  { name: "Buenas prácticas", url: "/buenas-practicas" },
  // { name: "Hemeroteca", url: "/hemeroteca" },
  { name: "Directorio Municipal", url: "/directorio-municipal" },
  { name: "Cifras de tu Municipio", url: "/cifras-municipio" },
  { name: "Experiencias Innovadoras", url: "/experiencias-innovadoras" },
  { name: "Comunicación", url: "/comunicacion" },
];

const StyledDivContainer = styled("div")(() => ({
  display: "flex",
  // overflow: "auto",
  minHeight: "100vh",
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  zIndex: theme.zIndex.drawer + 1,
  maxHeight: "68.5px",
  margin: 0,
  padding: 0,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: "100%",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
}));

const StyledMain = styled("main")(({ theme, color }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  maxWidth: "calc(100wh - 50px)",
  maxHeight: "calc(100vh - 50px)",
  background: color ? (color !== "custom" ? color : "initial") : "white",
  backgroundImage:
    color && color === "custom"
      ? "radial-gradient(circle at 44% 9%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 74%,transparent 74%, transparent 83%,transparent 83%, transparent 100%),radial-gradient(circle at 0% 23%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 8%,transparent 8%, transparent 69%,transparent 69%, transparent 100%),radial-gradient(circle at 87% 35%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 10%,transparent 10%, transparent 61%,transparent 61%, transparent 100%),radial-gradient(circle at 22% 7%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 16%,transparent 16%, transparent 88%,transparent 88%, transparent 100%),radial-gradient(circle at 37% 26%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 7%,transparent 7%, transparent 9%,transparent 9%, transparent 100%),radial-gradient(circle at 86% 59%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 77%,transparent 77%, transparent 95%,transparent 95%, transparent 100%),radial-gradient(circle at 75% 83%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 35%,transparent 35%, transparent 86%,transparent 86%, transparent 100%),linear-gradient(0deg, hsl(279,0%,100%),hsl(279,0%,100%))"
      : "initial",
}));

// const StyledMdMenuButton = styled(Button)(({ theme }) => ({
//   color: theme.palette.neutral.main,
//   minWidth: "initial",
//   padding: 0,
//   border: '.2px solid red',
//   "&:hover": {
//     background: 'transparent',
//     color: theme.palette.secondary.hover,
//   },
// }));

const Layout: React.FC<LayoutProps> = ({ children, bgColor }) => {
  let history = useHistory();
  const authContext = useAuthContext();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  // const isDesktop = useMediaQuery(theme.breakpoints.down("lg"));
  const match = useRouteMatch();
  printLog(" *** url match *** ", match);

  const handleGoToPage = (page: { name: string; url: string }) => {
    printLog("go to page > ", page);
    history.push(page.url);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      )
        return;

      setIsDrawerOpen(open);
    };

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {pages.map((page, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={() => handleGoToPage(page)}>
              <ListItemText primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  const listAuth = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            color="neutral"
            onClick={() => {
              printLog("ver mis cursos!...");
              history.push("/cursos");
            }}
          >
            <ListItemText>Mis cursos</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            color="neutral"
            onClick={() => {
              printLog("Cambiar ccontraseña!...");
              handleClose();
            }}
          >
            <ListItemText>Cambiar contraseña</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            color="neutral"
            onClick={() => {
              printLog("Ver ayuda!...");
              handleClose();
            }}
          >
            <ListItemText>Ayuda</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton color="neutral" onClick={handleCloseSession}>
            <ListItemText>Cerrar sesión</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSession = async () => {
    handleClose();
    Alerts.showConfirm({
      title: "¿Segura (o) que deseas cerrar sesión?",
      onConfirm() {
        authContext.logout({
          onSuccess: async () => {
            localStorage.removeItem("user");
            history.push("/", { replace: true });
          },
          onFailure: () => {},
        });
      },
      confirmText: "Sí, continuar",
    });
  };

  return (
    <StyledDivContainer>
      <CssBaseline />
      <StyledAppBar position="fixed">
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <StyledToolbar
            disableGutters
            sx={{
              width: { xs: "100%", md: "75%" },
              height: "100%",
              marginLeft: { md: "-15px", lg: "-15px" },
              padding: 0,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
              }}
            >
              <MenuIconBox />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
              >
                {pages.map((page) => (
                  // <StyledMdMenuButton
                  //   size={isDesktop ? 'small' : 'medium'}
                  //   key={page.name}
                  //   onClick={() => handleGoToPage(page)}
                  //   sx={{
                  //     mb: 0,
                  //     mt: {xs:0, md:0},
                  //     display: "block",
                  //     fontFamily:'Barlow',
                  //     lineHeight: {md:1},
                  //     fontSize: { xs: "0.550rem", lg: "0.850rem" },
                  //   }}
                  // >
                  //   {page.name}
                  // </StyledMdMenuButton>
                  <Link
                    key={page.name}
                    component={"button"}
                    underline="none"
                    onClick={() => handleGoToPage(page)}
                    sx={{
                      textTransform: "uppercase",
                      // border: '.2px solid green',
                      textAlign: "center",
                      mb: 0,
                      mt: { xs: 0, md: 0 },
                      fontWeight: 600,
                      fontFamily: "Barlow",
                      lineHeight: { md: 1 },
                      fontSize: { xs: "0.550rem", lg: "0.850rem" },
                      color:
                        match.url === page.url
                          ? theme.palette.secondary.active
                          : theme.palette.neutral.hover,
                      "&:hover": {
                        background: "transparent",
                        color:
                          match.url === page.url
                            ? theme.palette.secondary.active
                            : theme.palette.neutral.active,
                      },
                    }}
                  >
                    {page.name}
                  </Link>
                ))}
                <FacebookRounded
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/profile.php?id=61551458335758",
                      "_blank"
                    )
                  }
                  sx={{
                    color: theme.palette.neutral.hover,
                    "&:hover": { color: theme.palette.secondary.active },
                  }}
                />
                <Instagram
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/vidadignasinviolencia/",
                      "_blank"
                    )
                  }
                  sx={{
                    color: theme.palette.neutral.hover,
                    "&:hover": { color: theme.palette.secondary.active },
                  }}
                />
                <YouTube
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@SolidarSuizabolivia",
                      "_blank"
                    )
                  }
                  sx={{
                    color: theme.palette.neutral.hover,
                    "&:hover": { color: theme.palette.secondary.active },
                  }}
                />

                {authContext.user && (
                  <IconButton
                    size="small"
                    sx={{
                      border: `2px solid ${theme.palette.primary.main}`,
                      background: alpha(theme.palette.neutral.main, 0.4),
                      px: 1.5,
                    }}
                    onClick={handleMenu}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        textTransform: "uppercase",
                        color: alpha(theme.palette.common.black, 0.7),
                      }}
                    >
                      {authContext.user?.displayName?.substring(0, 1)}
                    </Typography>
                  </IconButton>
                )}
              </Box>
            </Box>
            {authContext.user && (
              <Box>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    // width: '1000px',
                    "& .MuiPaper-root": {
                      borderRadius: "18px",
                    },
                  }}
                >
                  <Box>
                    <Box display={"flex"} gap={2} sx={{ my: 2, px: 3, py: 1 }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "1.2rem",
                            color: "black",
                          }}
                        >
                          {authContext.user.displayName ?? ""}
                        </Typography>
                        {/* <Box mt={2} width={250}>
                        <Typography sx={{ color: 'orange', fontSize: '.8rem', fontWeight: 500 }}>
                          Rol
                        </Typography>
                        <Typography sx={{wordBreak: 'break-all' }}>
                          { authContext.user.rol ?? '' }
                        </Typography>
                      </Box> */}
                        <Box mt={2} width={250}>
                          <Typography
                            sx={{
                              color: "orange",
                              fontSize: ".8rem",
                              fontWeight: 500,
                            }}
                          >
                            Correo electrónico
                          </Typography>
                          <Typography sx={{ wordBreak: "break-all" }}>
                            {authContext.user.email ?? ""}
                          </Typography>
                        </Box>
                      </Box>
                      <Avatar
                        sx={{
                          border: `4px solid ${theme.palette.primary.main}`,
                          p: 4,
                          background: alpha(theme.palette.neutral.main, 0.4),
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.3rem",
                            textTransform: "uppercase",
                            color: alpha(theme.palette.common.black, 0.7),
                          }}
                        >
                          {authContext.user!.displayName?.substring(0, 1)}
                        </Typography>
                      </Avatar>
                    </Box>
                    <Divider></Divider>
                    <ListItem disablePadding>
                      <ListItemButton
                        color="neutral"
                        onClick={() => {
                          printLog("ver mis cursos!...");
                          history.push("/cursos");
                        }}
                      >
                        <ListItemIcon>
                          <DashboardRounded />
                        </ListItemIcon>
                        <ListItemText>Mis cursos</ListItemText>
                        <ArrowForwardIos />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                      <ListItemButton
                        color="neutral"
                        onClick={() => {
                          printLog("Cambiar ccontraseña!...");
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <KeyRounded />
                        </ListItemIcon>
                        <ListItemText>Cambiar contraseña</ListItemText>
                        <ArrowForwardIos />
                      </ListItemButton>
                    </ListItem>
                    <Divider></Divider>
                    <ListItem disablePadding>
                      <ListItemButton
                        color="neutral"
                        onClick={() => {
                          history.push("/ayuda");
                        }}
                      >
                        <ListItemIcon>
                          <HelpRounded />
                        </ListItemIcon>
                        <ListItemText>Ayuda</ListItemText>
                        <ArrowForwardIos />
                      </ListItemButton>
                    </ListItem>
                    <Divider></Divider>
                    <ListItem disablePadding>
                      <ListItemButton
                        color="neutral"
                        onClick={handleCloseSession}
                      >
                        <ListItemIcon>
                          <LogoutRounded />
                        </ListItemIcon>
                        <ListItemText>Cerrar sesión</ListItemText>
                        <ArrowForwardIos />
                      </ListItemButton>
                    </ListItem>
                  </Box>
                </Menu>
              </Box>
            )}
            <Box
              sx={{
                width: "100%",
                display: { xs: "flex", md: "none" },
                justifyContent: "space-between",
                marginLeft: "auto",
              }}
            >
              <Box>&nbsp;</Box>
              <MenuIconBox />
              <IconButton
                size="large"
                aria-label="mobile menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <StyledSwipeableDrawer
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              anchor="right"
            >
              <>
                {list()}
                {authContext.user && listAuth()}
              </>
            </StyledSwipeableDrawer>
          </StyledToolbar>
        </Container>
      </StyledAppBar>
      <StyledMain color={bgColor} id="childrenContent">
        {/* MUI Bug fix https://mui.com/material-ui/react-app-bar/#fixed-placement */}
        <Toolbar />
        {children}
      </StyledMain>
    </StyledDivContainer>
  );
};

export default Layout;
