import { Box, Button, Typography, styled } from "@mui/material";
import theme from "../../../../themes";
import imgPractice from "../../../../assets/GoodMunicipalPractices/good_practice.png";
import { AuthType } from "../../../Auth";
import { useState } from "react";
import AuthDialog from "../Auth";

const StyledBox = styled(Box)(() => ({
  margin: "0 0 0 0",
  padding: 0,
  width: "100%",
  backgroundColor: "#dedede",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  color: "#FFFFFF",
  borderRadius: "8px",
  backgroundColor: theme.palette.success.main,
  width: "200px",
  height: "50px",
  background:
    "linear-gradient(to top, rgba(0, 124, 60, 0.6), rgba(0, 124, 60, 0.6))",
  "&:hover": {
    backgroundColor: theme.palette.success.active,
    color: "#E8E8E8",
  },
}));

const StyledImg = styled("img")(() => ({
  maxWidth: "100%",
  height: "100%",
  objectFit: "contain",
}));

const GoodPracticeForm = () => {
  const [authForm, setAuthForm] = useState(false);
  const [typeAuth, setTypeAuth] = useState<AuthType>();

  const handleOpenAuthForm = (type: AuthType) => {
    setTypeAuth(type);
    setAuthForm(true);
  };

  const handleCloseAuthForm = () => {
    setAuthForm(false);
  };

  return (
    <StyledBox display="flex" justifyContent="center" id="formularioBuenasPrac">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          sx={{ width: "100%", height: "100%" }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
                padding: "1.5em 1.5em 1.5em 1.5em",
              },
            }}
          >
            <Typography
              style={{
                fontFamily: "Ubuntu",
                fontSize: 16,
                color: theme.palette.neutral.active,
              }}
            >
              En esta sección, los municipios comparten sus buenas prácticas y
              lecciones aprendidas, abordando de manera integral la garantía del
              ejercicio pleno de los derechos de las mujeres. En particular, se
              enfocan en el derecho a una vida libre de violencia, los derechos
              sexuales, los derechos reproductivos, la autonomía económica y la
              participación política.
            </Typography>
          </Box>
          <Box
            display="flex"
            sx={{
              width: {
                xs: "100%",
                md: "50%",
                padding: "1.5em 1.5em 1.5em 1.5em",
              },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={2}
              sx={{
                width: "60%",
                height: "100%",
                borderLeft: {
                  xs: "none",
                  md: `1px solid ${theme.palette.success.hover}`,
                },
                borderTop: {
                  xs: `1px solid ${theme.palette.success.hover}`,
                  md: "none",
                },
                pl: "1.5rem",
                pt: 0,
                [theme.breakpoints.down("md")]: {
                  pl: 0,
                  pt: "1.5rem",
                },
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Barlow",
                    fontSize: 22,
                    color: theme.palette.success.hover,
                  }}
                >
                  Envía tus buenas prácticas
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    color: theme.palette.neutral.active,
                  }}
                >
                  ¡Te animamos a compartir tus buenas prácticas! Simplemente
                  completa el siguiente formulario.
                </Typography>
              </Box>
              <Box>
                <StyledButton
                  variant="text"
                  onClick={() => handleOpenAuthForm(AuthType.REGISTER)}
                >
                  Llena el formulario
                </StyledButton>
              </Box>
            </Box>
            <Box
              sx={{
                width: "40%",
                height: "100%",
                borderTop: {
                  xs: `1px solid ${theme.palette.success.hover}`,
                  md: "none",
                },
              }}
            >
              <StyledImg src={imgPractice} alt="img" />
            </Box>
          </Box>
        </Box>
      </Box>
      {authForm && <AuthDialog open={authForm} onClose={handleCloseAuthForm} />}
    </StyledBox>
  );
};

export default GoodPracticeForm;
