export const machometroQuestions = [
  "A veces, es necesario usar la violencia para educar a las mujeres.",
  "Si un hombre golpea a su pareja o esposa, es algo privado y nadie debería meterse.",
  "Los hombres normalmente resuelven sus problemas a golpes y las mujeres hablando.",
  "Está bien interrumpir a una mujer cuando su opinión nos parece poco importante o aburrida.",
  "Cuando las personas tienen una relación sentimental con alguien de su mismo sexo, no es normal y esas personas necesitan tratamiento, orientación o apoyo.",
  "Para que las hijas y los hijos estén bien, es mejor que las mujeres se dediquen a cuidar el hogar.",
  "Cuando alguien de la familia se enferma, lo mejor es que una mujer le cuide.",
  "Los hombres trabajan más que las mujeres, por eso está bien que reciban más dinero que ellas.",
  "Estaría mal que mi hijo hombre se quede en casa haciendo las tareas del hogar, mientras su pareja o esposa trabaja.",
  "Las mujeres necesitan ser madres para ser felices.",
  "Es mejor que un hombre dirija un equipo de trabajo u organización, porque la mujer tiene responsabilidades con el cuidado de los y las hijas.",
  "Los hombres deben corregir la forma de vestir de sus parejas o de las mujeres de su familia para protegerlas.",
  "Cuando a una persona le gusta alguien de su mismo sexo es mejor que se quede en secreto, ya que es un mal ejemplo para la comunidad/municipio.",
  "Está bien que un hombre, insista o presione a una mujer para tener relaciones sexuales, aunque ella no quiera.",
  "Sólo el hombre debe decidir cuántos hijos o hijas tendrá con su pareja, porque él tendrá que mantenerlos/as.",
  "No es correcto que un hombre abrace y bese a sus hijos hombres o a sus amigos",
  "Las mujeres deben dar explicaciones sobre sus salidas y amistades a sus parejas para evitar problemas.",
  "Los hombres son mejores jefes, líderes y dirigentes que las mujeres.",
  "Los hombres por naturaleza tienen varias parejas sentimentales y sexuales.",
  "Las mujeres son acosadas en la calle por su forma provocativa de vestir.",
  "Los hombres son violentos, porque las mujeres son infieles, flojas, no quieren cocinar y atender a sus hijos, pareja o familia.",
  "Las mujeres que son dirigentes, dejan de lado a sus hijos/as, a sus esposos, y se vuelven rebeldes.",
  "Las mujeres y niñas necesitan la protección de un hombre para estar seguras.",
  "Las mujeres de ahora no son cómo las de antes, porque ya no saben cocinar y atender su hogar.",
  "A los niños/as es bueno educarlos tradicionalmente: a los niños hacerlos fuertes y valientes, y a las niñas enseñarles a cuidar de su hogar y a sus hijos.",
  "Un hombre para realizarse debe formar una familia, con una esposa e hijos/as.",
];
