import { Box, Button, Typography, styled } from "@mui/material";
import solidarLogo from '../../../../assets/solidar_logo.jpg';
import theme from "../../../../themes";

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Barlow',
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '8px',
  color: theme.palette.secondary.active,
  marginTop: 30,
  width: '200px',
  height: '50px',
  '&:hover': {
    backgroundColor: theme.palette.primary.hover,
    color: '#FFFFFF',
  }
}));

const StyledBox = styled(Box)(() => ({
  backgroundColor: '#FFFFFF',
  padding: '0 1.5em 0 1.5em',
}));

const StyledImg = styled('img')(() => ({
  maxWidth: '100%',
  height: '113px',
  objectFit: 'contain',
}));

const SolidarContent = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: { xs: 'auto', md: '208px' }
      }}
    >
      {/* <Box sx={{ width: { xs: '100%', md: theme.breakpoints.values.md, lg: theme.breakpoints.values.lg }, height: '100%' }}> */}
      <StyledBox sx={{ width: { xs: '100%', md: '75%' } }}>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ margin: 0, padding: 0, height: '100%' }}>
          <Box display="flex" flexDirection="column" sx={{ width: { xs: '100%', md: '60%' } }}>
            <Typography variant="h2" sx={{ fontFamily: 'Barlow', fontSize: '32px', color: theme.palette.neutral.hover }}>
              Accede a más contenido de Solidar Suiza
            </Typography>
            <StyledButton onClick={() => window.open('https://solidar-suiza.org.bo/vida-digna-sin-violencia', '_blank')}>
              Leer Completo
            </StyledButton>
          </Box>
          <Box display="flex" alignItems="center" sx={{ width: { xs: '100%', md: '40%' }, margin: 0, padding: 0, height: '100%', order: { xs: -1, md: 2 } }}>
            <StyledImg src={solidarLogo} alt="logo" />
          </Box>
        </Box>
      </StyledBox>
      {/* </Box> */}
    </Box>
  )
};

export default SolidarContent;