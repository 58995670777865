import { useEffect, useState } from "react";
import data from "./data";
import Timeline from "@mui/lab/Timeline";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import TimelineCard from "./TimelineCard";
import { Box, styled, useMediaQuery } from "@mui/material";
import { TimelineCardProps } from "../../types/TimelineCard";
import theme from "../../themes";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  paddingTop: "2rem",
  paddingBottom: "1rem",
  width: "100%",
}));

const TimelineTree: React.FC<any> = ({ nodes }) => {
  const [dataSliced, setDataSliced] = useState<TimelineCardProps[]>([]);
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    let tmpArray = data.slice(0, nodes);
    setDataSliced(tmpArray);
  }, [nodes]);

  return (
    <StyledBox display="flex" justifyContent={"center"}>
      <Box
        sx={{
          px: 4,
          [theme.breakpoints.down("lg")]: {
            px: 2,
          },
          [theme.breakpoints.down("sm")]: {
            px: 0,
          },
        }}
      >
        <Timeline sx={{ margin: 0, padding: 0 }}>
          {dataSliced.map((item, index) => (
            <TimelineItem
              position={xs || sm ? "right" : index % 2 !== 0 ? "left" : "right"}
              key={index}
              sx={{
                padding: 0,
                width: "100%",
                [theme.breakpoints.down("md")]: {
                  "&:before": {
                    content: "initial",
                  },
                },
              }}
            >
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px" }}>
                <TimelineCard
                  image={item.image}
                  year={item.year}
                  content={item.content}
                  link={item.link ? item.link : ""}
                />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </StyledBox>
  );
};

export default TimelineTree;
