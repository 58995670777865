import {
  DeleteRounded,
  EditRounded,
  KeyboardBackspaceRounded as KeyboardBackspaceRoundedIcon,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DesignSystem } from "../../../themes/theme";
import { useDeleteCampaign, useGetCampaigns } from "./data";
import { printLog } from "../../../shared/utils";
import { Campaign } from "./interface";
import { Alerts } from "../../../shared/hooks/useAlert";

export const CommunicationCampaignPage = () => {
  const navigate = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const getCampaigns = useGetCampaigns();
  const deleteCampaign = useDeleteCampaign();

  async function fetchCampaigns() {
    setLoading(true);
    const fetchedCampaigns = await getCampaigns("NACIONAL");
    printLog("campañas fetcheadas :: ", fetchedCampaigns);
    setCampaigns(fetchedCampaigns as unknown as []);
    setLoading(false);
  }

  useEffect(() => {
    fetchCampaigns();
    return () => {
      setCampaigns([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={6}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate.push("/admin/comunicacion", {
                replace: true,
              })
            }
          >
            <KeyboardBackspaceRoundedIcon />
          </IconButton>
          <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
            Campaña principal
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate.push("/admin/comunicacion/campana/detalle", {
                replace: true,
              });
            }}
          >
            Agregar +
          </Button>
        </Box>
      </Box>
      <Box my={3} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
        px={3}
      >
        {loading && <LinearProgress />}
        {!loading && !campaigns.length && (
          <Box sx={{ width: "100%" }}>
            <Alert severity="warning">
              No se tiene datos de campañas nacionales
            </Alert>
          </Box>
        )}
        {!loading &&
          !!campaigns.length &&
          campaigns.map((campaign, idxCampaign) => (
            <Card
              key={idxCampaign}
              elevation={0}
              sx={{
                p: 2,
                width: "100%",
                borderRadius: 2,
              }}
            >
              <Box display={"flex"} alignItems={"center"} gap={3}>
                <Typography
                  variant="body1"
                  fontWeight={"medium"}
                  sx={{ flex: 1 }}
                >
                  {campaign.titulo}
                </Typography>
                <Box>
                  <IconButton
                    size="small"
                    color="warning"
                    onClick={() => {
                      printLog("editar campaña");
                      // comunicacion/campana/detalle
                      navigate.push("/admin/comunicacion/campana/detalle", {
                        campaignId: campaign.id,
                      });
                    }}
                  >
                    <EditRounded />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => {
                      printLog("eliminar campaña");
                      Alerts.showConfirm({
                        title: "¿Estás segura(o) de eliminar la campaña?",
                        description:
                          "Tenga en cuenta que no podrá recuperar los datos de la campaña eliminada luego de confirmar.",
                        onConfirm: async () => {
                          await deleteCampaign(campaign.id!);
                          await fetchCampaigns();
                        },
                      });
                    }}
                  >
                    <DeleteRounded />
                  </IconButton>
                </Box>
              </Box>
            </Card>
          ))}
      </Box>
    </React.Fragment>
  );
};
