import { useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { items } from './items';
import Banner from "./Banner";
import { Settings } from "../../../../interfaces/CarouselBannerProps";
import DefaultSettings from "./DefaultSettings";
import MobileSettings from "./MobileSettings";
import theme from "../../../../themes";

const HomeCarousel = () => {

  const matchMd: boolean = useMediaQuery(theme.breakpoints.down("md"));
  let settings: Settings = matchMd ? MobileSettings : DefaultSettings;

  return (
    <div style={{backgroundColor: "#ededed",}}>
      <Carousel
        {...settings}
      >
        {
          items.map((item, index) => {
            return <Banner item={item} key={index} />
          })
        }
      </Carousel>
    </div>
  )
};



export default HomeCarousel;