import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Slide,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseRounded } from "@mui/icons-material";
import { MouseEventHandler, forwardRef } from "react";

export const CoursePrimaryButton = styled((props: ButtonProps) => (
  <Button variant="contained" disableElevation color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  background:
    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
  fontFamily: "Barlow",
  borderRadius: "8px",
  color: "white",
  paddingTop: ".5rem",
  paddingBottom: ".5rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
  // border: "1px solid rgba(0,119,170,1)",
  "&:hover": {
    background:
      "linear-gradient(180deg, rgba(0,191,232,.95) 0%, rgba(0,154,219,.95) 53%, rgba(0,119,170,.95) 100%)",
  },
}));

export const CourseWhiteButton = styled((props: ButtonProps) => (
  <Button variant="contained" disableElevation color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.9),
  borderColor: alpha(theme.palette.common.white, 0.9),
  color: theme.palette.common.black,
  "&:hover": {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  fontFamily: "Barlow",
  borderRadius: "8px",
  paddingTop: ".5rem",
  paddingBottom: ".5rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
}));

export const CoursePrimaryButtonText = styled((props: ButtonProps) => (
  <Button variant="text" disableElevation color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  textTransform: "initial",
  color: theme.palette.info.main,
  fontSize: "1.1rem",
}));

export const CourseNeutralButton = styled((props: ButtonProps) => (
  <Button variant="outlined" color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral.main}`,
  background: "white",
  borderRadius: "8px",
  minWidth: "initial",
  color: theme.palette.neutral.main,
  py: 1,
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CourseAlertDialog = styled((props: any) => (
  <Dialog
    maxWidth={"xs"}
    TransitionComponent={Transition}
    keepMounted
    {...props}
  />
))`
  & .MuiPaper-root {
    border-radius: 8px;
  }
`;

type CourseContentDialogProps = {
  withActions?: boolean;
  withTitle?: boolean;
  ctitle?: string;
  actiontext?: string;
  handleConfirm?: VoidFunction;
};

export const CourseContentDialog = styled(
  ({
    withTitle = true,
    withActions = true,
    handleConfirm,
    ...props
  }: DialogProps & CourseContentDialogProps) => (
    <Dialog maxWidth={"sm"} fullWidth keepMounted {...props}>
      <Box sx={{ position: "relative" }}>
        <Box
          display="flex"
          justifyContent={"end"}
          alignItems={"center"}
          sx={{
            m: 0,
            p: 0.5,
          }}
        >
          <IconButton onClick={props.onClose as MouseEventHandler}>
            <CircleBox sx={{ background: "#818080", p: 0.5 }}>
              <CloseRounded sx={{ fontSize: "1rem" }} />
            </CircleBox>
          </IconButton>
        </Box>
        <DialogContent sx={{ paddingTop: 0, marginTop: 0 }}>
          {withTitle && (
            <Box mb={3}>
              <Typography sx={{ fontSize: "1.6rem" }}>
                {props.ctitle}
              </Typography>
            </Box>
          )}
          <Box>{props.children}</Box>
          {withActions && (
            <Box
              sx={{
                paddingTop: 5,
                paddingBottom: 3,
                position: "relative",
                zIndex: 99,
              }}
            >
              <Box display="flex" justifyContent="flex-end">
                <CourseNeutralButton
                  onClick={props.onClose as MouseEventHandler}
                >
                  Cancelar
                </CourseNeutralButton>
                <CoursePrimaryButton sx={{ ml: 2 }} onClick={handleConfirm}>
                  {props.actiontext}
                </CoursePrimaryButton>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  )
)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    paddingTop: ".5rem",
    background: "#FFFFFF",
  },
}));

export const CircleBox = styled(Box)(({ theme }) => ({
  background:
    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
  borderRadius: "50%",
  color: "white",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

// TODO: background wave
// izq a centro > f7f7f7
// centro a derecha > E0E0E0
// derecha a centro > EDEDED
