import axios, {AxiosError} from "axios";
import {Auth, LoginParams, LogoutParams, RegisterParams, Subscription, UserRecord,} from "./Auth";
import {Alerts} from "../hooks/useAlert";
import {printLog} from "../utils";

export default class AuthVdsv implements Auth {
  private currentUser: UserRecord | null = null;
  private subscriber: Subscription | null = null;
  private urlBase = "/api";

  async init(): Promise<void> {}
  async login(params: LoginParams): Promise<UserRecord | null> {
    let user: any = {
      email: params.username,
      uid: params.password,
      displayName: params.username,
      token: params.password,
      tokenFCM: "<token-push-notifications-locale>",
    };
    printLog("user for auth", user);
    const response = await axios.post(
      `${this.urlBase}/session/login`,
      {
        email: params.username,
        password: params.password,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    printLog("👽 Respuesta de login >>>> ", response);
    if (response.status === 200) {
      printLog("response de current login :: ", response.data);
      const datos = response.data;
      datos.displayName = `${datos.nombres ?? ""} ${datos.primerApellido ?? ""} ${
        datos.segundoApellido ?? ""
      }`
      user = {
        ...user,
        ...datos,
      };
    }
    this.currentUser = user;
    params.onSuccess(user);
    this.subscriber?.apply(this, [this.currentUser]);

    return user;
  }
  async logout(params: LogoutParams): Promise<void> {
    // if (this.currentUser == null) {
    //   throw new Error("Usuario no autenticado");
    // }
    // this.subscriber?.apply(this, [this.currentUser]);
    const response = await axios.post(`${this.urlBase}/session/logout`);
    printLog("👽 Respuesta de logout >>>> ", response);
    params.onSuccess();
  }
  async isLoggedIn(): Promise<boolean> {
    return this.currentUser != null;
  }
  async getCurrentToken(): Promise<string | null> {
    return this.currentUser?.uid ? this.currentUser.uid : null;
  }
  getCurrentTokenID(): Promise<string | null> {
    return this.getCurrentToken();
  }

  subscribe(onAuthChange: Subscription): void {
    this.subscriber = onAuthChange;
    onAuthChange(this.currentUser);
  }
  unsubscribe(): void {
    this.subscriber = null;
  }

  async resetPassword(email: string) {
    printLog("Reestableciendo la contraseña... ", email);
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    printLog("Actualizando la contraseña en... ", oldPassword, newPassword);
  }

  async register(params: RegisterParams) {
    printLog("🛸 user participant for register > ", params.userRegister);
    const { userRegister } = params;
    try {
      let user: any = {
        email: userRegister.email,
        displayName: `${userRegister.nombres ?? ""} ${userRegister.primerApellido ?? ""} ${
          userRegister.segundoApellido ?? ""
        }`,
        tokenFCM: "<token-push-notifications-locale>",
      };
      const responseRegister = await axios.post(
        `${this.urlBase}/user/participante`,
        { ...userRegister },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      printLog("👽 Respuesta de register >>>> ", responseRegister);
      if (responseRegister.status === 200) {
        printLog("Registro >>> ", responseRegister.data);
        const responseLogin = await axios.post(
          `${this.urlBase}/session/login`,
          {
            email: userRegister.email,
            password: userRegister.password,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        printLog("👽 Respuesta de login >>>> ", responseLogin);
        if (responseLogin.status === 200) {
          printLog("response de login :: ", responseLogin.data);
          const datos = responseLogin.data;
          datos.displayName = datos.nombreCompleto;
          delete datos.nombreCompleto;
          user = {
            ...user,
            ...datos,
          };
        }
        this.currentUser = user;
        params.onSuccess(user);
        this.subscriber?.apply(this, [this.currentUser]);
        return user;
      } else {
        debugger;
        console.log("ResponseRegister");
        Alerts.showError({
          description:
            "No se pudo registrar al participante" + responseRegister.data,
        });
        return null;
      }
    } catch (error) {
      const errorMessage =
        error instanceof AxiosError ? error.response?.data?.error ?? "" : "";

      printLog("🔥 Error en el proceso de registro >> ", error);
      Alerts.showError({
        description: "No se pudo registrar al participante.\n" + errorMessage,
      });
    }
  }
}
