import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { TipoPregunta, TipoPreguntaEntregable } from "../../../interface";
// import { UploadFileRounded, VideoLibraryRounded } from '@mui/icons-material'
import bgCargaVideoIcon from './../../../../../assets/course/tipo-pregunta/carga_video.png';
import bgCargaVideoSelectedIcon from './../../../../../assets/course/tipo-pregunta/carga_video_hover.png';
import bgCargarArchivoIcon from './../../../../../assets/course/tipo-pregunta/carga_archivo.png';
import bgCargarArchivoSelectedIcon from './../../../../../assets/course/tipo-pregunta/carga_archivo_hover.png';

type QuestionTypeSelectorProps = {
  tipoSeleccionado: TipoPreguntaEntregable | "";
  onchangeTipoPregunta: (tipo: TipoPreguntaEntregable) => void;
};

export function QuestionTypeSelector({
  tipoSeleccionado, onchangeTipoPregunta,
}: QuestionTypeSelectorProps) {
  const theme = useTheme();
  const onSeleccion = (tipo: TipoPreguntaEntregable) => {
    if (tipoSeleccionado !== tipo) {
      onchangeTipoPregunta(tipo);
    }
  };
  return (
    <Grid item xs={12}>
      <Grid container direction="column" spacing={2} justifyContent="center">
        <Grid item container spacing={2} justifyContent="center">
          <Grid item>
            <Box
              component={Button}
              onClick={() => onSeleccion(TipoPregunta.CARGA_ARCHIVO)}
              display={'flex'}
              gap={1}
              flexDirection={'column'}
              alignItems={'center'}
            >
              {/* <Box sx={{ color: 'white', borderRadius: '10px', overflow: 'auto', p: 1, display: 'flex', background: tipoSeleccionado === TipoPregunta.CARGA_ARCHIVO ? "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)" : '#cacaca' }}>
                <UploadFileRounded />
              </Box> */}
              <Box sx={{ borderRadius: '10px', overflow: 'auto', display: 'flex' }}>
                <img src={tipoSeleccionado === TipoPregunta.CARGA_ARCHIVO ? bgCargarArchivoSelectedIcon : bgCargarArchivoIcon} width={'100%'} height={'100%'} alt="archivo-icon" />
              </Box>

              <Typography sx={{ color: tipoSeleccionado === TipoPregunta.CARGA_ARCHIVO ? theme.palette.info.main : 'black', textTransform: 'initial' }}>
                Carga de archivo
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              component={Button}
              onClick={() => onSeleccion(TipoPregunta.CARGA_VIDEO)}
              display={'flex'}
              gap={1}
              flexDirection={'column'}
              alignItems={'center'}
            >
              {/* <Box sx={{ color: 'white', borderRadius: '10px', overflow: 'auto', display: 'flex', p: 1, background: tipoSeleccionado === TipoPregunta.CARGA_VIDEO ? "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)" : '#cacaca' }}>
                <VideoLibraryRounded />
              </Box> */}
              <Box sx={{ borderRadius: '10px', overflow: 'auto', display: 'flex' }}>
                <img src={tipoSeleccionado === TipoPregunta.CARGA_VIDEO ? bgCargaVideoSelectedIcon : bgCargaVideoIcon} width={'100%'} height={'100%'} alt="video-icon" />
              </Box>
              <Typography sx={{ color: tipoSeleccionado === TipoPregunta.CARGA_VIDEO ? theme.palette.info.main : 'black', textTransform: 'initial' }}>
                Carga de video
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
