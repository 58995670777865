import produce from "immer";
import { Box, Grid, Radio } from "@mui/material";
import React from "react";
import CInputForm from "../../../../../shared/components/form/CInputForm";
import { PreguntaSeleccionUnica } from "../../../types";

type UniqueSelectionEditorProps = {
  pregunta: PreguntaSeleccionUnica;
  onChangePregunta: (pregunta: PreguntaSeleccionUnica) => void;
};

export function UniqueSelectionEditor({
  pregunta,
  onChangePregunta,
}: UniqueSelectionEditorProps) {
  const { opcion1, opcion2 } = pregunta;

  const handleRadioChange =
    (prop: "opcion1" | "opcion2") =>
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChangePregunta(
        produce(pregunta, (draft: any) => {
          if (checked === true && prop === "opcion1") {
            draft.opcion1.esCorrecta = true;
            draft.opcion2.esCorrecta = false;
          }

          if (checked === true && prop === "opcion2") {
            draft.opcion1.esCorrecta = false;
            draft.opcion2.esCorrecta = true;
          }
        })
      );
    };

  const handleTextChange =
    (prop: "opcion1" | "opcion2") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangePregunta(
        produce(pregunta, (draft: any) => {
          draft[prop].texto = event.target.value;
        })
      );
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={11}>
        <Box display="flex" flexDirection="column">
          {/* Pregunta unica 1 */}
          <Box display="inline-flex">
            <Radio
              checked={opcion1.esCorrecta}
              value={"opcion1"}
              name={opcion1.texto}
              onChange={handleRadioChange("opcion1")}
              sx={{
                marginBottom: 2,
              }}
            />
            <CInputForm
              name="opcion1"
              placeholder="Introduzca la respuesta"
              value={opcion1.texto}
              onChange={handleTextChange("opcion1")}
            />
          </Box>
          {/* Pregunta unica 2 */}
          <Box display="inline-flex">
            <Radio
              checked={opcion2.esCorrecta}
              value={"opcion2"}
              name={opcion2.texto}
              onChange={handleRadioChange("opcion2")}
              sx={{
                marginBottom: 2,
              }}
            />
            <CInputForm
              name="opcion2"
              placeholder="Introduzca la respuesta"
              value={opcion2.texto}
              onChange={handleTextChange("opcion2")}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={1}></Grid>
    </Grid>
  );
}
