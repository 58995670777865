import { Box, Chip, Typography, styled } from "@mui/material";
import theme from "../../../../../themes";
import { NewsCardProps } from "../../../../../types/NewsCard";
import useAppContext from "../../../../../shared/hooks/useAppContext";
import moment from "moment";

const StyledBox = styled(Box)(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: '100%',
  maxHeight: '200px',
  height: '200px',
  // TODO: mobile breakpoints
  // [theme.breakpoints.up('md')]: {
  //   maxHeight: '300px',
  //   height: '300px'
  // },
  [theme.breakpoints.up('lg')]: {
    maxHeight: '300px',
    height: '300px',
  }
}));

const StyledImg = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
  [theme.breakpoints.up('md')]: {
    height: '100%'
  },
  [theme.breakpoints.up('lg')]: {
    height: '100%'
  }
}));

const LinkCard = ({ image, content, date, clipLabel, title, displayAsColumn, cardWidth, cardHeight }: any) => {

  const {
    interestLinkContent
  } = useAppContext();

  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: '65%', padding: '1.5em 1em 1.5em 1.5em' }}
      >
        <Box display="flex" flexWrap={'wrap'}>
          <Chip label={"ENLACE DE INTERÉS"} sx={{ backgroundColor: '#818181', color: '#FFFFFF', fontSize: { xs: 9, md: 13 }, marginRight: '0.5em' }} />
          {/* <Chip label={interestLinkContent.mediaStr} sx={{ backgroundColor: theme.palette.primary.main, color: '#FFFFFF', marginRight: '1.5em', fontSize: { xs: 9, md: 13 } }} /> */}
          <Chip label={interestLinkContent&&interestLinkContent.tag} sx={{ backgroundColor: theme.palette.primary.main, color: '#FFFFFF', fontSize: { xs: 9, md: 13 }, marginRight: '1.5em' }} />
          <Typography sx={{ color: theme.palette.neutral.active }}>
            { interestLinkContent && interestLinkContent.creadoFecha ? moment(interestLinkContent.creadoFecha).format("DD-MM-YYYY HH:mm") : ''}
          </Typography>
        </Box>
        <Box my={'0.5em'}>
          <Typography sx={{
            fontFamily: 'Barlow',
            fontSize: {xs:38, md:42},
            lineHeight: 1,
            color: '#54595F',
            fontWeight: 'bold',
            [theme.breakpoints.down('md')]: {
              fontSize: 22
            },
          }}>
            {interestLinkContent && interestLinkContent.titulo ? interestLinkContent.titulo : ''}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" sx={{ width: '35%' }}>
        <Box display="inline-block" height={'100%'} width={'100%'} style={{ padding: '1.5em 0 0 0' }}>

          <StyledImg src={interestLinkContent && interestLinkContent.urlImagen} alt="img" />

        </Box>
      </Box>
    </StyledBox>
  )
};

export default LinkCard;