export const departamentosF = [
  "Beni",
  "Chuquisaca",
  "Cochabamba",
  "La Paz",
  "Oruro",
  "Pando",
  "Potosi",
  "Santa Cruz",
  "Tarija",
];

export const TIPOS_DIRECTORIOS = {
  SLIM: "SLIM",
  ALCADIA: "ALCALDIA",
  DESARROLLO_HUMANO_SOCIAL: "DESARROLLO_HUMANO_SOCIAL",
  POLICIA: "POLICIA",
  SALUD: "SALUD",
  FORENSE: "FORENSE",
  JUZGADO: "JUZGADO",
  FISCALIA: "FISCALIA",
};

export const _ordenDirectorios = {
  [TIPOS_DIRECTORIOS.ALCADIA]: 1,
  [TIPOS_DIRECTORIOS.SLIM]: 2,
  [TIPOS_DIRECTORIOS.DESARROLLO_HUMANO_SOCIAL]: 3,
  [TIPOS_DIRECTORIOS.POLICIA]: 4,
  [TIPOS_DIRECTORIOS.SALUD]: 5,
  [TIPOS_DIRECTORIOS.FORENSE]: 6,
  [TIPOS_DIRECTORIOS.JUZGADO]: 7,
  [TIPOS_DIRECTORIOS.FISCALIA]: 8,
};

export const directoriosF = [
  {
    id: "1",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Charcas",
    municipio_cod: "1129",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Av. Mariscal de Sucre, Mercado Central segundo piso"],
    telefono: "78690542",
    celular: "64324199",
  },
  {
    id: "2",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Serrano",
    municipio_cod: "1122",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Calle 6 de agosto esq. Potosí frente a la plaza principal edificio del Gobierno Municipal",
    ],
    telefono: "68616140",
    celular: null,
  },
  {
    id: "3",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Padilla",
    municipio_cod: "1110",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Ayacucho # 26"],
    telefono: "76301567",
    celular: null,
  },
  {
    id: "4",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "El Villar",
    municipio_cod: "1114",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle 14 de septiembre S/N"],
    telefono: "68660240",
    celular: null,
  },
  {
    id: "5",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Culpina",
    municipio_cod: "1124",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Barrio San Silvestre, calle Juana Azurduy de Padilla, frente a la Sede Sindical de Trabjadores Campesinos de Culpina ",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "6",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Machareti",
    municipio_cod: "1128",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Calle 25 de Mayo instalaciones del Gobierno Autónomo Municipal de Macharetí",
    ],
    telefono: "67791120",
    celular: null,
  },
  {
    id: "7",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Vaca Guzmán",
    municipio_cod: "1126",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Marzana - Palacio de Justicia"],
    telefono: null,
    celular: null,
  },
  {
    id: "8",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "San Benito",
    municipio_cod: "1317",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Km. 40 de la carretera antigua a Santa Cruz - Mercado central de San Benito ",
    ],
    telefono: "71743509",
    celular: null,
  },
  {
    id: "9",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Sacaba",
    municipio_cod: "1312",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Distrito 1-Oficina 1-Centro integral contra la violencia: final Granado Norte Zona Laicacota.",
      "Distrito 1: Oficina 2 - Edificio SLIM: Calle Ismael Cespedes casi Circunvalación 3.  Distrito 2: Sub alcaldía D2 Primer piso, Zona Quintanilla lado Mercado Quintanilla.  Distrito 3: Sub alcaldía de D 3 Planta baja Zona Pacata.  Distrito 4: Sub alcaldía del D4 Av. Chapare Zona Guadalupe 6.  Distrito 6: Sub alcaldía de El Abra 7.   Distrito 7: Sub alcaldía de Villa Obrajes.  Distrito Rural de Lava Lava: Sub Alcaldia de Lava Lava, lado Mercado de Canal Pata. Distrito Rural de Ucuchi: Modulo Policial de K'asapata",
    ],
    telefono: "4-4701329",
    celular: null,
  },
  {
    id: "10",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Omereque",
    municipio_cod: "1309",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Bolívar S/N"],
    telefono: null,
    celular: null,
  },
  {
    id: "11",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Shinahota",
    municipio_cod: "1347",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Plaza Principal 4 de julio, acera oeste"],
    telefono: "71727440",
    celular: null,
  },
  {
    id: "12",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Chimoré",
    municipio_cod: "1334",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Las Palmas frente a la Plaza Europa"],
    telefono: "4-4136218",
    celular: null,
  },
  {
    id: "13",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Guanay",
    municipio_cod: "1227",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Planta baja de la Alcaldía, frente a la Plaza Gualberto Villarroel",
    ],
    telefono: "68212028",
    celular: null,
  },
  {
    id: "14",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Yanacachi",
    municipio_cod: "1258",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Plaza Libertad camino a Chaguara"],
    telefono: "73303824",
    celular: null,
  },
  {
    id: "15",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Huatajata",
    municipio_cod: "1286",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Carretera a Copacabana Km. 80.-Palacio Consistorial del Gobierno Autónomo Municipal de Huatajata planta baja.",
    ],
    telefono: "76541973",
    celular: null,
  },
  {
    id: "16",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chua Cocani",
    municipio_cod: "1287",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Plaza Principal Jaime Paz Zamora s/n - Palacio Consistorial del Gobierno Autónomo de Chua Cocani",
    ],
    telefono: "79101488",
    celular: null,
  },
  {
    id: "17",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Patacamaya",
    municipio_cod: "1215",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Zona Central, Coliseo Municipal Evo Morales Ayma"],
    telefono: "74511593",
    celular: null,
  },
  {
    id: "18",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chulumani",
    municipio_cod: "1256",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Sagarnaga primer piso, Edif. Casa de justicia"],
    telefono: "77223684",
    celular: null,
  },
  {
    id: "19",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Sorata",
    municipio_cod: "1226",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Plaza Principal Enrique Peñaranda del Castillo. Edif. Sub gobernación primer piso ",
    ],
    telefono: "71568764",
    celular: null,
  },
  {
    id: "20",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "La Asunta",
    municipio_cod: "1260",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Plaza Principal 20 de Septiembre entre calles Hipólito Pari y Gregorio Vasquez. Edif. Casa de Justicia primer piso ",
    ],
    telefono: "71555463",
    celular: null,
  },
  {
    id: "21",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Santiago de Callapa",
    municipio_cod: "1245",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Oficina 1:Plaza 25 Julio s/n Santiago de Callapa",
      "Oficina 2: Avenida 6 de marzo Nro. 59 Zona Villa Bolívar ciudad de El Alto",
    ],
    telefono: "71901252",
    celular: null,
  },
  {
    id: "22",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "El Alto",
    municipio_cod: "1205",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Av. 6 de Marzo, Zona Santiago Segundo"],
    telefono: null,
    celular: null,
  },
  {
    id: "23",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Pazña",
    municipio_cod: "1409",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Plaza Principal 6 de Enero, instalaciones del Gobierno Autonómo Municipal",
    ],
    telefono: "74135597",
    celular: null,
  },
  {
    id: "24",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Cruz de Machacamarca",
    municipio_cod: "1420",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Plaza Principal 29 de Mayo S/N"],
    telefono: "67201469",
    celular: null,
  },
  {
    id: "25",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Machacamarca",
    municipio_cod: "1407",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Ladislao Cabrera Esq. Calle Bolívar"],
    telefono: "68354410",
    celular: null,
  },
  {
    id: "26",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Porco",
    municipio_cod: "1532",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Av. El Estudiante . Frente al colegio José María Linares y Dirección Distrital, planta baja ",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "27",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Pocoata",
    municipio_cod: "1513",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Alameda - Módulo policial"],
    telefono: null,
    celular: null,
  },
  {
    id: "28",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Atocha",
    municipio_cod: "1520",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Av. Bush Nº 120"],
    telefono: "68421645",
    celular: null,
  },
  {
    id: "29",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Ramón",
    municipio_cod: "1750",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Barrio el Progreso al lado del Concejo Municipal"],
    telefono: "76332121",
    celular: null,
  },
  {
    id: "30",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "General Saavedra",
    municipio_cod: "1735",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Av. . Santa Cruz, frente al Colegio Hilarión Tomelicht"],
    telefono: "3-39249037",
    celular: null,
  },
  {
    id: "31",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Cuatro Cañadas",
    municipio_cod: "1755",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Barrio Jireth, a 3 cuadras de la Plaza Principal"],
    telefono: "71091489",
    celular: null,
  },
  {
    id: "32",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Camiri",
    municipio_cod: "1723",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Barrio San José"],
    telefono: null,
    celular: null,
  },
  {
    id: "33",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Miguel de Velasco",
    municipio_cod: "1708",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Sucre - Casco viejo"],
    telefono: "77129705",
    celular: null,
  },
  {
    id: "34",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San José de Chiquitos",
    municipio_cod: "1713",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Zona Casco viejo, Calle Ovidio Barbery y Germán Busch"],
    telefono: "68859288",
    celular: null,
  },
  {
    id: "35",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Pailón",
    municipio_cod: "1714",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Barrio las Misiones a una cuadra de la Circulación"],
    telefono: null,
    celular: null,
  },
  {
    id: "36",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Buena Vista",
    municipio_cod: "1515",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Celso Sandoval"],
    telefono: "68909703",
    celular: null,
  },
  {
    id: "37",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Villamontes",
    municipio_cod: "1606",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Barrio Pilcomayo entre Calle La Paz y Avaroa. Edf. Cerpi"],
    telefono: "68596606",
    celular: null,
  },
  {
    id: "38",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Coroico",
    municipio_cod: "1265",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Av.Sagarnaga Edif. Municipal Primer Piso"],
    telefono: "74020087",
    celular: null,
  },
  {
    id: "39",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "El Torno",
    municipio_cod: "1705",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Barrio Naciones Unidas, en Instalaciones del Coliseo Municipal. Oficina 18",
    ],
    telefono: "71624274",
    celular: null,
  },
  {
    id: "40",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "San Lorenzo",
    municipio_cod: "1909",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Barrio La Banda - Calle Florida"],
    telefono: "72989478",
    celular: null,
  },
  {
    id: "41",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Tomina",
    municipio_cod: "1111",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Comando policial de Tomina Av. Angelica Tufiño"],
    telefono: "67601057",
    celular: null,
  },
  {
    id: "42",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Alcalá",
    municipio_cod: "1113",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Plaza 25 de Mayo S/N"],
    telefono: "69670625",
    celular: null,
  },
  {
    id: "43",
    departamento: "Beni",
    departamento_cod: "8",
    municipio: "Puerto de Rurrenabaque",
    municipio_cod: "1807",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Abaroa frente a la Plaza 2 de Febrero"],
    telefono: "67121972",
    celular: "74029137",
  },
  {
    id: "44",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "San Pablo de Huacareta",
    municipio_cod: "1116",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle 9 de abril, mercado central segundo piso"],
    telefono: "74420588",
    celular: "67397687",
  },
  {
    id: "45",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Tiquipaya",
    municipio_cod: "1304",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Aguas potables y Khora frente al vivero municipal"],
    telefono: "4-4316512",
    celular: "65768267 - 68580315",
  },
  {
    id: "46",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Caranavi",
    municipio_cod: "1210",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Calle Batallón de Ingenieros s/n, lado EPI (Estación policial integral, policía rural fronteriza, FELCV) Caranavi",
    ],
    telefono: "63235728 - 60760715",
    celular: "65607930 - 73730701",
  },
  {
    id: "47",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Challapata",
    municipio_cod: "1404",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Calle Heroes del Chaco y Rengel - Casa de Justicia"],
    telefono: "74104746",
    celular: "71108598",
  },
  {
    id: "48",
    departamento: "Pando",
    departamento_cod: "9",
    municipio: "Cobija",
    municipio_cod: "1901",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Zona central Calle Elvira Gutierrez Nro. 53, Barrio Conavi"],
    telefono: "67661884",
    celular: "72283309",
  },
  {
    id: "49",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Potosi",
    municipio_cod: "1501",
    titulo: "SLIM",
    tipo: "SLIM",
    direccion: ["Calle La Paz esq. Betanzos Nro. 1158"],
    telefono: "2-6229164",
    celular: null,
  },
  {
    id: "50",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Padcaya",
    municipio_cod: "1602",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: [
      "Av. Bermejo S/N, instalaciones del Gobierno Autónomo Municipal",
    ],
    telefono: "63653903",
    celular: "72972901",
  },
  {
    id: "51",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Porongo",
    municipio_cod: "1703",
    titulo: "SLIM - DNA",
    tipo: "SLIM",
    direccion: ["Av. 24 de septiembre s/n Porongo"],
    telefono: "68939254",
    celular: null,
  },
  {
    id: "52",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Charcas",
    municipio_cod: "1129",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Oficina GAM frente a la Plaza Principal Julio Andia, segundo piso",
    ],
    telefono: "67381631",
    celular: null,
  },
  {
    id: "53",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Serrano",
    municipio_cod: "1122",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Calle 6 de agosto esq. Potosí frente a la plaza principal edificio del Gobierno Municipal",
    ],
    telefono: "68616140",
    celular: null,
  },
  {
    id: "54",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Padilla",
    municipio_cod: "1110",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza Manuel Asencio Padilla Nro. 30"],
    telefono: "73448804",
    celular: null,
  },
  {
    id: "55",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "El Villar",
    municipio_cod: "1114",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Calle 3 de Febrero"],
    telefono: "68660233",
    celular: null,
  },
  {
    id: "56",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Vaca Guzmán",
    municipio_cod: "1126",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza 6 de Agosto"],
    telefono: null,
    celular: null,
  },
  {
    id: "57",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "San Benito",
    municipio_cod: "1317",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza Benito Moxos acera noreste"],
    telefono: "4-4579146",
    celular: null,
  },
  {
    id: "58",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Sacaba",
    municipio_cod: "1312",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "1.Secretaria de Desarrollo Humano Integral Pasaje Consistorial S-002- Tercer piso",
    ],
    telefono: "4702301 int 2008- SECRETARIA",
    celular: null,
  },
  {
    id: "59",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Omereque",
    municipio_cod: "1309",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza 7 de Octubre, Acera Norte"],
    telefono: "4-4136519",
    celular: null,
  },
  {
    id: "60",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Shinahota",
    municipio_cod: "1347",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza Principal 4 de julio, acera oeste"],
    telefono: "67510085",
    celular: null,
  },
  {
    id: "61",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Chimoré",
    municipio_cod: "1334",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Calle Las Palmas frente a la Plaza Europa"],
    telefono: "71725233",
    celular: null,
  },
  {
    id: "62",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Guanay",
    municipio_cod: "1227",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Frente a la Plaza Gualberto Villarroel"],
    telefono: "68212028",
    celular: null,
  },
  {
    id: "63",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Yanacachi",
    municipio_cod: "1258",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza Libertad camino a Chaguara"],
    telefono: "73303824",
    celular: null,
  },
  {
    id: "64",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Huatajata",
    municipio_cod: "1286",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Carretera a Copacabana Km. 80.-Palacio Consistorial del Gobierno Autónomo Municipal de Huatajata planta baja.",
    ],
    telefono: "71570841",
    celular: null,
  },
  {
    id: "65",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chua Cocani",
    municipio_cod: "1287",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Plaza Principal Jaime Paz Zamora s/n - Palacio Consistorial del Gobierno Autónomo de Chua Cocani",
    ],
    telefono: "64183928",
    celular: null,
  },
  {
    id: "66",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Patacamaya",
    municipio_cod: "1215",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Zona Asunción, Av. 6 de Agosto"],
    telefono: "67106272",
    celular: null,
  },
  {
    id: "67",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chulumani",
    municipio_cod: "1256",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Calle General Pando"],
    telefono: "77223684",
    celular: null,
  },
  {
    id: "68",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Sorata",
    municipio_cod: "1226",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza Principal Enrique Peñaranda del Castillo."],
    telefono: "78937693",
    celular: null,
  },
  {
    id: "69",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "La Asunta",
    municipio_cod: "1260",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Plaza 20 de Septiembre.  Oficina de enlace ubicada en la Ciudad de La Paz, centro paceño, Edif. Presencia, piso 10 a lado de Cotel",
    ],
    telefono: "71596688",
    celular: "2-2129835",
  },
  {
    id: "70",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Santiago de Callapa",
    municipio_cod: "1245",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza 25 Julio s/n Santiago de Callapa"],
    telefono: "71265504",
    celular: null,
  },
  {
    id: "71",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "El Alto",
    municipio_cod: "1205",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Av. Costanera Nro.5022 Urb. Libertad, Jacha Uta"],
    telefono: null,
    celular: null,
  },
  {
    id: "72",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Pazña",
    municipio_cod: "1409",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza Principal 6 de Enero"],
    telefono: null,
    celular: null,
  },
  {
    id: "73",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Cruz de Machacamarca",
    municipio_cod: "1420",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza Principal 29 de Mayo S/N"],
    telefono: "67242407",
    celular: null,
  },
  {
    id: "74",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Porco",
    municipio_cod: "1532",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza Principal  La Libertad"],
    telefono: null,
    celular: null,
  },
  {
    id: "75",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Ramón",
    municipio_cod: "1750",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Av. Santa Cruz- Barrio 13 de Mayo"],
    telefono: null,
    celular: null,
  },
  {
    id: "76",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "General Saavedra",
    municipio_cod: "1735",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Frente a la Plaza Principal Pedro Nuñez"],
    telefono: "67832098",
    celular: null,
  },
  {
    id: "77",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Cuatro Cañadas",
    municipio_cod: "1755",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Barrio San Antonio-Gobierno Autonómo Municipal"],
    telefono: "64440061",
    celular: null,
  },
  {
    id: "78",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Camiri",
    municipio_cod: "1723",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Ex portería de YPF "],
    telefono: null,
    celular: null,
  },
  {
    id: "79",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Miguel de Velasco",
    municipio_cod: "1708",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Calle Betania"],
    telefono: "3-9624500",
    celular: null,
  },
  {
    id: "80",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San José de Chiquitos",
    municipio_cod: "1713",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Calle Velasco, Esq. Linares frente a la Plaza Principal Felipe Suarez, segundo piso",
    ],
    telefono: "78102175",
    celular: null,
  },
  {
    id: "81",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Pailón",
    municipio_cod: "1714",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Barrio 24 de Septiembre a un lado de la Plaza Principal 13 de Mayo",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "82",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Buena Vista",
    municipio_cod: "1515",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Calle Celso Sandoval, esq. Juan Moreno Raldes"],
    telefono: "3-9322032",
    celular: null,
  },
  {
    id: "83",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Villamontes",
    municipio_cod: "1606",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Barrio Pilcomayo entre Calle La Paz y Avaroa. Edf. Cerpi"],
    telefono: null,
    celular: null,
  },
  {
    id: "84",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Coroico",
    municipio_cod: "1265",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Centro de Salud 1, OTBE Nro. 4."],
    telefono: "73231536",
    celular: null,
  },
  {
    id: "85",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "El Torno",
    municipio_cod: "1705",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Edificio Municipal, Calle 25 de Julio - Plaza Principal 25 de Julio",
    ],
    telefono: "3-3822132",
    celular: null,
  },
  {
    id: "86",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "San Lorenzo",
    municipio_cod: "1909",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Barrio Central, Calle Rodolfo Avila frente a la Plaza Principal Eustaquio Méndez",
    ],
    telefono: "4-6661019",
    celular: null,
  },
  {
    id: "87",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Tomina",
    municipio_cod: "1111",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Av. San Pablo s/n esq. Avaroa"],
    telefono: "74406743",
    celular: null,
  },
  {
    id: "88",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Alcalá",
    municipio_cod: "1113",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza 25 de Mayo S/N"],
    telefono: null,
    celular: null,
  },
  {
    id: "89",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "San Pablo de Huacareta",
    municipio_cod: "1116",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Calle 9 de abril, mercado central segundo piso"],
    telefono: "67603435",
    celular: null,
  },
  {
    id: "90",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Tiquipaya",
    municipio_cod: "1304",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Calle Aguas potables y Khora frente al vivero municipal"],
    telefono: "4-4316512",
    celular: null,
  },
  {
    id: "91",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Caranavi",
    municipio_cod: "1210",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Plaza Principal Libertador Simón Bolívar, Primer piso de Edf. Del  Gobiero Autonómo Municipal de  Caranavi",
    ],
    telefono: "74005312",
    celular: null,
  },
  {
    id: "92",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Challapata",
    municipio_cod: "1404",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Plaza Eduardo Avaroa, Calle Bolívar y Ladislao Cabrera"],
    telefono: "72492375",
    celular: null,
  },
  {
    id: "93",
    departamento: "Pando",
    departamento_cod: "9",
    municipio: "Cobija",
    municipio_cod: "1901",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Barrio Santa Clara"],
    telefono: "67668666",
    celular: null,
  },
  {
    id: "94",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Potosi",
    municipio_cod: "1501",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: ["Calle La Paz esq. Betanzos Nro. 1158"],
    telefono: "2-6229164",
    celular: null,
  },
  {
    id: "95",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Padcaya",
    municipio_cod: "1602",
    titulo: "Desarrollo humano/social en el GAM",
    tipo: "DESARROLLO_HUMANO_SOCIAL",
    direccion: [
      "Av. Bermejo S/N, instalaciones del Gobierno Autónomo Municipal",
    ],
    telefono: "68702567",
    celular: null,
  },
  {
    id: "96",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Charcas",
    municipio_cod: "1129",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Camargo frente al Centro de Salud"],
    telefono: "75774562",
    celular: null,
  },
  {
    id: "97",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Serrano",
    municipio_cod: "1122",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Guabira s/n"],
    telefono: "69690302",
    celular: null,
  },
  {
    id: "98",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Padilla",
    municipio_cod: "1110",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Plaza Manuel Asencio Padilla Nro 30"],
    telefono: "61192883",
    celular: null,
  },
  {
    id: "99",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "El Villar",
    municipio_cod: "1114",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Bolivar s/n"],
    telefono: "73919917",
    celular: null,
  },
  {
    id: "100",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Culpina",
    municipio_cod: "1124",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Plaza 20 de Diciembre"],
    telefono: "67600457",
    celular: null,
  },
  {
    id: "101",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Machareti",
    municipio_cod: "1128",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Comercio s/n"],
    telefono: "67791120",
    celular: null,
  },
  {
    id: "102",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Vaca Guzmán",
    municipio_cod: "1126",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Plaza 6 de Agosto"],
    telefono: null,
    celular: null,
  },
  {
    id: "103",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "San Benito",
    municipio_cod: "1317",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Km. 40 de la carretera antigua a Santa Cruz"],
    telefono: null,
    celular: null,
  },
  {
    id: "104",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Sacaba",
    municipio_cod: "1312",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: [
      "Plaza  Principal de Sacaba esq. Pasaje Consistorial",
      "Comando Regional de Policia  ",
    ],
    telefono: "4-4011828 Radio Patrulla Sacaba VIGILANTE SACABA",
    celular: null,
  },
  {
    id: "105",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Omereque",
    municipio_cod: "1309",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Bolívar S/N"],
    telefono: "71721866",
    celular: null,
  },
  {
    id: "106",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Shinahota",
    municipio_cod: "1347",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Plaza Principal 4 de julio, acera oeste"],
    telefono: "74943617",
    celular: null,
  },
  {
    id: "107",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Chimoré",
    municipio_cod: "1334",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Las Palmas frente a la Plaza Europa"],
    telefono: "71729193",
    celular: null,
  },
  {
    id: "108",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Guanay",
    municipio_cod: "1227",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Esq. Plaza Principal Gualberto Villarroel"],
    telefono: "69987720",
    celular: null,
  },
  {
    id: "109",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Yanacachi",
    municipio_cod: "1258",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Plaza Libertad camino a Chaguara"],
    telefono: null,
    celular: null,
  },
  {
    id: "110",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Huatajata",
    municipio_cod: "1286",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: [
      "Carretera a Copacabana Km. 80.-Palacio Consistorial del Gobierno Autónomo Municipal de Huatajata planta baja.",
    ],
    telefono: "77202177",
    celular: null,
  },
  {
    id: "111",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chua Cocani",
    municipio_cod: "1287",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Plaza Principal Jaime Paz Zamora s/n"],
    telefono: "110",
    celular: null,
  },
  {
    id: "112",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Patacamaya",
    municipio_cod: "1215",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Av. Panamericana"],
    telefono: null,
    celular: null,
  },
  {
    id: "113",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chulumani",
    municipio_cod: "1256",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle General Pando"],
    telefono: "71203777",
    celular: null,
  },
  {
    id: "114",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Sorata",
    municipio_cod: "1226",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Plaza Principal Enrique Peñaranda del Castillo"],
    telefono: "76703023",
    celular: null,
  },
  {
    id: "115",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "La Asunta",
    municipio_cod: "1260",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: [
      "Frente de la plaza 20 de Septiembre, Edif. Casa de Justicia planta baja",
    ],
    telefono: "68216328",
    celular: null,
  },
  {
    id: "116",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Santiago de Callapa",
    municipio_cod: "1245",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Av. principal ingreso a Santiago de Callapa s/n"],
    telefono: "73013345",
    celular: null,
  },
  {
    id: "117",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "El Alto",
    municipio_cod: "1205",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Zona Ciudad Satelite, CRP Franz Tamayo"],
    telefono: null,
    celular: null,
  },
  {
    id: "118",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Cruz de Machacamarca",
    municipio_cod: "1420",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: [
      "Comunidad de Huayllas a una cuadra de la Plaza Principal 29 de Mayo",
    ],
    telefono: "67202275",
    celular: null,
  },
  {
    id: "119",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Machacamarca",
    municipio_cod: "1407",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Ladislao Cabrera Esq. Calle Bolívar"],
    telefono: "68318504",
    celular: null,
  },
  {
    id: "120",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Porco",
    municipio_cod: "1532",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: [
      "Calle s/n, frente  al Centro de Atención de Salud Cooperativa Porco",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "121",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Pocoata",
    municipio_cod: "1513",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Alameda - Módulo policial"],
    telefono: null,
    celular: null,
  },
  {
    id: "122",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Atocha",
    municipio_cod: "1520",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Final Av. Chorolque s/n"],
    telefono: "71859087",
    celular: null,
  },
  {
    id: "123",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Ramón",
    municipio_cod: "1750",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Av. Santa Cruz- Barrio 13 de Mayo"],
    telefono: null,
    celular: null,
  },
  {
    id: "124",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "General Saavedra",
    municipio_cod: "1735",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Frente a la Plaza Principal Pedro Nuñez"],
    telefono: "75645898",
    celular: "67818074",
  },
  {
    id: "125",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Cuatro Cañadas",
    municipio_cod: "1755",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Barrio San Antonio - Gobierno Autonómo Municipal"],
    telefono: "71346116",
    celular: null,
  },
  {
    id: "126",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Camiri",
    municipio_cod: "1723",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Cochabamba"],
    telefono: "110",
    celular: null,
  },
  {
    id: "127",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Miguel de Velasco",
    municipio_cod: "1708",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Sucre - Casco viejo"],
    telefono: "7906477",
    celular: null,
  },
  {
    id: "128",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San José de Chiquitos",
    municipio_cod: "1713",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Carretera a Roboré"],
    telefono: "110",
    celular: null,
  },
  {
    id: "129",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Pailón",
    municipio_cod: "1714",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: [
      "Barrio 24 de Septiembre a un lado de la Plaza Principal 13 de Mayo",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "130",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Buena Vista",
    municipio_cod: "1515",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle José Steimbash  Frente a la  Plaza Principal"],
    telefono: "72160487",
    celular: null,
  },
  {
    id: "131",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Villamontes",
    municipio_cod: "1606",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Av. Ayacucho entre Calle Sbtte Barrau y Av. Mendez Arcos"],
    telefono: "64241515",
    celular: null,
  },
  {
    id: "132",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Coroico",
    municipio_cod: "1265",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Av. Sagarnaga  Plaza Principal Manuel Victorio García Lanza"],
    telefono: "77279252",
    celular: null,
  },
  {
    id: "133",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "El Torno",
    municipio_cod: "1705",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Santa Rita - Km.32"],
    telefono: "3-3822697",
    celular: null,
  },
  {
    id: "134",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "San Lorenzo",
    municipio_cod: "1909",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Barrio Oscar Alfaro- Calle Teofilo Vaca Dols"],
    telefono: "71865017",
    celular: null,
  },
  {
    id: "135",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Tomina",
    municipio_cod: "1111",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Comando policial de Tomina Av. Angelica Tufiño"],
    telefono: "67609803",
    celular: null,
  },
  {
    id: "136",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Alcalá",
    municipio_cod: "1113",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Plaza 25 de Mayo S/N"],
    telefono: null,
    celular: null,
  },
  {
    id: "137",
    departamento: "Beni",
    departamento_cod: "8",
    municipio: "Puerto de Rurrenabaque",
    municipio_cod: "1807",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Campero entre Abaroa y Bolívar"],
    telefono: "71559872",
    celular: null,
  },
  {
    id: "138",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "San Pablo de Huacareta",
    municipio_cod: "1116",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Bolivar s/n"],
    telefono: "774420934",
    celular: null,
  },
  {
    id: "139",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Tiquipaya",
    municipio_cod: "1304",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Khora sud, RÍo Khora"],
    telefono: null,
    celular: null,
  },
  {
    id: "141",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Caranavi",
    municipio_cod: "1210",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: [
      "Calle Batallón de Ingenieros s/n lado oficinas de SLIM - DNA y Edif. Subgobernación",
    ],
    telefono: "75255399",
    celular: null,
  },
  {
    id: "142",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Challapata",
    municipio_cod: "1404",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Plaza Eduardo Avaroa, Lado Sud, Calle Sucre y La Bandera"],
    telefono: "62787263",
    celular: null,
  },
  {
    id: "143",
    departamento: "Pando",
    departamento_cod: "9",
    municipio: "Cobija",
    municipio_cod: "1901",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Barrio Progreso"],
    telefono: "110",
    celular: null,
  },
  {
    id: "144",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Potosi",
    municipio_cod: "1501",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Av.Canada frente al Mercado Villa Santiago"],
    telefono: "72042570",
    celular: null,
  },
  {
    id: "145",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Padcaya",
    municipio_cod: "1602",
    titulo: "Oficina policial",
    tipo: "POLICIA",
    direccion: ["Calle Gilberto Saavedra s/n, frente a la Plaza Principal"],
    telefono: "72012657",
    celular: null,
  },
  {
    id: "146",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Charcas",
    municipio_cod: "1129",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Camargo frente a la oficina de la Policia"],
    telefono: null,
    celular: null,
  },
  {
    id: "147",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Serrano",
    municipio_cod: "1122",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle 6 de Agosto esq. Potosí"],
    telefono: "73473889",
    celular: null,
  },
  {
    id: "148",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Padilla",
    municipio_cod: "1110",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: [
      "Calle Final Colon Hospital Marcos Rojas Zurita / Calle Ayacucho # Hospital San Juan de Dios",
    ],
    telefono: "72870515",
    celular: null,
  },
  {
    id: "149",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "El Villar",
    municipio_cod: "1114",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Avenida del Maestro"],
    telefono: "68660235",
    celular: null,
  },
  {
    id: "150",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Culpina",
    municipio_cod: "1124",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Barrio Santa Rosa"],
    telefono: "67602730",
    celular: null,
  },
  {
    id: "151",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Machareti",
    municipio_cod: "1128",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Comercio s/n"],
    telefono: null,
    celular: null,
  },
  {
    id: "152",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Vaca Guzmán",
    municipio_cod: "1126",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Santa Cruz"],
    telefono: null,
    celular: null,
  },
  {
    id: "153",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "San Benito",
    municipio_cod: "1317",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Carrera antigua a Santa Cruz Km. 39"],
    telefono: "4-4579180",
    celular: null,
  },
  {
    id: "154",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Sacaba",
    municipio_cod: "1312",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: [
      "Hospital de Segundo nivel México Distrito 1 Av. Barrientos. ",
      " Hospital de Segundo Nivel Solomon Klein Av. Villazón D2 Zona Quintanilla",
    ],
    telefono:
      "4-293945 - 72222256 Centro municipal de Emergencia en salud MUNICIPAL - SACABA",
    celular: null,
  },
  {
    id: "155",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Omereque",
    municipio_cod: "1309",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Zona Cuchu Punata"],
    telefono: null,
    celular: null,
  },
  {
    id: "156",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Shinahota",
    municipio_cod: "1347",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: [
      "Calle San Martin de Porres, entre calles Progreso y 16 de Noviembre",
    ],
    telefono: "72234593",
    celular: null,
  },
  {
    id: "157",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Chimoré",
    municipio_cod: "1334",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Av. Los Pinos entre Abecedario"],
    telefono: "72223507",
    celular: null,
  },
  {
    id: "158",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Guanay",
    municipio_cod: "1227",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Frente a la cancha Carlos Smith "],
    telefono: "74269491",
    celular: null,
  },
  {
    id: "159",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Yanacachi",
    municipio_cod: "1258",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Yanacachi y Sacahuaya"],
    telefono: "69744452",
    celular: null,
  },
  {
    id: "160",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Huatajata",
    municipio_cod: "1286",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Carretera a Copacabana Km. 80"],
    telefono: "71216917",
    celular: null,
  },
  {
    id: "161",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chua Cocani",
    municipio_cod: "1287",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: [
      "Plaza Principal Jaime Paz Zamora s/n - Palacio Consistorial del Gobierno Autónomo de Chua Cocani",
    ],
    telefono: "67340358",
    celular: null,
  },
  {
    id: "162",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Patacamaya",
    municipio_cod: "1215",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Zona Central. Av. Panamericana"],
    telefono: null,
    celular: null,
  },
  {
    id: "163",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chulumani",
    municipio_cod: "1256",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Zona Alto Cuchumpaya"],
    telefono: "77224187",
    celular: null,
  },
  {
    id: "164",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Sorata",
    municipio_cod: "1226",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Zona Verde y Av. Villamil de Rada"],
    telefono: "71566608",
    celular: null,
  },
  {
    id: "165",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "La Asunta",
    municipio_cod: "1260",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Zona 4 entre Calle Santiago Fernandez y Calle Bolivia"],
    telefono: "71557609",
    celular: null,
  },
  {
    id: "166",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Santiago de Callapa",
    municipio_cod: "1245",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Av. Principal ingreso a Santiago de Callapa"],
    telefono: "67033688",
    celular: null,
  },
  {
    id: "167",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "El Alto",
    municipio_cod: "1205",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Carretera  a Viacha, Distrito 2, Hospital Korea"],
    telefono: null,
    celular: null,
  },
  {
    id: "168",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Pazña",
    municipio_cod: "1409",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Busch"],
    telefono: null,
    celular: null,
  },
  {
    id: "169",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Cruz de Machacamarca",
    municipio_cod: "1420",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["A una cuadra de la Plaza Principal 29 de Mayo"],
    telefono: "73813546",
    celular: null,
  },
  {
    id: "170",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Machacamarca",
    municipio_cod: "1407",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Joaquín Fortum"],
    telefono: "72454312",
    celular: null,
  },
  {
    id: "171",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Porco",
    municipio_cod: "1532",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: [
      "Av. El Estudiante . Frente al colegio José María Linares y Dirección Distrital, planta baja ",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "172",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Atocha",
    municipio_cod: "1520",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Final Av. Chorolque s/n"],
    telefono: "71813296",
    celular: null,
  },
  {
    id: "173",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Ramón",
    municipio_cod: "1750",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Barrio el Progreso sobre la Av. Circunvalación"],
    telefono: null,
    celular: null,
  },
  {
    id: "174",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "General Saavedra",
    municipio_cod: "1735",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Santiago Koruner"],
    telefono: "3-9249222",
    celular: null,
  },
  {
    id: "175",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Cuatro Cañadas",
    municipio_cod: "1755",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Barrio Centro Florida"],
    telefono: null,
    celular: null,
  },
  {
    id: "176",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Camiri",
    municipio_cod: "1723",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Av. Primero de Mayo"],
    telefono: "3-9522310",
    celular: null,
  },
  {
    id: "177",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Miguel de Velasco",
    municipio_cod: "1708",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: [
      "Barrio San Antonio calle Sucre diagonal de la farmacia San Antonio",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "178",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San José de Chiquitos",
    municipio_cod: "1713",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: [
      "HM Bernadino Gil Julio, B. Santa María y Centro de Salud 15 de Agosto, ubicado en el mismo Barrio",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "179",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Pailón",
    municipio_cod: "1714",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Barrio 24 de Septiembre"],
    telefono: null,
    celular: null,
  },
  {
    id: "180",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Buena Vista",
    municipio_cod: "1515",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Barrio Celso Sandoval y Av. Juan Sullivan"],
    telefono: "3-3751759",
    celular: null,
  },
  {
    id: "181",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Villamontes",
    municipio_cod: "1606",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Av. Sbtte. Vacaflor entre Calles la Paz y Potosí"],
    telefono: null,
    celular: null,
  },
  {
    id: "182",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Coroico",
    municipio_cod: "1265",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Av. Panacarani"],
    telefono: "78863944",
    celular: null,
  },
  {
    id: "183",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "El Torno",
    municipio_cod: "1705",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: [
      "Barrio Las Palmas, entrando 3 cuadras por el surtidor El Torno",
    ],
    telefono: "3-3822112",
    celular: null,
  },
  {
    id: "184",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "San Lorenzo",
    municipio_cod: "1909",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Barrio Oscar Alfaro - Calle sin nombre"],
    telefono: "4-6641213",
    celular: null,
  },
  {
    id: "185",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Tomina",
    municipio_cod: "1111",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Av. San Pablo"],
    telefono: "68880279",
    celular: null,
  },
  {
    id: "186",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Alcalá",
    municipio_cod: "1113",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Barrio Historico"],
    telefono: null,
    celular: null,
  },
  {
    id: "187",
    departamento: "Beni",
    departamento_cod: "8",
    municipio: "Puerto de Rurrenabaque",
    municipio_cod: "1807",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Ayacucho esq. Calle La Paz"],
    telefono: "3-3922025",
    celular: null,
  },
  {
    id: "188",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "San Pablo de Huacareta",
    municipio_cod: "1116",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Tarija s/n"],
    telefono: "72867407",
    celular: null,
  },
  {
    id: "189",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Tiquipaya",
    municipio_cod: "1304",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Gualberto Villarroel"],
    telefono: null,
    celular: null,
  },
  {
    id: "190",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Caranavi",
    municipio_cod: "1210",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Final Calle Batallón  de Ingenieros s/n"],
    telefono: "65611977",
    celular: null,
  },
  {
    id: "191",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Challapata",
    municipio_cod: "1404",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Calle Cochabamba entre Calles  Murillo Dorado y Ofreddy"],
    telefono: "74125135",
    celular: null,
  },
  {
    id: "192",
    departamento: "Pando",
    departamento_cod: "9",
    municipio: "Cobija",
    municipio_cod: "1901",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Barrio Tajibos "],
    telefono: null,
    celular: null,
  },
  {
    id: "193",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Potosi",
    municipio_cod: "1501",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: [
      "1. Centro de salud Pailaviri 1 ubicado en el campamento Pilaviri Pabellon N°87. | 2. Centro de Salud Pari Orko 2 ubicado en calle Integración zona Pari Orko. | 3. Centro de Salud Potosí 3 ubicado en calle Linares esquina Topater. | 4. Centro de Salud Sagrada Familia 4 ubicado Av. Tumusla. | 5. Centro de Salud Anselmo 5 ubicado en la Av. Huaynamayu. | 6. Centro de Salud San Benito 6 ubicado en calle Canchas Blancas Villa Santiago. | 7. Centro de Salud San Cristóbal 7 ubicado en la calle Sagarnaga esq. Cortes. | 8. Centro de Salud San Gerardo8 ubicado en la final Smith. | 9. Centro de Salud Pedro ubicado en la Calle Costanera esq. Torrez. | 10. Centro de Salud Villa Colon ubicado en la Calle Santa Cruz de la Cierra esq. Santiago de Chaqui. | 11. Centro de Salud Villa Mecánicos11 ubicado en la Av. Libertad. | 12. Centro de salud Villa Venezuela 12 ubicado calle Alianza esq. José David Berrios",
    ],
    telefono: "168",
    celular: null,
  },
  {
    id: "194",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Padcaya",
    municipio_cod: "1602",
    titulo: "Centro de Salud/Hospital/Posta",
    tipo: "SALUD",
    direccion: ["Av. Chaguaya frente al Stadio Municipal"],
    telefono: "68742769",
    celular: null,
  },
  {
    id: "195",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Sacaba",
    municipio_cod: "1312",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: [
      "Final Granado Norte Zona Laicacota ",
      " Centro Integral Contra la Violencia",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "196",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chulumani",
    municipio_cod: "1256",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: ["Calle Sagarnaga - Edf. Casa de Justicia planta baja"],
    telefono: "64154752",
    celular: null,
  },
  {
    id: "197",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "La Asunta",
    municipio_cod: "1260",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: [
      "Plaza principal 20 de Septiembre, Edif.  Casa de Justicia, primer piso",
    ],
    telefono: "71557609",
    celular: null,
  },
  {
    id: "198",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "El Alto",
    municipio_cod: "1205",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: ["Zona 16 de julio, Av. Castillo"],
    telefono: null,
    celular: null,
  },
  {
    id: "199",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Villamontes",
    municipio_cod: "1606",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: [
      "Av. Ingavi y la Ruta 11 (frente del monumento del Sombrerito)",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "200",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Coroico",
    municipio_cod: "1265",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: ["Plaza Principal Manuel Victorio García Lanza"],
    telefono: null,
    celular: null,
  },
  {
    id: "201",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Caranavi",
    municipio_cod: "1210",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: [
      "Calle Batallón de Ingenieros s/n, Edif. Subgobernación Planta baja",
    ],
    telefono: "75426146",
    celular: null,
  },
  {
    id: "202",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Challapata",
    municipio_cod: "1404",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: ["Calle Héroes del Chaco y Rengel - Casa de Justicia"],
    telefono: "71880779",
    celular: null,
  },
  {
    id: "203",
    departamento: "Pando",
    departamento_cod: "9",
    municipio: "Cobija",
    municipio_cod: "1901",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: ["Barrio Miraflores Av. 9 de Febrero Nro. 211- Mercado Central"],
    telefono: "67668164",
    celular: null,
  },
  {
    id: "204",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Potosi",
    municipio_cod: "1501",
    titulo: "Médico Forense",
    tipo: "FORENSE",
    direccion: ["Av. Villazón y Av. Sevilla"],
    telefono: "2-6223878",
    celular: null,
  },
  {
    id: "205",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Serrano",
    municipio_cod: "1122",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle 6 de agosto esq. Potosí frente a la plaza principal"],
    telefono: "72885466",
    celular: null,
  },
  {
    id: "206",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Padilla",
    municipio_cod: "1110",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Bolivar"],
    telefono: null,
    celular: null,
  },
  {
    id: "207",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Culpina",
    municipio_cod: "1124",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Barrio Santa Rosa"],
    telefono: "72890294",
    celular: null,
  },
  {
    id: "208",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Machareti",
    municipio_cod: "1128",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Av. Virgen del Carmen"],
    telefono: null,
    celular: null,
  },
  {
    id: "209",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Vaca Guzmán",
    municipio_cod: "1126",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Marzana - Palacio de Justicia"],
    telefono: null,
    celular: null,
  },
  {
    id: "210",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Sacaba",
    municipio_cod: "1312",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: [
      "Final Granado Norte Zona Laicacota.",
      "Juzgado de Instrucción de Violencia hacia la mujer N°1 de Sacaba.",
      "Centro Integral Contra la Violencia de Sacaba.",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "211",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Shinahota",
    municipio_cod: "1347",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Plaza Principal 4 de Julio Acera Oeste"],
    telefono: "63848780",
    celular: null,
  },
  {
    id: "212",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Chimoré",
    municipio_cod: "1334",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Mapajos entre Av. Panamericana"],
    telefono: "67780662",
    celular: null,
  },
  {
    id: "213",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Guanay",
    municipio_cod: "1227",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Cerca a la Plaza principal Gualberto Villarroel"],
    telefono: "68212028",
    celular: null,
  },
  {
    id: "214",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Patacamaya",
    municipio_cod: "1215",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Zona central, Av. Panamericana"],
    telefono: null,
    celular: null,
  },
  {
    id: "215",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chulumani",
    municipio_cod: "1256",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Sagarnaga - Edif. Casa de Justicia planta baja"],
    telefono: "71959402",
    celular: null,
  },
  {
    id: "216",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Sorata",
    municipio_cod: "1226",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Plaza Principal Enrique Peñaranda del Castillo"],
    telefono: "67343590",
    celular: null,
  },
  {
    id: "217",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "La Asunta",
    municipio_cod: "1260",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: [
      "Plaza Principal 20 de Septiembre, Edif. Casa de Justicia, 2do. piso",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "218",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "El Alto",
    municipio_cod: "1205",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Cruce Viacha, Av. Satelite"],
    telefono: null,
    celular: null,
  },
  {
    id: "219",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Porco",
    municipio_cod: "1532",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Av. Api Porco Edif. Nuevo"],
    telefono: null,
    celular: null,
  },
  {
    id: "220",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Atocha",
    municipio_cod: "1520",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Plaza Franz Tamayo s/n"],
    telefono: "71831300",
    celular: null,
  },
  {
    id: "221",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Cuatro Cañadas",
    municipio_cod: "1755",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Barrio Jireth, a 3 cuadras de la Plaza Principal"],
    telefono: null,
    celular: null,
  },
  {
    id: "222",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Camiri",
    municipio_cod: "1723",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Capitán Pabón"],
    telefono: null,
    celular: null,
  },
  {
    id: "223",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San José de Chiquitos",
    municipio_cod: "1713",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Humberto Paredes s/n, frente del Mercado Modelo"],
    telefono: null,
    celular: null,
  },
  {
    id: "224",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Pailón",
    municipio_cod: "1714",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: [
      "Barrio 24 de Septiembre a un lado de la Plaza Principal 24 de Septiembre",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "225",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Buena Vista",
    municipio_cod: "1515",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Celso Sandoval frente a la Plaza Principal"],
    telefono: null,
    celular: null,
  },
  {
    id: "226",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Villamontes",
    municipio_cod: "1606",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: [
      "Av. Ingavi y la Ruta 11 (frente del monumento del Sombrerito)",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "227",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Coroico",
    municipio_cod: "1265",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Av.Sagarnaga, Casa de la Democracia"],
    telefono: null,
    celular: null,
  },
  {
    id: "228",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "El Torno",
    municipio_cod: "1705",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: [
      "Edificio Canaan, Calle Abasto, diagonal al  Mercado Municipal El Torno",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "229",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "San Lorenzo",
    municipio_cod: "1909",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Barrio Oscar Alfaro- Calle Teofilo Vaca Dols"],
    telefono: null,
    celular: null,
  },
  {
    id: "230",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Tomina",
    municipio_cod: "1111",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle San Mauro"],
    telefono: "72888442",
    celular: null,
  },
  {
    id: "231",
    departamento: "Beni",
    departamento_cod: "8",
    municipio: "Puerto de Rurrenabaque",
    municipio_cod: "1807",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Campero esq. Calle German Busch"],
    telefono: null,
    celular: null,
  },
  {
    id: "232",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "San Pablo de Huacareta",
    municipio_cod: "1116",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Ávila s/n"],
    telefono: "73433048",
    celular: null,
  },
  {
    id: "233",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Tiquipaya",
    municipio_cod: "1304",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Tolavi"],
    telefono: null,
    celular: null,
  },
  {
    id: "234",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Caranavi",
    municipio_cod: "1210",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Batallón de Ingenieros s/n, esq. Av. Cívica"],
    telefono: "67121931",
    celular: null,
  },
  {
    id: "235",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Challapata",
    municipio_cod: "1404",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Heroes del Chaco y Rengel - Casa de Justicia"],
    telefono: "72319065",
    celular: null,
  },
  {
    id: "236",
    departamento: "Pando",
    departamento_cod: "9",
    municipio: "Cobija",
    municipio_cod: "1901",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Barrio Miraflores Av. 9 de Febrero Nro. 211"],
    telefono: "67668484",
    celular: null,
  },
  {
    id: "237",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Potosi",
    municipio_cod: "1501",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Av. Villazon y Av. Sevilla"],
    telefono: "2-6223878",
    celular: null,
  },
  {
    id: "238",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Padcaya",
    municipio_cod: "1602",
    titulo: "Juzgado",
    tipo: "JUZGADO",
    direccion: ["Calle Gilberto Saavedra s/n frente a la Plaza Principal."],
    telefono: "72994002",
    celular: null,
  },
  {
    id: "239",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Serrano",
    municipio_cod: "1122",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Potosí"],
    telefono: "72872755",
    celular: null,
  },
  {
    id: "240",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Padilla",
    municipio_cod: "1110",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Plaza Manuel Asencio Padilla esq. Cardenal Maurer"],
    telefono: "72872755",
    celular: null,
  },
  {
    id: "241",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Culpina",
    municipio_cod: "1124",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Pilaya-Terminal de buses"],
    telefono: "77875308",
    celular: null,
  },
  {
    id: "242",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Machareti",
    municipio_cod: "1128",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle 25 de Mayo"],
    telefono: null,
    celular: null,
  },
  {
    id: "243",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Vaca Guzmán",
    municipio_cod: "1126",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Marzana - Palacio de Justicia"],
    telefono: null,
    celular: null,
  },
  {
    id: "244",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Sacaba",
    municipio_cod: "1312",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: [
      "Final Granado Norte Zona Laicacota",
      "Fiscalía Especializada en delitos de razón de Género",
      "Centro Integral Contra la Violencia de  Sacaba",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "245",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Shinahota",
    municipio_cod: "1347",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Intalaciones de la terminal de Shinahota"],
    telefono: "68479434",
    celular: null,
  },
  {
    id: "246",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Chimoré",
    municipio_cod: "1334",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Las palmas entre Av. Los Pinos"],
    telefono: null,
    celular: null,
  },
  {
    id: "247",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Guanay",
    municipio_cod: "1227",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Entre calles Pilcol y Cecominag"],
    telefono: null,
    celular: null,
  },
  {
    id: "248",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chulumani",
    municipio_cod: "1256",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Sagarnaga - Edif. Casa de Justicia planta baja"],
    telefono: "64154752",
    celular: null,
  },
  {
    id: "249",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Sorata",
    municipio_cod: "1226",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Plaza Principal Enrique Peñaranda del Castillo"],
    telefono: "78970093",
    celular: null,
  },
  {
    id: "250",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "La Asunta",
    municipio_cod: "1260",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: [
      "Plaza Principal 20 de Septiembre, Edif. Casa de Justicia, primer piso",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "251",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "El Alto",
    municipio_cod: "1205",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Cruce Viacha, Av. Satelite"],
    telefono: null,
    celular: null,
  },
  {
    id: "252",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Cuatro Cañadas",
    municipio_cod: "1755",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Tarija esq. Daniel Mamani frente a la Plaza Principal"],
    telefono: "77098907",
    celular: null,
  },
  {
    id: "253",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Camiri",
    municipio_cod: "1723",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Teniente Coronel Sánchez"],
    telefono: null,
    celular: null,
  },
  {
    id: "254",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San José de Chiquitos",
    municipio_cod: "1713",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Humberto Paredes s/n, frente del Mercado Modelo"],
    telefono: "79576145",
    celular: null,
  },
  {
    id: "255",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Pailón",
    municipio_cod: "1714",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: [
      "Barrio 24 de Septiembre a un lado de la Plaza Principal 24 de Septiembre",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "256",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Villamontes",
    municipio_cod: "1606",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: [
      "Av. Ingavi y la Ruta 11 (frente del monumento del Sombrerito)",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "257",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Coroico",
    municipio_cod: "1265",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: [
      "Plaza Principal Victorio García Lanza Edif. De la Gobernación",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "258",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "El Torno",
    municipio_cod: "1705",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Modulo Policial de Santa Rita Km 32"],
    telefono: null,
    celular: null,
  },
  {
    id: "259",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "San Lorenzo",
    municipio_cod: "1909",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Barrio Oscar Alfaro- Calle Teofilo Vaca Dols"],
    telefono: null,
    celular: null,
  },
  {
    id: "260",
    departamento: "Beni",
    departamento_cod: "8",
    municipio: "Puerto de Rurrenabaque",
    municipio_cod: "1807",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Campero esq. Calle German Busch"],
    telefono: null,
    celular: null,
  },
  {
    id: "261",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "San Pablo de Huacareta",
    municipio_cod: "1116",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle 9 de Abril -  Mercado Central, segundo piso"],
    telefono: "77131498",
    celular: null,
  },
  {
    id: "262",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Tiquipaya",
    municipio_cod: "1304",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Plaza 23 de Septiembre Acera Sud"],
    telefono: null,
    celular: null,
  },
  {
    id: "263",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Caranavi",
    municipio_cod: "1210",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: [
      "Calle Batallón de Ingenieros s/n, Edif. Subgobernación Planta baja",
    ],
    telefono: "73848910",
    celular: null,
  },
  {
    id: "264",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Challapata",
    municipio_cod: "1404",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Heroes del Chaco y Rengel - Casa de Justicia"],
    telefono: null,
    celular: null,
  },
  {
    id: "265",
    departamento: "Pando",
    departamento_cod: "9",
    municipio: "Cobija",
    municipio_cod: "1901",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Barrio Miraflores Av. 9 de Febrero Nro. 211"],
    telefono: "67668164",
    celular: null,
  },
  {
    id: "266",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Potosi",
    municipio_cod: "1501",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Av. Villazon y Av. Sevilla"],
    telefono: "2-6223878",
    celular: null,
  },
  {
    id: "267",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Padcaya",
    municipio_cod: "1602",
    titulo: "Fiscalía",
    tipo: "FISCALIA",
    direccion: ["Calle Gilberto Saavedra s/n frente a la Plaza Principal"],
    telefono: "75119908",
    celular: null,
  },
  {
    id: "268",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Charcas",
    municipio_cod: "1129",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Juan Martínez Sorico",
    tipo: "ALCALDIA",
    direccion: ["Frente a la Plaza Principal Julio Andia"],
    telefono: null,
    celular: null,
  },
  {
    id: "269",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Serrano",
    municipio_cod: "1122",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Lorenzo Galarza Benavides",
    tipo: "ALCALDIA",
    direccion: [
      "Calle 6 de agosto esq. Potosí frente a la plaza principal edificio del Gobierno Municipal",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "270",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Padilla",
    municipio_cod: "1110",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Ing. Constancio Salazar Padilla",
    tipo: "ALCALDIA",
    direccion: ["Plaza Manuel Asencio Padilla Nro. 30"],
    telefono: null,
    celular: null,
  },
  {
    id: "271",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "El Villar",
    municipio_cod: "1114",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Marcial Cruz León",
    tipo: "ALCALDIA",
    direccion: ["Calle 3 Febrero s/n"],
    telefono: null,
    celular: null,
  },
  {
    id: "272",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Culpina",
    municipio_cod: "1124",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Ing. Victor Gallardo Rivera",
    tipo: "ALCALDIA",
    direccion: ["Plaza 20 de Diciembre"],
    telefono: null,
    celular: null,
  },
  {
    id: "273",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Machareti",
    municipio_cod: "1128",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Andrés Flores Serrudo",
    tipo: "ALCALDIA",
    direccion: ["Calle 25 de Mayo"],
    telefono: null,
    celular: null,
  },
  {
    id: "274",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Villa Vaca Guzmán",
    municipio_cod: "1126",
    titulo: "Gobierno Autónomo Municipal - Alcalde: German Hermo Herrera Barja",
    tipo: "ALCALDIA",
    direccion: ["Plaza 6 de Agosto"],
    telefono: null,
    celular: null,
  },
  {
    id: "275",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "San Benito",
    municipio_cod: "1317",
    titulo: "Gobierno Autónomo Municipal - Alcalde: J. Jhamil Rodriguez Claros",
    tipo: "ALCALDIA",
    direccion: ["Plaza Benito Moxos acera noreste"],
    telefono: null,
    celular: null,
  },
  {
    id: "276",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Sacaba",
    municipio_cod: "1312",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Pedro Gutierrez Vidaurre",
    tipo: "ALCALDIA",
    direccion: ["Pasaje Consistorial S-002"],
    telefono: null,
    celular: null,
  },
  {
    id: "277",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Omereque",
    municipio_cod: "1309",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Darwin Vera",
    tipo: "ALCALDIA",
    direccion: ["Plaza 7 de Octubre, Acera norte"],
    telefono: null,
    celular: null,
  },
  {
    id: "278",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Shinahota",
    municipio_cod: "1347",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Luis Arce Clemente",
    tipo: "ALCALDIA",
    direccion: ["Plaza Principal 4 de julio, acera oeste"],
    telefono: null,
    celular: null,
  },
  {
    id: "279",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Chimoré",
    municipio_cod: "1334",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Melquiades Claure Gonzales",
    tipo: "ALCALDIA",
    direccion: ["Calle Las Palmas frente a la Plaza Europa"],
    telefono: null,
    celular: null,
  },
  {
    id: "280",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Guanay",
    municipio_cod: "1227",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Victor Ticona",
    tipo: "ALCALDIA",
    direccion: ["Frente a la Plaza Gualberto Villarroel"],
    telefono: null,
    celular: null,
  },
  {
    id: "281",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Yanacachi",
    municipio_cod: "1258",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Víctor Guillermo Fernández Salinas",
    tipo: "ALCALDIA",
    direccion: ["Plaza Libertad camino a Chaguara"],
    telefono: null,
    celular: null,
  },
  {
    id: "282",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Huatajata",
    municipio_cod: "1286",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Timoteo Gutierrez Huañapaco",
    tipo: "ALCALDIA",
    direccion: [
      "Carretera a Copacabana Km. 80.-Palacio Consistorial del Gobierno Autónomo Municipal de Huatajata planta baja.",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "283",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chua Cocani",
    municipio_cod: "1287",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Andrés Canaza Mita",
    tipo: "ALCALDIA",
    direccion: [
      "Plaza Principal Jaime Paz Zamora s/n - Palacio Consistorial del Gobierno Autónomo de Chua Cocani",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "284",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Patacamaya",
    municipio_cod: "1215",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Pedro Delgado Aro",
    tipo: "ALCALDIA",
    direccion: ["Zona Asunción, Av. 6 de agosto, Sede Social"],
    telefono: null,
    celular: null,
  },
  {
    id: "285",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Chulumani",
    municipio_cod: "1256",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Ramiro Avendaño Navia",
    tipo: "ALCALDIA",
    direccion: ["Calle General Pando"],
    telefono: null,
    celular: null,
  },
  {
    id: "286",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Sorata",
    municipio_cod: "1226",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Lic. Eustaquio Huayta Poma",
    tipo: "ALCALDIA",
    direccion: ["Plaza Principal Enrique Peñaranda del Castillo"],
    telefono: null,
    celular: null,
  },
  {
    id: "287",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "La Asunta",
    municipio_cod: "1260",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Benjamín Chuquimia Nina",
    tipo: "ALCALDIA",
    direccion: ["Plaza Principal 20 de Septiembre"],
    telefono: null,
    celular: null,
  },
  {
    id: "288",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Santiago de Callapa",
    municipio_cod: "1245",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Fidel Ramos Condori",
    tipo: "ALCALDIA",
    direccion: ["Plaza 25 Julio s/n Santiago de Callapa"],
    telefono: null,
    celular: null,
  },
  {
    id: "289",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "El Alto",
    municipio_cod: "1205",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Monica Eva Copa Murga",
    tipo: "ALCALDIA",
    direccion: [
      "Edificio Jach’a Uta. Se encuentra ubicado en la Avenida Costanera, Nro: 5022 Urbanización Libertad, entre calle J.J. Torres y calle Hernán Siles Suazo (Jach´a Uta), a media cuadra de la estación de bomberos El Alto",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "290",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Pazña",
    municipio_cod: "1409",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Martin Sabino Quispe Alarcon",
    tipo: "ALCALDIA",
    direccion: ["Plaza Principal 6 de Enero"],
    telefono: null,
    celular: null,
  },
  {
    id: "291",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Cruz de Machacamarca",
    municipio_cod: "1420",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Santos Saul Flores Copa",
    tipo: "ALCALDIA",
    direccion: ["Plaza Principal 29 de Mayo s /n"],
    telefono: null,
    celular: null,
  },
  {
    id: "292",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Machacamarca",
    municipio_cod: "1407",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Tec. Egr. Serafín Escobar Quispe",
    tipo: "ALCALDIA",
    direccion: ["Calle Ladislao Cabrera esq. Calle Bolívar"],
    telefono: null,
    celular: null,
  },
  {
    id: "293",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Porco",
    municipio_cod: "1532",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Prof. René Guanaco Mamani",
    tipo: "ALCALDIA",
    direccion: ["Plaza Principal La Libertad"],
    telefono: null,
    celular: null,
  },
  {
    id: "294",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Pocoata",
    municipio_cod: "1513",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Bladimir Tumiri Cocha",
    tipo: "ALCALDIA",
    direccion: ["Plaza Principal 10 de Noviembre"],
    telefono: null,
    celular: null,
  },
  {
    id: "295",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Atocha",
    municipio_cod: "1520",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Juaquin Milton Chiri Yanqui",
    tipo: "ALCALDIA",
    direccion: ["Av. Bush Nº 120"],
    telefono: null,
    celular: null,
  },
  {
    id: "296",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Ramón",
    municipio_cod: "1750",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Germán Sánchez Padilla",
    tipo: "ALCALDIA",
    direccion: ["Av. Santa Cruz- Barrio 13 de Mayo"],
    telefono: null,
    celular: null,
  },
  {
    id: "297",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "General Saavedra",
    municipio_cod: "1735",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Roly Rolando Oño Añez",
    tipo: "ALCALDIA",
    direccion: ["Frente a la Plaza Principal Pedro Nuñez"],
    telefono: null,
    celular: null,
  },
  {
    id: "298",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Cuatro Cañadas",
    municipio_cod: "1755",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Hugo Gutierrez Vique",
    tipo: "ALCALDIA",
    direccion: ["Barrio San Antonio"],
    telefono: null,
    celular: null,
  },
  {
    id: "299",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Camiri",
    municipio_cod: "1723",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Ing. David Anzaldo Anzaldo",
    tipo: "ALCALDIA",
    direccion: ["Frente a la Plaza Principal 12 de Julio acera Norte"],
    telefono: null,
    celular: null,
  },
  {
    id: "300",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San Miguel de Velasco",
    municipio_cod: "1708",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Pedro Damian Dora Lopez",
    tipo: "ALCALDIA",
    direccion: ["Calle Betania-Plaza Principal 29 de Septiembre"],
    telefono: null,
    celular: null,
  },
  {
    id: "301",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "San José de Chiquitos",
    municipio_cod: "1713",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Ing. Marbin Barbery Céspedes",
    tipo: "ALCALDIA",
    direccion: [
      "Calle Velasco, Esq. Linares frente a la Plaza Principal Felipe Suarez",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "302",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Pailón",
    municipio_cod: "1714",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Dr. Hagapito Castro Vaca",
    tipo: "ALCALDIA",
    direccion: [
      "Barrio 24 de Septiembre a un lado de la Plaza Principal 13 de Mayo",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "303",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Buena Vista",
    municipio_cod: "1515",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Ing. Teodoro Gonzales Saucedo",
    tipo: "ALCALDIA",
    direccion: ["Calle Celso Sandoval Esq. Juan Moreno Raldes"],
    telefono: null,
    celular: null,
  },
  {
    id: "304",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Villamontes",
    municipio_cod: "1606",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Rubén Vaca Salazar",
    tipo: "ALCALDIA",
    direccion: [
      "Barrio Central entre Calles Paz y  Av . Méndez Arcos, frente a la Plaza Principal 24 de julio.",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "305",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Coroico",
    municipio_cod: "1265",
    titulo: "Gobierno Autónomo Municipal - Alcaldesa: Elizabeth Mamani Yujra",
    tipo: "ALCALDIA",
    direccion: ["Plaza Principal Manuel Victorio Lanza"],
    telefono: null,
    celular: null,
  },
  {
    id: "306",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "El Torno",
    municipio_cod: "1705",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Ing. Hediberto Cuellar Cuva",
    tipo: "ALCALDIA",
    direccion: [
      "Edificio Municipal, Calle 25 de Julio - Plaza Principal 25 de Julio",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "307",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "San Lorenzo",
    municipio_cod: "1909",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Asunción Ramos",
    tipo: "ALCALDIA",
    direccion: [
      "Barrio Central, Calle Rodolfo Avila frente a la Plaza Principal Eustaquio Méndez",
    ],
    telefono: null,
    celular: null,
  },
  {
    id: "308",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Tomina",
    municipio_cod: "1111",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Angel Calderon",
    tipo: "ALCALDIA",
    direccion: ["Av. San Pablo s/n esq. Avaroa"],
    telefono: null,
    celular: null,
  },
  {
    id: "309",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "Alcalá",
    municipio_cod: "1113",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Oscar Salazar",
    tipo: "ALCALDIA",
    direccion: ["Plaza 25 de Mayo"],
    telefono: null,
    celular: null,
  },
  {
    id: "310",
    departamento: "Beni",
    departamento_cod: "8",
    municipio: "Puerto de Rurrenabaque",
    municipio_cod: "1807",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Elías Moreno Vargas",
    tipo: "ALCALDIA",
    direccion: ["Calle Campero entre Abaroa y Comercio"],
    telefono: null,
    celular: null,
  },
  {
    id: "311",
    departamento: "Chuquisaca",
    departamento_cod: "1",
    municipio: "San Pablo de Huacareta",
    municipio_cod: "1116",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Alfredo Fuentes Mariscal",
    tipo: "ALCALDIA",
    direccion: ["Plaza 21 de Septiembre"],
    telefono: null,
    celular: null,
  },
  {
    id: "312",
    departamento: "Cochabamba",
    departamento_cod: "3",
    municipio: "Tiquipaya",
    municipio_cod: "1304",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Juan Pahuasi Argote",
    tipo: "ALCALDIA",
    direccion: ["Plaza 23 de Septiembre acera norte"],
    telefono: null,
    celular: null,
  },
  {
    id: "313",
    departamento: "La Paz",
    departamento_cod: "2",
    municipio: "Caranavi",
    municipio_cod: "1210",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Eustaquio Huiza Tapia",
    tipo: "ALCALDIA",
    direccion: ["Plaza Libertador Simón Bolívar"],
    telefono: null,
    celular: null,
  },
  {
    id: "314",
    departamento: "Oruro",
    departamento_cod: "4",
    municipio: "Challapata",
    municipio_cod: "1404",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Marcos Choqueticlla Tito",
    tipo: "ALCALDIA",
    direccion: ["Plaza Eduardo Avaroa, Calle Bolívar y Ladislao Cabrera"],
    telefono: null,
    celular: null,
  },
  {
    id: "315",
    departamento: "Pando",
    departamento_cod: "9",
    municipio: "Cobija",
    municipio_cod: "1901",
    titulo: "Gobierno Autónomo Municipal - Alcaldesa: Ana Lucia Reis Melena",
    tipo: "ALCALDIA",
    direccion: ["Plaza Germán Busch, Av. Teniente Cnl. Cornejo Nro. 40"],
    telefono: null,
    celular: null,
  },
  {
    id: "316",
    departamento: "Potosi",
    departamento_cod: "5",
    municipio: "Potosi",
    municipio_cod: "1501",
    titulo:
      "Gobierno Autónomo Municipal - Alcaldesa: Mvz. Lesly Janeth Flores Mollo",
    tipo: "ALCALDIA",
    direccion: ["Plaza Principal 10 de Noviembre"],
    telefono: null,
    celular: null,
  },
  {
    id: "317",
    departamento: "Tarija",
    departamento_cod: "6",
    municipio: "Padcaya",
    municipio_cod: "1602",
    titulo:
      "Gobierno Autónomo Municipal - Alcalde: Dr. Willams Joel Guerrero Quiroga",
    tipo: "ALCALDIA",
    direccion: ["Barrio Lindo Av. Bermejo s/n (ex Hospital San Roque)"],
    telefono: null,
    celular: null,
  },
  {
    id: "318",
    departamento: "Santa Cruz",
    departamento_cod: "7",
    municipio: "Porongo",
    municipio_cod: "1703",
    titulo: "Gobierno Autónomo Municipal - Alcalde: Dr. Neptaly Mendoza Durán",
    tipo: "ALCALDIA",
    direccion: ["Plaza principal de Porongo"],
    telefono: null,
    celular: null,
  },
];

const value = directoriosF.map(directory =>
`INSERT INTO municipal_directory(id, direcciones, telefonos, title, type, municipality_municipio_cod)
VALUES(
          gen_random_uuid (),
          '{ ${(directory.direccion ?? []).map(dir => `"${dir.replaceAll("'", "''")}"`).join(", ")} }',
          '{ ${[directory.telefono, directory.celular].filter(p => p).map(p => `"${p?.replaceAll("'", "''")}"`).join(", ")} }',
          '${directory.titulo}',
          '${directory.tipo}',
          '${directory.municipio_cod}'
)`).join("; \n")



// console.log(value);
