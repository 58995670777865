import Swal from "sweetalert2";

type CAlertProps = {
  title?: string;
  description?: string;
  confirmText?: string;
  onConfirm?: () => void;
};

type ShowConfirmProps = CAlertProps & {
  onConfirm: () => void;
  onCancel?: () => void;
  icon?: string;
  cancelText?: string;
};

export class Alerts {
  static showConfirm = (props: ShowConfirmProps) => {
    const customSwal = Swal.mixin({
      customClass: {
        title: "custom-title-swal",
        htmlContainer: "custom-text-swal",
        actions: "custom-actions-swal",
        cancelButton: "custom-btn-cancel-swal",
        confirmButton: "custom-btn-confirm-swal",
      },
      reverseButtons: true,
      buttonsStyling: false,
    });
    customSwal
      .fire({
        showCancelButton: true,
        confirmButtonText: props.confirmText ?? "Confirmar",
        cancelButtonText: props.cancelText ?? "Cancelar",
        title: props.title,
        text: props.description,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      })
      .then((value) => {
        if (value.isConfirmed) props.onConfirm();
        console.log(value);
        if (value.isDismissed && props.onCancel) props.onCancel();
      });
  };

  static showWarning = (props: CAlertProps) => {
    const customSwal = Swal.mixin({
      customClass: {
        title: "custom-title-warning-swal",
        htmlContainer: "custom-text-swal",
        actions: "custom-actions-swal",
        cancelButton: "custom-btn-cancel-swal",
        confirmButton: "custom-btn-confirm-swal",
        image: "custom-image-warning-swal",
      },
      reverseButtons: true,
      buttonsStyling: false,
    });

    customSwal.fire({
      confirmButtonText: props.confirmText ?? "Continuar",
      title: props.title ?? "ATENCIÓN",
      text: props.description,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  static showSuccess = (props: CAlertProps) => {
    const customSwal = Swal.mixin({
      customClass: {
        title: "custom-title-success-swal",
        htmlContainer: "custom-text-swal",
        actions: "custom-actions-swal",
        cancelButton: "custom-btn-cancel-swal",
        confirmButton: "custom-btn-confirm-swal",
      },
      reverseButtons: true,
      buttonsStyling: false,
    });
    customSwal.fire({
      confirmButtonText: props.confirmText ?? "Continuar",
      title: props.title ?? "ÉXITO",
      text: props.description,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  static showInfo = (props: CAlertProps) => {
    const customSwal = Swal.mixin({
      customClass: {
        title: "custom-title-info-swal",
        htmlContainer: "custom-text-swal",
        actions: "custom-actions-swal",
        cancelButton: "custom-btn-cancel-swal",
        confirmButton: "custom-btn-confirm-swal",
      },
      reverseButtons: true,
      buttonsStyling: false,
    });
    customSwal
      .fire({
        confirmButtonText: props.confirmText ?? "Continuar",
        title: props.title ?? "INFORMACIÓN",
        text: props.description,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      })
      .then((value) => {
        if (value.isConfirmed && props.onConfirm) props.onConfirm();
        // console.log(value);
        // if (value.isDismissed && props.onCancel) props.onCancel();
      });
  };

  static showError = (props: CAlertProps) => {
    const customSwal = Swal.mixin({
      customClass: {
        title: "custom-title-error-swal",
        htmlContainer: "custom-text-swal",
        actions: "custom-actions-swal",
        cancelButton: "custom-btn-cancel-swal",
        confirmButton: "custom-btn-confirm-swal",
      },
      reverseButtons: true,
      buttonsStyling: false,
    });
    customSwal.fire({
      confirmButtonText: props.confirmText ?? "Continuar",
      title: props.title ?? "ERROR",
      text: props.description,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };
}
