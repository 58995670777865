import { Box, styled } from "@mui/material";
import theme from "../../themes";
import { useHistory } from "react-router-dom";
import marcaPortada from "../../assets/marca_cv_nueva.png";

const StyledHomeIcon = styled("img")(() => ({
  maxWidth: "100%",
  objectFit: "contain",
  height: "100%",
  cursor: "pointer",
}));

const MenuIconBox = () => {
  let history = useHistory();
  return (
    <Box
      sx={{
        borderBottom: `6px solid ${theme.palette.primary.main}`,
        overflow: "visible",
        height: "74px",
        position: "relative",
        display: "inline-box",
        marginLeft: "1.5em",
      }}
    >
      <StyledHomeIcon
        src={marcaPortada}
        alt="Vida digna sin violencia Home"
        onClick={() => history.push('/')}
      />
    </Box>
  )
};

export default MenuIconBox;