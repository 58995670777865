import { Box, Typography, alpha, styled } from "@mui/material";
import bgTop from "../../../assets/network_banner.jpg";
import theme from "../../../themes";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  // height: 400,
  height: "70vh",
  backgroundImage: `url(${bgTop})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "fit-content",
    height: 500,
  },
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 1.5em 1.5em 1.5em",
  backgroundColor: alpha(theme.palette.info.dark, 0.85),
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const MainBanner = () => {
  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledDivMediaCaption>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            sx={{ height: "100%", margin: 0, padding: 0 }}
          >
            <Box mb={2}>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: 24,
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                  lineHeight: 2,
                }}
              >
                DIRECTORIO MUNICIPAL
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: 42,
                  fontWeight: 400,
                  color: theme.palette.common.white,
                  lineHeight: 1,
                }}
              >
                Información de los Servicios Municipales
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                Información de servicios municipales proporcionados por los 
                municipios participantes del proyecto Vida Digna Sin Violencia. 
                Permite acceder fácilmente a direcciones y números telefónicos de 
                servicios municipales que brindan atención y protección a mujeres 
                en situación de violencia, además de instituciones que aportan en 
                el ejercicio integral de los derechos de las mujeres.
              </Typography>
            </Box>
          </Box>
        </StyledDivMediaCaption>
      </Box>
    </StyledBox>
  );
};

export default MainBanner;
