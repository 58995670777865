import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Slide,
  Typography,
  styled,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseRounded } from "@mui/icons-material";
import { MouseEventHandler, forwardRef } from "react";

export const CoursePrimaryButton = styled((props: ButtonProps) => (
  <Button variant="contained" disableElevation color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  background:
    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
  fontFamily: 'Barlow',
  borderRadius: "8px",
  color: "white",
  paddingTop: ".5rem",
  paddingBottom: ".5rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
  // border: "1px solid rgba(0,119,170,1)",
  "&:hover": {
    background:
      "linear-gradient(180deg, rgba(0,191,232,.95) 0%, rgba(0,154,219,.95) 53%, rgba(0,119,170,.95) 100%)",
  },
}));

export const CourseWhiteButton = styled((props: ButtonProps) => (
  <Button variant="contained" disableElevation color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  background: theme.palette.common.white,
  fontFamily: 'Barlow',
  borderRadius: "8px",
  color: theme.palette.common.black,
  paddingTop: ".5rem",
  paddingBottom: ".5rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
}));

export const CoursePrimaryButtonText = styled((props: ButtonProps) => (
  <Button variant="text" disableElevation color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  textTransform: "initial",
  color: theme.palette.info.main,
  fontSize: "1.1rem",
}));

export const CourseNeutralButton = styled((props: ButtonProps) => (
  <Button variant="outlined" color="inherit" {...props}>
    {props.children}
  </Button>
))(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral.main}`,
  background: 'white',
  borderRadius: "8px",
  minWidth: "initial",
  color: theme.palette.neutral.main,
  py: 1,
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CourseAlertDialog = styled((props: any) => (
  <Dialog
    maxWidth={"xs"}
    TransitionComponent={Transition}
    keepMounted
    {...props}
  />
))`
  & .MuiPaper-root {
    border-radius: 10px;
  }
`;

type CourseContentDialogProps = {
  withActions?: boolean
  withTitle?: boolean
  ctitle?: string
  actiontext?: string
  handleConfirm?: VoidFunction
}

export const CourseContentDialog = styled(({
  withTitle = true,
  withActions = true,
  handleConfirm,
  ...props
}: DialogProps & CourseContentDialogProps) => (
  <Dialog maxWidth={"sm"} fullWidth keepMounted {...props}>
    <Box sx={{ position: 'relative' }}>
      <Box
        display="flex"
        justifyContent={"end"}
        alignItems={"center"}
        sx={{
          m: 0,
          p: .5
        }}
      >
        <IconButton onClick={props.onClose as MouseEventHandler}>
          <CircleBox sx={{ background: "#818080", p: 0.5 }}>
            <CloseRounded sx={{ fontSize: "1rem" }} />
          </CircleBox>
        </IconButton>
      </Box>
      <DialogContent sx={{ paddingTop: 0, marginTop: 0 }}>
        {withTitle && <Box mb={3}>
          <Typography sx={{ fontSize: "1.6rem" }}>{props.ctitle}</Typography>
        </Box>}
        <Box>{props.children}</Box>
        {withActions && <Box
          sx={{
            paddingTop: 5,
            paddingBottom: 3,
            position: 'relative',
            zIndex: 99,
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <CourseNeutralButton onClick={props.onClose as MouseEventHandler}>
              Cancelar
            </CourseNeutralButton>
            <CoursePrimaryButton sx={{ ml: 2 }} onClick={handleConfirm}>
              {props.actiontext}
            </CoursePrimaryButton>
          </Box>
        </Box>}
      </DialogContent>
      {/* <Box sx={{
        height: '100px',
        overflow: 'hidden',
        position: 'absolute',
        bottom: -10, right: 0, left: 170,
        zIndex: 2,
      }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{height: '100%', width: '100%'}}
        >
          <path
            d="M90.00,77.03 C252.50,-84.96 251.87,232.03 543.75,-40.96 L522.50,172.04 L-13.75,182.04 Z"
            style={{stroke: 'none', fill: 'rgb(224, 224, 224)'}}
          ></path>
        </svg>
      </Box>
      <Box sx={{
        height: '100px',
        overflow: 'hidden',
        position: 'absolute',
        bottom: 40, right: -60, left: 170,
        zIndex: 0,
      }}>
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}><path d="M-45.62,158.04 C241.87,102.03 639.37,-98.96 516.87,-17.96 L516.87,173.03 L-36.25,209.04 Z" style={{stroke: 'none', fill: 'rgb(237, 237, 237)'}}></path></svg>
      </Box>
      <Box sx={{
        height: '120px',
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0, right: 0, left: 0,
        zIndex: 0,
      }}>
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}><path d="M-59.37,277.04 C115.62,-358.96 56.25,339.04 496.87,88.03 L536.87,156.03 L-43.12,193.03 Z" style={{stroke: 'none', fill: 'rgb(247, 247, 247)'}}></path></svg>
      </Box> */}
    </Box>
  </Dialog>
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "10px",
    paddingTop: ".5rem",
    // paddingBottom: ".5rem",
    background: "#FFFFFF",
  },
  // "& .pattern": {
  //   background: "#E0E0E0",
  //   height: "2rem"
  // },
}));

export const CircleBox = styled(Box)(({ theme }) => ({
  background:
    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
  borderRadius: "50%",
  color: "white",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

// TODO: background wave
// izq a centro > f7f7f7
// centro a derecha > E0E0E0
// derecha a centro > EDEDED
