import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { Box, Card, Divider, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DesignSystem } from "../../../themes/theme";
import { DetailCampaignGeneral } from "./components/DetailCampaignGeneral";
import { DetailCampaignResources } from "./components/DetailCampaignResources";
import { printLog } from "../../../shared/utils";
import { useCampaignContext, withCampaignContextProvider } from "./context";
import { NeutralButton, PrimaryButton } from "./shared/Elements";
import { Alerts } from "../../../shared/hooks/useAlert";
import {
  useCreateCampaign,
  useGetCampaignById,
  useUpdateCampaign,
} from "./data";

const DetailCampaignPage = () => {
  const navigate = useHistory();
  const location = useLocation();
  const myState: any = location.state;
  const [campaign, dispatch] = useCampaignContext();

  const getCampaign = useGetCampaignById();
  const updateCampaign = useUpdateCampaign();
  const createCampaign = useCreateCampaign();

  const fetchCampaign = useCallback(async (campaignId: string) => {
    const fetchedCampaign = await getCampaign(campaignId);
    printLog("Campaign fetched :: ", fetchedCampaign);
    dispatch({ type: "LOAD_CAMPAIGN", campaign: fetchedCampaign });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myState.campaignId) {
      fetchCampaign(myState.campaignId);
    }
  }, [fetchCampaign, myState.campaignId]);

  const handleCloseDetalle = () => {
    navigate.goBack();
  };

  const handleSaveCampaign = async () => {
    printLog("campaña >> ", campaign);
    const { titulo, descripcion, recursos } = campaign;
    const errores = [];
    if (!titulo || titulo.trim() === "") {
      errores.push("La campaña debe tener un título");
    }
    if (!descripcion || descripcion.trim() === "") {
      errores.push("La campaña debe tener una descripción");
    }
    if (!recursos || recursos.length === 0) {
      errores.push("La campaña debe tener al menos un recurso");
    }
    printLog("errrores > ", errores);
    if (errores.length) {
      Alerts.showError({
        description: errores.join("."),
      });
      return;
    }

    if (campaign.id) {
      printLog("Actualizar campaña.... ", campaign);
      await updateCampaign({ ...campaign }, campaign.id);
    } else {
      printLog("Guardar campaña....", campaign);
      await createCampaign({ ...campaign });
    }
    handleCloseDetalle();
  };

  return (
    <React.Fragment>
      <Card elevation={0} sx={{ borderRadius: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          alignContent="center"
          sx={{
            borderBottom: "1px solid #cacaca",
            paddingX: "2rem",
            paddingY: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton size="small" onClick={handleCloseDetalle}>
              <KeyboardBackspaceRounded />
            </IconButton>
            <Typography
              sx={{ ...DesignSystem?.titulo1, flex: 1, fontWeight: 500 }}
            >
              Campaña
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingX: "2rem", pb: 4 }}>
          <DetailCampaignGeneral />
          <Box my={2} />
          <Divider />
          <DetailCampaignResources />
          <Box display="flex" gap={2} justifyContent="flex-end" mt={3}>
            <NeutralButton onClick={handleCloseDetalle}>Cancelar</NeutralButton>
            <PrimaryButton onClick={handleSaveCampaign}>Guardar</PrimaryButton>
          </Box>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default withCampaignContextProvider(DetailCampaignPage);
