import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { DeleteOutlineRounded } from '@mui/icons-material'
import CInputForm from "../../../../../shared/components/form/CInputForm";

type PropsComunes = "titulo" | "descripcion";
type EditorValoresComunesProps = {
  indicePregunta: number;
  pregunta: Record<PropsComunes, string>;
  onUpdate: (prop: PropsComunes, value: string) => void;
  onDelete: () => void;
};

export function EditorValoresComunes({
  indicePregunta,
  pregunta,
  onUpdate,
  onDelete,
}: EditorValoresComunesProps) {
  const handleChange =
    (prop: PropsComunes) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      onUpdate(prop, target.value);
    };

  return (
    <>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={2}>
          <Typography component="p" sx={{ mr: 2 }}>
            {indicePregunta + 1}. Pregunta
          </Typography>
        </Grid> */}
        <Grid item xs={12} md={11}>
          <CInputForm
            type="text"
            placeholder="Introduce el título de la pregunta"
            // name={pregunta.elementId}
            value={pregunta.titulo ? pregunta.titulo : ""}
            onChange={handleChange("titulo")}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <IconButton sx={{ fill: 'red' }} onClick={onDelete}>
            <DeleteOutlineRounded />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 1 }}>
        <Grid item xs={12} md={11}>
          <CInputForm
            type="text"
            placeholder="Introduce una breve descripción de la pregunta"
            // name={pregunta.elementId}
            value={pregunta.descripcion ? pregunta.descripcion : ""}
            onChange={handleChange("descripcion")}
          />
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>
    </>
  );
}
