import { Box, Pagination, PaginationItem } from "@mui/material";
import { useEffect, useState } from "react";
import MunicipalityCard from "./MunicipalityCard";
import useAppContext from "../../../../shared/hooks/useAppContext";
import Categories from "./Categories";
import React from "react";

type PaginationType = {
  count: number;
  from: number;
  to: number;
};

const News = () => {
  const { newsMedia, newsList, getNewsList } = useAppContext();
  const pageSize = 4;
  const [pagination, setPagination] = useState<PaginationType>({
    count: 0,
    from: 0,
    to: pageSize,
  });

  const handlePageChange = (event: any, page: any) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;

    let data: any = newsToRender.slice(from, to);
    setSlicedNewsToRender(data);

    setPagination({ ...pagination, from: from, to: to });
  };

  const [filterNews, setFilterNews] = useState<string>("");
  const [mediaMap, setMediaMap] = useState<Map<any, any>>();
  const [newsToRender, setNewsToRender] = useState<Array<any>>([]);
  const [slicedNewsToRender, setSlicedNewsToRender] = useState<Array<any>>([]);

  const handleFilters = (str: string) => {
    setFilterNews(str);
  };

  useEffect(() => {
    let mediaMap: Map<any, any> = new Map();

    for (let media of newsMedia) {
      mediaMap.set(media.medio_cod, media.medio_nombre);
    }

    setMediaMap(mediaMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tmpArray = newsList.slice(0, pageSize);

    setNewsToRender(newsList);
    setSlicedNewsToRender(tmpArray);
    setPagination({ from: 0, to: pageSize, count: newsList.length });
  }, [newsList]);

  //Filters
  useEffect(() => {
    getNewsList(filterNews);
    setPagination({ from: 0, to: pageSize, count: newsToRender.length });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterNews]);

  const handleScroll = () => {
    window.scrollTo({ top: 1025, behavior: "smooth" });
  };

  return (
    <Box id="noticias">
      <Categories handleFilters={handleFilters} />
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          margin: "3em  0 6em 0",
          padding: 0,
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "75%" },
            height: "100%",
            margin: 0,
            borderRadius: "8px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",
              margin: 0,
              padding: "0 1.5em 0 0",
            }}
          >
            {slicedNewsToRender
              ? slicedNewsToRender.map((item, index) => {
                  const isPress =
                    item && item.tipo === "ENLACE_INTERES" ? true : false;
                  console.log(item);
                  return (
                    <Box
                      display="flex"
                      sx={{ width: { xs: "100%", md: "60%" } }}
                      key={item.codigoNoticia ?? item.codigoPrensa}
                    >
                      <MunicipalityCard
                        image={item.urlImagen}
                        title={item.titulo}
                        url={isPress && item.prensa_url ? item.prensa_url : ""}
                        date={item.fechaLegacyFormat}
                        isInterestLink={isPress ? true : false}
                        mediaStr={item.tag}
                        lastDateModified={item.fechaLegacyFormat}
                        code={
                          isPress
                            ? parseInt(item.codigoPrensa)
                            : parseInt(item.codigoNoticia)
                        }
                        urlSufix={
                          isPress && item.prensa_url ? item.prensa_url : ""
                        }
                        category={item.categoria}
                        isSolidar={item.esNoticiaSolidar}
                      />
                    </Box>
                  );
                })
              : null}
          </Box>
          <Box display="flex" justifyContent="center" mt={"1.5em"}>
            <Pagination
              color="primary"
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    backgroundColor: "#dedede",
                    color: "theme.palette.neutral.active",
                  }}
                />
              )}
              count={Math.ceil(pagination.count / pageSize)}
              onChange={handlePageChange}
              onClick={handleScroll}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default News;
