import { adminTheme } from "./muiTheme";

// Colores base
export const colorsBase = adminTheme.colorsBase;

// Estilos de fuente
export const DesignSystem = adminTheme.designSystem;

export const BackgroundCards = [
  "linear-gradient(90deg, #BDE5F7 0%, rgba(186, 229, 247, 0) 100%)",
  "linear-gradient(90deg, #CBD253 0%, rgba(248, 246, 247, 0) 100%)",
  "linear-gradient(90deg, rgba(233, 76, 41, 0.7) 0%, rgba(233, 76, 41, 0) 100%)",
  "linear-gradient(90deg, rgba(183, 56, 118, 0.7) 0%, rgba(183, 56, 118, 0) 100%)",
];

export const BorderCards = [
  colorsBase.Verde2,
  colorsBase.Lila2,
  colorsBase.Rosado,
  colorsBase.Azul2,
];

export const FichasColor = [
  colorsBase.Verde3,
  colorsBase.Lila2,
  colorsBase.Naranja2,
  // colorsBase.Azul2,
];

export const notificationColors: any = {
  retroalimentacion: colorsBase.Verde1,
  noticia: colorsBase.Azul1,
  recordatorio: colorsBase.Naranja1,
};

export const CardQuizRespuesta = {
  background: `linear-gradient(white, white) content-box, linear-gradient(to right, ${colorsBase.Verde3}, white 100%) border-box`,
  borderRadius: "10px !important",
  borderWidth: "2px",
  borderStyle: "solid none solid solid",
  borderColor: "transparent",
  my: 3,
  boxShadow: "1.5px 2.6px 10px rgba(119, 119, 119, 0.1)",
};
