// import useHttp from "../../../../shared/hooks/useHttp";
import { v4 as uuidv4 } from "uuid";
import { printLog } from "../../../../shared/utils";
import { Campaign } from "../interface";

export const campaignsFake = [
  {
    id: 1,
    titulo: "Red de Lucha Contra la Violencia",
    descripcion:
      "Redes municipales que trabajan de forma coordinada para brindar mejores servicios de prevención, atención y protección a las mujeres en situación o riesgo de violencia.",
    recursos: null,
    tipo: "OTRO",
  },
  {
    id: 2,
    titulo: "Mujeres",
    descripcion:
      "Mujeres fortalecidas trabajan por su autonomía, hablan de sus derechos y generan alianzas municipales desde sus propuestas de incidencia.",
    recursos: null,
    tipo: "OTRO",
  },
  {
    id: 3,
    titulo: "Otras Masculinidades",
    descripcion:
      "Reflexión y acción para la construcción de nuevas masculinidades que contribuyan a una vida digna y sin violencia.",
    recursos: null,
    tipo: "OTRO",
  },
  {
    id: 4,
    titulo: "Eduactivistas",
    descripcion:
      "¡Jóvenes en acción por una vida libre de violencia! Trabajo activo de la Red Nacional de EduActivistas de universidades y de las y los jóvenes en municipios, que realizan acciones de prevención de la violencia hacia las mujeres.",
    recursos: null,
    tipo: "OTRO",
  },
];

export function useGetCampaigns(): (tipo: string) => Promise<any> {
  // const http = useHttp();
  // return async () => http.get("/campaigns", {});
  return async (tipo: string) => {
    const _campaigns = localStorage.getItem("campaigns");
    printLog("Campañas obtenidos de localstorage > ", _campaigns);
    let campaigns;
    if (!_campaigns) {
      localStorage.setItem("campaigns", JSON.stringify(campaignsFake));
      const filtrados = campaignsFake.filter((item) => item.tipo === tipo);
      campaigns = filtrados;
    } else {
      const _parseado = JSON.parse(_campaigns);
      const filtrados = _parseado.filter((item: any) => item.tipo === tipo);
      campaigns = filtrados;
    }
    return campaigns;
  };
}

export function useGetCampaignById(): (campaignId: string) => Promise<any> {
  // const http = useHttp();
  // return async (campaignId: string) => http.get(`/campaigns/${campaignId}`, {});
  return async (campaignId: string) => {
    const _campaigns = localStorage.getItem("campaigns");
    printLog("Campañas obtenidos de localstorage > ", _campaigns);
    const campaigns = JSON.parse(_campaigns!);
    return campaigns.find((item: any) => item.id === campaignId);
  };
}

export function useCreateCampaign(): (payload: Campaign) => Promise<void> {
  // const http = useHttp();
  return async (payload: Campaign) => {
    const newCampaign = {
      ...payload,
      tipo: "NACIONAL", // TODO: solo se puede crear campañas nacionales?
      id: uuidv4(),
    };
    printLog("[create:campaign] >>> ", newCampaign);
    const _campaigns = localStorage.getItem("campaigns");
    printLog("Campañas obtenidos de localstorage > ", _campaigns);
    const campaigns = _campaigns ? JSON.parse(_campaigns) : [];
    campaigns.push(newCampaign);
    printLog("Campañas >>> ", campaigns);
    localStorage.setItem("campaigns", JSON.stringify(campaigns));
    // return await http.post(`/campaigns`, {
    //   payload: formData,
    //   withFiles: true,
    // });
  };
}

export function useUpdateCampaign(): (
  payload: Campaign,
  campaignId: string
) => Promise<any> {
  // const http = useHttp();
  return async (payload: Campaign, campaignId: string) => {
    const _campaigns = localStorage.getItem("campaigns");
    printLog("campaigns obtenidos de localstorage > ", _campaigns);
    const campaigns = _campaigns ? JSON.parse(_campaigns) : [];
    const idxCampaign = campaigns.findIndex(
      (item: any) => item.id === campaignId
    );
    printLog("indice de campaign >> ", idxCampaign);
    campaigns[idxCampaign] = { ...payload };
    printLog("Campaigns actualizados >>> ", campaigns);
    localStorage.setItem("campaigns", JSON.stringify(campaigns));
    // return await http.put(`/campaigns/${campaignId}`, {
    //   payload: formData,
    //   withFiles: true,
    // });
  };
}

export function useDeleteCampaign(): (campaignId: string) => Promise<void> {
  // const http = useHttp();
  return async (campaignId: string) => {
    const _campaigns = localStorage.getItem("campaigns");
    printLog("campaigns obtenidos de localstorage > ", _campaigns);
    const campaigns = _campaigns ? JSON.parse(_campaigns) : [];
    const idxCampaign = campaigns.findIndex(
      (item: any) => item.id === campaignId
    );
    printLog("indice de campaign >> ", idxCampaign);
    campaigns.splice(idxCampaign, 1);
    localStorage.setItem("campaigns", JSON.stringify(campaigns));
    // return await http.delete(`/campaigns/${campaignId}`, {});
  };
}
