import { createContext, useState, useCallback } from "react";
import useHttp from "../shared/hooks/useHttp";
import useHttpExternal from "../shared/hooks/useHttpExternal";

type AppContextType = {
  //home
  homeNews: any[];
  getHomeNews: () => void;
  homeCourses: any[];
  getHomeCourses: () => void;
  //news
  newsMedia: any[];
  getNewsMedia: () => void;
  newsCategories: any[];
  getNewsCategories: () => void;
  pressList: any[];
  getPressList: (queryParams: string) => void;
  newsList: any[];
  getNewsList: (queryParams: string) => void;
  getInterestLinkContent: (id: number) => void;
  interestLinkContent?: any;
  setInterestLinkContent?: any;
  getNewsContent: (id: number) => void;
  newsContent?: any;
  setNewsContent?: any;
  featuredNews:any;
  getFeaturedNews:()=>void;
  //practices
  goodPractices: any[];
  //Remove old once v2 is implemented
  getGoodPractices: () => void;
  getGoodPractices2: () => void;
  saveGoodPracticeForm: any;
  getFilteredGoodPractices: (
    resourceType: any,
    dpto: any,
    municipio: any
  ) => void;
  innovativeExperiences: any[];
  getInnovativeExperiences: () => void; 
};
// https://stackoverflow.com/questions/61333188/react-typescript-avoid-context-default-value
const AppContext = createContext<AppContextType>({} as AppContextType);

function Provider({ children }: { children: React.ReactNode }) {
  //Home
  const [homeCourses, setHomeCourses] = useState([]);
  const [homeNews, setHomeNews] = useState([]);
  //News
  const [newsMedia, setNewsMedia] = useState([]);
  const [newsCategories, setNewsCategories] = useState([]);
  const [pressList, setPressList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [interestLinkContent, setInterestLinkContent] = useState(null);
  const [newsContent, setNewsContent] = useState(null);
  const [featuredNews,setFeaturedNews]=useState(null);
  //Practices
  const [goodPractices, setGoodPractices] = useState([]);
  //Innovative experiences
  const [innovativeExperiences, setInnovativeExperiences] = useState([]);

  const http = useHttp();
  const httpExternal = useHttpExternal();

  //Home
  const getHomeCourses = useCallback(async () => {
    const response: any = await http.get("/plantilla_curso", {});

    setHomeCourses(response);
  }, []);

  const getHomeNews = useCallback(async () => {
    const response: any = await http.get("/ext/noticiaPrensa/portada", {});
    setHomeNews(response);
  }, []);

  //Home news and Hemeroteca
  const getNewsMedia = useCallback(async () => {
    const response: any = await http.post("/medio/listado", {});

    setNewsMedia(response.medios);
  }, []);

  const getNewsCategories = useCallback(async () => {
    const response: any = await http.post("/categoria/listado", {});
    setNewsCategories(response.categorias);
  }, []);

  const getPressList = useCallback(async (queryParams: string) => {
    const response: any = await http.post("/prensa/listado" + queryParams, {});
    setPressList(response.prensa);
  }, []);

  const getNewsList = useCallback(async (queryParams: string) => {
    const response: any = await http.get("/ext/noticiaPrensa" + queryParams, {});
    setNewsList(response);
  }, []);

  const getInterestLinkContent = useCallback(async (id: number) => {
    const response: any = await http.get(`/ext/publicacion/prensa/${id}`, {});

    setInterestLinkContent(response);
  }, []);

  const getNewsContent = useCallback(async (id: number) => {
    const response: any = await http.get(`/ext/publicacion/noticia/${id}`, {});

    setNewsContent(response);
  }, []);

  const getGoodPractices = useCallback(async () => {
    const response: any = await httpExternal.post(
      "/externo/listadobuenaspracticas",
      {
        payload: {
          creado_por: 0,
        },
      }
    );

    setGoodPractices(response.buenas);
  }, []);

  const getGoodPractices2 = useCallback(async () => {

    const response: any = await http.get("/ext/iniciativa/buenaPractica", {});

    setGoodPractices(response);

  }, []);

  const getFeaturedNews=useCallback(async()=>{
    const response:any=await http.get("/ext/publicacion/noticia/destacada", {});
    setFeaturedNews(response);
  },[]);

  const saveGoodPracticeForm = useCallback(async (formData: any) => {
    // debugger;
    const response: any = await http
      .post("/ext/iniciativa", {
        payload: formData,
        withFiles: true,
      })

    return response;
  }, []);

  const getFilteredGoodPractices = useCallback(
    async (resourceType: any, dpto: any, municipio: any) => {
      
      let filters:string[] = [];

      if(resourceType)
        filters.push(`tipoRecurso=${resourceType}`);

      if(dpto)
        filters.push(`departamento_cod=${dpto}`);

      if(dpto && municipio)
        filters.push(`municipio_cod=${municipio}`);

      let filtersStr:string = "";
      
      if(filters && filters.length > 0) {
        filtersStr = `?${filters.join('&')}`
      }      

      const response: any = await http.get(`/ext/iniciativa/buenaPractica${filtersStr}`, {});

      setGoodPractices(response);
    },
    []
  );

  const getInnovativeExperiences = useCallback(async () => {
    
    const response: any = await http.get("/ext/iniciativa/experienciaInnovadora", {});
    setInnovativeExperiences(response);

    return response;

  }, []);

  // const useGetFeaturedGoodPractice = useCallback(
  //   async () => {
  //     const response: any = await http.get("/ext/iniciativa/buenaPractica/destacada", {});  
  //     return response;
  //   }, []);

  const valueToShare = {
    homeNews,
    getHomeNews,
    homeCourses,
    getHomeCourses,
    //news
    newsCategories,
    getNewsCategories,
    newsMedia,
    getNewsMedia,
    pressList,
    getPressList,
    newsList,
    getNewsList,
    getInterestLinkContent,
    interestLinkContent,
    setInterestLinkContent,
    getNewsContent,
    newsContent,
    setNewsContent,
    featuredNews,
    getFeaturedNews,
    goodPractices,
    getGoodPractices,
    getGoodPractices2,
    saveGoodPracticeForm,
    getFilteredGoodPractices,
    // useGetFeaturedGoodPractice,
    innovativeExperiences,
    getInnovativeExperiences
  };

  return (
    <AppContext.Provider value={valueToShare}>{children}</AppContext.Provider>
  );
}

export function useGetFeaturedGoodPractice(): () => Promise<any> {
  const http = useHttp();
  // const authContext = useAuthContext();
  return async () => {
    return await http.get(`/ext/iniciativa/buenaPractica/destacada`, {
      // auth: authContext?.user?.token ?? undefined,
    });
  };
}

export { Provider };
export default AppContext;
