import { Box, Button, Grid, Typography } from "@mui/material";
import { TipoPregunta, TipoPreguntaQuiz } from "../../../interface";
import bgSeleccionUnicaIcon from "./../../../../../assets/course/tipo-pregunta/seleccion_unica.png";
import bgSeleccionUnicaSelectedIcon from "./../../../../../assets/course/tipo-pregunta/seleccion_unica_hover.png";
import bgSeleccionMultipleIcon from "./../../../../../assets/course/tipo-pregunta/seleccion_multiple.png";
import bgSeleccionMultipleSelectedIcon from "./../../../../../assets/course/tipo-pregunta/seleccion_multiple_hover.png";
import bgSeleccionAbiertaIcon from "./../../../../../assets/course/tipo-pregunta/seleccion_abierta.png";
import bgSeleccionAbiertaSelectedIcon from "./../../../../../assets/course/tipo-pregunta/seleccion_abierta_hover.png";

type QuestionTypeSelectorProps = {
  tipoSeleccionado: TipoPreguntaQuiz | "";
  onchangeTipoPregunta: (tipo: TipoPreguntaQuiz) => void;
};

export function QuestionTypeSelector({
  tipoSeleccionado,
  onchangeTipoPregunta,
}: QuestionTypeSelectorProps) {
  const onSeleccion = (tipo: TipoPreguntaQuiz) => {
    if (tipoSeleccionado !== tipo) {
      onchangeTipoPregunta(tipo);
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container direction="column" spacing={2} justifyContent="center">
        <Grid item container spacing={2} justifyContent="center">
          <Grid item>
            <Box
              component={Button}
              onClick={() => onSeleccion(TipoPregunta.SELECCION_UNICA)}
              display={"flex"}
              gap={1}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box
                sx={{ borderRadius: "10px", overflow: "auto", display: "flex" }}
              >
                <img
                  src={
                    tipoSeleccionado === TipoPregunta.SELECCION_UNICA
                      ? bgSeleccionUnicaSelectedIcon
                      : bgSeleccionUnicaIcon
                  }
                  width={"100%"}
                  height={"100%"}
                  alt="unica-icon"
                />
              </Box>
              <Typography sx={{ color: "black", textTransform: "initial" }}>
                Selección única
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              component={Button}
              onClick={() => onSeleccion(TipoPregunta.SELECCION_MULTIPLE)}
              display={"flex"}
              gap={1}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box
                sx={{ borderRadius: "10px", overflow: "auto", display: "flex" }}
              >
                <img
                  src={
                    tipoSeleccionado === TipoPregunta.SELECCION_MULTIPLE
                      ? bgSeleccionMultipleSelectedIcon
                      : bgSeleccionMultipleIcon
                  }
                  width={"100%"}
                  height={"100%"}
                  alt="multiple-icon"
                />
              </Box>
              <Typography sx={{ color: "black", textTransform: "initial" }}>
                Selección múltiple
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              component={Button}
              onClick={() => onSeleccion(TipoPregunta.PREGUNTA_ABIERTA)}
              display={"flex"}
              gap={1}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box
                sx={{ borderRadius: "10px", overflow: "auto", display: "flex" }}
              >
                <img
                  src={
                    tipoSeleccionado === TipoPregunta.PREGUNTA_ABIERTA
                      ? bgSeleccionAbiertaSelectedIcon
                      : bgSeleccionAbiertaIcon
                  }
                  width={"100%"}
                  height={"100%"}
                  alt="abierta-icon"
                />
              </Box>
              <Typography sx={{ color: "black", textTransform: "initial" }}>
                Respuesta abierta
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
