import {Box, FormControlLabel, Grid, Switch, Typography} from "@mui/material";
import {AddRounded} from "@mui/icons-material";
import {MultipleSelectionEditor} from "./MultipleSelectionEditor";
import {UniqueSelectionEditor} from "./UniqueSelectionEditor";
import {QuestionTypeSelector} from "./QuestionTypeSelector";
import {PreguntaQuizModel} from "../../../types";
import {PreguntasHandlerProps, usePreguntasHandler,} from "../preguntaComponents/usePreguntasHandler";
import {EditorValoresComunes} from "../preguntaComponents/EditorValoresComunes";
import {EditorAdjunto} from "../preguntaComponents/EditorAdjunto";
import {TipoPregunta} from "../../../interface";
import {CircleBox, CoursePrimaryButtonText} from "../../../shared/Elements";

type QuizContentEditorProps = {
  isEvaluated: boolean;
  onEvaluatedChange: (value: boolean) => void;
  preguntas?: PreguntaQuizModel[];
  onPreguntasChange: (preguntas: PreguntaQuizModel[]) => void;
  onAdjuntoChange: (tempId: string, file: File) => void;
  onAdjuntoDelete: (tempId: string) => void;
};

export function QuizContentEditor(props: QuizContentEditorProps) {
  const preguntasHandlerProps: PreguntasHandlerProps<"QUIZ"> = {
    preguntas: props.preguntas,
    onPreguntasChange: props.onPreguntasChange,
    onAdjuntosChange: props.onAdjuntoChange,
    onAdjuntosDelete: props.onAdjuntoDelete,
  };

  const {
    preguntas,
    handleChangeTipoPregunta,
    handleChangePropPregunta,
    handleEliminarPregunta,
    handleSetAdjunto,
    handleSetPregunta,
    addPregunta,
  } = usePreguntasHandler("QUIZ", preguntasHandlerProps);

  return (
    <Box>
      <FormControlLabel control={<Switch defaultChecked={props.isEvaluated} onChange={(_, checked) => {props.onEvaluatedChange(checked)}} />} label="Habilitar Evaluacion automatica" />

      {preguntas.map((pregunta, indicePregunta: number) => {
        const { tipo } = pregunta;
        const updateTipo = handleChangeTipoPregunta(indicePregunta);
        const updateValor = handleChangePropPregunta(indicePregunta);
        const deletePregunta = handleEliminarPregunta(indicePregunta);
        const setAdjunto = handleSetAdjunto(indicePregunta);
        const deleteAdjunto = () => {
          setAdjunto(undefined);
        };
        const setPregunta = handleSetPregunta(indicePregunta);
        return (
          <Grid container alignItems="center" key={indicePregunta} mb={4}>
            <Grid item xs={12} mb={2}>
              <Typography sx={{ fontSize: "1.2rem" }}>
                Selecciona el tipo de pregunta
              </Typography>
            </Grid>
            <QuestionTypeSelector
              tipoSeleccionado={tipo}
              onchangeTipoPregunta={updateTipo}
            />

            {pregunta.tipo !== "" && (
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                rowSpacing={2}
                sx={{ marginTop: 3 }}
              >
                <Grid item xs={12}>
                  <Box mb={3}>
                    <EditorValoresComunes
                      indicePregunta={indicePregunta}
                      pregunta={pregunta}
                      onDelete={deletePregunta}
                      onUpdate={updateValor}
                    />
                    <EditorAdjunto
                      adjunto={pregunta.adjunto}
                      onAdjuntoChange={setAdjunto}
                      onDelete={deleteAdjunto}
                    />
                    {/* Lógica para preguntas tipo: SELECCION_UNICA */}
                    {pregunta.tipo === TipoPregunta.SELECCION_UNICA && (
                      <UniqueSelectionEditor
                        pregunta={pregunta}
                        onChangePregunta={setPregunta}
                      ></UniqueSelectionEditor>
                    )}

                    {/* Lógica para preguntas tipo: SELECCION_MULTIPLE */}
                    {pregunta.tipo === TipoPregunta.SELECCION_MULTIPLE && (
                      <MultipleSelectionEditor
                        pregunta={pregunta}
                        onPreguntaChange={setPregunta}
                      />
                    )}
                  </Box>
                </Grid>
                {indicePregunta === preguntas.length - 1 && (
                  <Grid item>
                    <CoursePrimaryButtonText onClick={addPregunta}>
                      <CircleBox sx={{ mr: 0.8 }}>
                        <AddRounded sx={{ fontSize: "14pt" }} />
                      </CircleBox>
                      Añadir pregunta
                    </CoursePrimaryButtonText>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        );
      })}
    </Box>
  );
}
