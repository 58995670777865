import { ArrowForwardIosRounded } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useMemo, useState } from "react";
import { InternCourse, extractUnits } from "../context";
import { UnidadVistaPrevia } from "./UnidadVistaPrevia";
import { CourseContentDialog } from "../shared/Elements";
import { printLog } from "../../../shared/utils";
import {Unidad} from "../interface";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type VistaPreviaProps = {
  onClose: () => void;
  open: boolean;
  plantillaCursoInterna: InternCourse;
};

export const VistaPrevia = ({
  open,
  plantillaCursoInterna,
  onClose,
}: VistaPreviaProps) => {
  const theme = useTheme();
  const plantillaCurso = useMemo(
    () => ({
      ...plantillaCursoInterna,
      unidades: extractUnits(plantillaCursoInterna.unidades),
    }),
    [plantillaCursoInterna]
  );
  const [unidad, setUnidad] = useState<Unidad | null>(null);

  const verUnidad = (_unidad: Unidad) => {
    printLog("ver unidad :: ", _unidad);
    setUnidad(_unidad);
  };

  const cerrarUnidad = () => {
    setUnidad(null);
  };

  const handleClose = () => {
    cerrarUnidad();
    setUnidad(null);
    onClose();
  };

  return (
    <CourseContentDialog
      fullScreen
      open={open}
      onClose={handleClose}
      ctitle="Vista previa del curso"
      withActions={false}
      TransitionComponent={Transition}
    >
      {!unidad && (
        <List>
          {/* { JSON.stringify(plantillaCurso) } */}
          {plantillaCurso !== null &&
            plantillaCurso.unidades.length > 0 &&
            plantillaCurso.unidades.map((unidad: Unidad, idxUnidad: number) => (
              <ListItem
                key={idxUnidad}
                onClick={() => verUnidad(unidad)}
              >
                <ListItemButton sx={{ borderBottom: `1px solid ${theme.palette.neutral.main}` }}>
                  <ListItemIcon>
                    <ArrowForwardIosRounded />
                  </ListItemIcon>
                  <ListItemText
                    primary={unidad.titulo}
                    secondary={unidad.objetivo}
                  />

                </ListItemButton>
              </ListItem>
            ))}
        </List>
      )}
      {unidad !== null && (
        <UnidadVistaPrevia unidad={unidad} onClose={cerrarUnidad} />
      )}
    </CourseContentDialog>
  );
};
