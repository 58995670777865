import { Box, Chip, Typography, styled } from "@mui/material";
import theme from "../../../../../../themes";
import listenIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico1_nomal.png";
import videoIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico4_nomal.png";
import photoIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico5_nomal.png";
import fileIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico3_nomal.png";
import { MunicipalityCardProps } from "../../../../../../types/MunicipalityCardTypes";
import moment from "moment";

const StyledBox = styled(Box)(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: '100%',
  height: '310px',
  maxHeight: '310px',
  borderBottom: `1px solid ${theme.palette.neutral.main}`,
  // TODO: mobile breakpoints
  [theme.breakpoints.up('xs')]: {
    maxHeight: 'fit-content',
    height: 'auto'
  },
  [theme.breakpoints.up('sm')]: {
    maxHeight: '360px',
    height: '360px'
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: '100%',
    height: 'auto'
  },
  [theme.breakpoints.up('lg')]: {
    maxHeight: '100%',
    height: 'auto',
  }
}));

const StyledImg = styled('img')(() => ({
  width: '100%',
  height: '75%',
  objectFit: 'contain',
  borderRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    height: '35%',
  }
}));

const StyledIcon = styled('img')(() => ({
  width: '24',
  height: '24',
  [theme.breakpoints.down('md')]: {
    width: '20',
    height: '20',
  }
}));

const MunicipalityCard: React.FC<MunicipalityCardProps> = ({ department, municipality, title, date, content, image, resourceType, resourceUrl, handleOpenModal, handleModalResource }) => {

  const openLink = () => {
    window.open(resourceUrl, '_blank')
  }

  const renderIcon = (resourceType:string) => {
    
    if (resourceType === "YOUTUBE")
      return <StyledIcon src={videoIcon} alt="video ico" />
    else if (resourceType === "SOUNDCLOUD")
      return <StyledIcon src={listenIcon} alt="audio ico" />
    else if(resourceType === "LINK_FOTOS")
      return <StyledIcon src={photoIcon} alt="link ico" />
    else if(resourceType === "LINK_ARCHIVO")
      return <StyledIcon src={fileIcon} alt="link ico" />
    else
      return null;

  }

  const renderLabel = (resourceType:string) => {
    
    if (resourceType === "YOUTUBE")
      return <Typography sx={{ fontFamily: 'Barlow', color: theme.palette.neutral.active, textTransform: 'uppercase' }} onClick={setModalValues}>
        Mira el video
      </Typography>
    else if (resourceType === "SOUNDCLOUD")
      return <Typography sx={{ fontFamily: 'Barlow', color: theme.palette.neutral.active, textTransform: 'uppercase' }} onClick={setModalValues}>
        Escucha el audio
      </Typography>
    else if(resourceType === "LINK_FOTOS")
      return <Typography sx={{ fontFamily: 'Barlow', color: theme.palette.neutral.active, textTransform: 'uppercase' }} onClick={openLink}>
        Mira las fotos
      </Typography>
    else if(resourceType === "LINK_ARCHIVO")
      return <Typography sx={{ fontFamily: 'Barlow', color: theme.palette.neutral.active, textTransform: 'uppercase' }} onClick={openLink}>
        Revisa el enlace
      </Typography>
  }

  const setModalValues = () => {
    if(handleOpenModal)
      handleOpenModal()
    if(handleModalResource)
      handleModalResource(resourceType, resourceUrl);
  }
  
  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          width: '65%',
          padding: '1.5em 1.5em 1.5em 1.5em',
          maxHeight: 'inherit',
          height: 'inherit',
          position: 'relative',
          [theme.breakpoints.down('sm')]: {
            width: '65%',
          }
        }}
      >
        <Box flex={1} sx={{ overflowY: 'hidden' }}>
          <Box display="flex">
            <Chip color="primary" label={department}
              sx={{
                color: '#FFFFFF',
                [theme.breakpoints.down('sm')]: {
                  fontSize: 10
                }
              }}
            />
            <Typography
              sx={{
                color: theme.palette.primary.main,
                marginLeft: { xs: '0.3em', md: '1.5em', },
                [theme.breakpoints.down('md')]: {
                  fontSize: '12px',
                  paddingTop: '5px'
                }
              }}
            >
              {municipality}
            </Typography>
          </Box>
          <Box my={'0.5em'}>
            <Typography sx={{ fontFamily: 'Barlow', fontSize: 22, color: '#54595F', fontWeight: 'bold' }}>
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography color="primary">
              {date ? moment(date).format("DD-MM-YYYY HH:mm") : ''}
            </Typography>
          </Box>
          <Box my={'0.5em'}>
            <Typography dangerouslySetInnerHTML={{ __html: `${content}` }} sx={{ fontFamily: 'Ubuntu', fontSize: { xs: 14, md: 16 } }} />
          </Box>
          { resourceType ? 
            <Box mt={'0.5em'}>
              <Box display="inline-flex" justifyContent="flex-start"
                onClick={resourceType === "YOUTUBE" || resourceType === "SOUNDCLOUD" ? setModalValues : openLink}
                sx={{ backgroundColor: "#dddddd", borderRadius: '16px' , cursor: 'pointer'}}
              >
                <Box display="flex" alignItems="center" sx={{ width: '100%' }} px={'12px'}>
                  {renderIcon(resourceType)}
                  {renderLabel(resourceType)}
                  
                </Box>
              </Box>
            </Box> : null
          }
        </Box>
      </Box>
      <Box
        display="flex"
        sx={{
          width: '35%',
          [theme.breakpoints.down('sm')]: {
            width: '35%',
          }
        }}
      >
        <Box
          display="inline-block"
          height={'100%'}
          width={'100%'}
          sx={{
            padding: { xs: '1.5em 1.5em 0 0', sm: '1.5em 0 0 0', md: '1.5em 0 0 0' },
          }}>

          <StyledImg src={image} alt="img" />

        </Box>
      </Box>
    </StyledBox>
  )
};

export default MunicipalityCard;