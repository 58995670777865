import { Box, Button, styled, Typography, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import theme from "../../../../themes";
import NewsCard from "./components/NewsCard/NewsCard";
import BigNews from "./components/BigNews";

import useAppContext from "../../../../shared/hooks/useAppContext";
import { useEffect, useState } from "react";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: "6em 0 6em 0",
  width: "100%",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  borderRadius: "8px",
  border: "1px solid #808080",
  width: "200px",
  height: "50px",
  color: "#808080",
  "&:hover": {
    backgroundColor: "#808080",
    color: "#E8E8E8",
  },
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
}));

const News = () => {
  const { homeNews, featuredNews } = useAppContext();
  let history = useHistory();

  const [trimedHomeNews, setTrimedHomeNews] = useState(Array<any>);
  const matchMd: boolean = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    let data = homeNews.filter((item) => {
      if (item.esNoticiaDestacada) return item.esNoticiaDestacada === false;
      else return item;
    });

    let dataSlice = data.slice(0, 3);

    setTrimedHomeNews(dataSlice);
  }, [homeNews]);

  const renderWideNews = () => {
    let data = trimedHomeNews;

    return (
      <Box
        display="flex"
        mt={"1.5em"}
        sx={{ height: "610px", maxHeight: "610" }}
      >
        <Box width="50%" sx={{ height: "610px", maxHeight: "610" }}>
          {featuredNews ? (
            <BigNews
              image={featuredNews ? featuredNews.urlImagen : ""}
              content={featuredNews ? featuredNews.titulo : ""}
              date={featuredNews ? featuredNews.creadoFecha : ""} //modificado debido a que fechaLegacyFormat no existe en el objeto
              clipLabel={"NOTICIA"}
              isInterestLink={false}
              code={featuredNews && parseInt(featuredNews.noticia_cod)}
              urlSufix={
                featuredNews && featuredNews.prensa_url
                  ? featuredNews.prensa_url
                  : ""
              }
              category={
                featuredNews && featuredNews.categoria
                  ? featuredNews.categoria
                  : ""
              }
              clipLabel2={
                featuredNews.esNoticiaSolidar === true ? "SOLIDAR SUIZA" : ""
              } //cover.tipo==='NOTICIA' && esto fue borrado debido a que el objeto no retorna un tipo
            />
          ) : null}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="50%"
          sx={{ height: "610px", maxHeight: "610" }}
        >
          {data
            ? data.map((item, index) => {
                const isPress =
                  item && item.tipo === "ENLACE_INTERES" ? true : false;
                const isSolidar =
                  item.tipo === "NOTICIA" && item.esNoticiaSolidar === true;

                return (
                  <Box display="flex" width="100%" key={index}>
                    <NewsCard
                      image={item.urlImagen}
                      content={item.titulo}
                      date={item.fechaIso}
                      clipLabel={isPress ? "ENLACE DE INTERÉS" : "NOTICIA"}
                      isInterestLink={isPress ? true : false}
                      code={
                        isPress
                          ? parseInt(item.codigoPrensa)
                          : parseInt(item.codigoNoticia)
                      }
                      urlSufix={isPress ? item.prensa_url : ""}
                      category={item.categoria}
                      clipLabel2={isSolidar ? "SOLIDAR SUIZA" : ""}
                    />
                  </Box>
                );
              })
            : null}
        </Box>
      </Box>
    );
  };

  const renderMobileNews = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ height: "810px", maxHeight: "910", padding: "0 0 0 1.5em" }}
      >
        <Box width="100%">
          {featuredNews ? (
            <NewsCard
              image={featuredNews ? featuredNews.urlImagen : ""}
              content={featuredNews ? featuredNews.titulo : ""}
              date={featuredNews ? featuredNews.creadoFecha : ""}
              clipLabel={"NOTICIA"}
              isInterestLink={false}
              code={featuredNews && parseInt(featuredNews.noticia_cod)}
              urlSufix={
                featuredNews && featuredNews.prensa_url
                  ? featuredNews.prensa_url
                  : ""
              }
              category={
                featuredNews && featuredNews.categoria
                  ? featuredNews.categoria
                  : ""
              }
              clipLabel2={
                featuredNews.esNoticiaSolidar === true ? "SOLIDAR SUIZA" : ""
              }
            />
          ) : null}
        </Box>
        {trimedHomeNews.map((item, index) => {
          const isPress = item && item.tipo === "ENLACE_INTERES" ? true : false;
          const isSolidar =
            item.tipo === "NOTICIA" && item.esNoticiaSolidar === true;

          return (
            <Box display="flex" width="100%" key={index}>
              <NewsCard
                image={item.urlImagen}
                content={item.titulo}
                date={item.fechaIso}
                clipLabel={isPress ? "ENLACE DE INTERÉS" : "NOTICIA"}
                isInterestLink={isPress ? true : false}
                code={
                  isPress
                    ? parseInt(item.codigoPrensa)
                    : parseInt(item.codigoNoticia)
                }
                //TODO: Update urlSufix
                urlSufix={isPress ? item.prensa_url : ""}
                category={item.categoria}
                clipLabel2={isSolidar ? "SOLIDAR SUIZA" : ""}
              />
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <StyledBox display="flex" justifyContent="center" style={{ width: "100%" }}>
      {/* <Box sx={{ width: { xs: '100%', md: theme.breakpoints.values.md, lg: theme.breakpoints.values.lg } }}> */}
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: { xs: "100%", md: "75%" }, height: "100%" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ padding: "0 1.5em 0 1.5em" }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Barlow",
                fontSize: 42,
                color: theme.palette.neutral.main,
              }}
            >
              Noticias
            </Typography>
          </Box>
          <Box>
            {/* <StyledButton variant="text" onClick={() => history.push('/hemeroteca')}> */}
            <StyledButton
              variant="text"
              onClick={() => history.push("/comunicacion#noticias")}
            >
              Ver todas
            </StyledButton>
          </Box>
        </Box>

        {matchMd ? renderMobileNews() : renderWideNews()}
      </Box>
      {/* </Box> */}
    </StyledBox>
  );
};

export default News;
