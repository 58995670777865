import { HomeItem } from "../../../../types/CarouselTypes";
import Slide0 from "../../../../assets/carousel/slide00.png";
//import Slide1 from "../../../../assets/carousel/slide01.jpg";
import Slide1 from "../../../../assets/carousel/new2.jpg";
import Slide2 from "../../../../assets/carousel/slide02.jpg";
//import Slide3 from "../../../../assets/carousel/slide03.jpg";
import Slide3 from "../../../../assets/carousel/new3.jpg";
import Slide4 from "../../../../assets/carousel/slide04.jpg";
import Slide5 from "../../../../assets/carousel/new1.jpg";
//import Slide5 from "../../../../assets/carousel/slide05.jpg";

export const items: HomeItem[] = [
  {
    title: "",
    caption: "",
    content: "",
    image: `${Slide0}`,
    noContent: true,
  },
  {
    title: "QUIENES SOMOS",
    caption: "Vida Digna Sin Violencia",
    content:
      "Es un proyecto que quiere contribuir al derecho a una vida libre de violencia para las mujeres, entendiendo que para ello es necesario el acceso y oportunidades de autonomía económica, salud sexual, y espacios de apoyo y protección.Es implementado por Solidar Suiza en Bolivia, con el apoyo de la Agencia Sueca de para el Desarrollo Internacional (Asdi).",
    image: `${Slide2}`,
    urlSection: "/quienes-somos",
  },
  {
    title: "EL CAMINO RECORRIDO",
    caption: "Nuestra historia",
    content:
      "Son más de 20 años de camino recorrido en contribuir a que las mujeres alcancen una vida libre de violencia. Ante los desafíos que nos plantea la problemática estructural de la violencia, continuamos trabajando con iniciativas municipales, en redes contra la violencia, compromisos de universidades, normativas y mandatos sociales, y esfuerzos innovadores de distintos actores.¡Sigamos avanzando hacia una vida digna sin violencia!",
    image: `${Slide1}`,
    urlSection: "/quienes-somos#nuestraHistoria",
  },
  {
    title: "REDES MUNICIPALES",
    caption: "Redes de lucha contra la violencia",
    content:
      "La Red es un espacio de coordinación en el municipio, donde actores públicos, sociedad civil (jóvenes, hombres y mujeres), privados y medios de comunicación, se unen y colaboran para asumir de forma corresponsable la prevención, atención y protección a las mujeres en situación de violencia, contribuyendo a que alcancen una vida digna.",
    image: `${Slide4}`,
    urlSection: "/quienes-somos#actores-clave",
  },
  {
    title: "NUESTROS CURSOS",
    caption: "Desarrollo de Capacidades",
    content:
      "Los cursos de la Comunidad virtual están diseñados para fortalecer las capacidades de las y los integrantes de la comunidad, servidoras y servidores públicos, jóvenes EduActivistas, mujeres, comunicadoras y comunicadores locales, entre otros.",
    image: `${Slide5}`,
    urlSection: "/catalogo-cursos",
  },
  {
    title: "BUENAS PRÁCTICAS",
    caption: "Gestión del conocimiento",
    content:
      "Este es un espacio donde los municipios a través de las Redes de Lucha contra Violencia, las organizaciones de mujeres que plantean sus propuestas, los hombres que trabajan con otros hombres para cambios culturales, y las y los EduActivistas que desarrollan acciones de prevención, podrán compartir sus buenas prácticas e inspirar a otras y otros para que las repliquen.",
    image: `${Slide3}`,
    urlSection: "/buenas-practicas",
  },
];
