import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  SelectChangeEvent,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import {colorsBase, DesignSystem} from "../../../themes/theme";
import React, {useState} from "react";
import {useCourseReport, useCourses, useDepartamentos} from "../ReporteClient";
import {ReporteCursosQueryParams} from "../ReporteApiModel";
import {FilterButton} from "../../../shared/components/CButton";
import {ReactComponent as FilterIcon} from "../../../assets/icons/filter.svg";
import CSelectForm from "../../../shared/components/form/CSelectForm";
import CDatePicker from "../../../shared/components/form/CDatePicker";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${colorsBase.Texto2}`,
  [`&.${tableCellClasses.head}`]: {
    ...DesignSystem?.titulo3,
    color: colorsBase.Texto2,
    padding: 4,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const nivelesEducativos = [
  { value: "NINGUNO", label: "Ninguno" },
  {
    value: "PRIMARIA",
    label: "Educación primaria",
  },
  {
    value: "SECUNDARIA",
    label:
      "Educación secundaria",
  },
  {
    value: "SUPERIOR_INCOMPLETO",
    label:
      "Superior incompleto",
  },
  {
    value: "SUPERIOR_COMPLETO",
    label:
      "Superior completo",
  },
] as const;

export const ReporteCursos = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [showFilters, setShowFilters] = useState(false);

  const [queryParams, setQueryParams] = useState<ReporteCursosQueryParams>({
    pageSize: 20,
    pageNumber: 0,
    courseId: undefined,
    startDate: undefined,
    endDate: undefined,
    hasCertificate: undefined,
    departamentoCod: undefined
  })

  const { data, status, isLoading} = useCourseReport(queryParams);

  const { data: departamentosResponse } = useDepartamentos();
  const municipios = (queryParams.departamentoCod && departamentosResponse &&
    departamentosResponse?.departamentos?.find(dpto => dpto.departamento_cod === queryParams.departamentoCod)?.municipios)
    || [];

  const { data: coursesResponse} = useCourses();
  const courseSelectorItems = coursesResponse?.map(course => ({value: course.id, label: course.nombre}))

  // const getReporteInscritosExcel = useGetReporteInscritosExcel();

  // async function downloadReporteInscritosExcel() {
  //   //const filterValues = getFilterValues();
  //   //printLog('Valores de form :: ', filterValues)
  //   await getReporteInscritosExcel({
  //     //...filterValues
  //   });
  // }

  const handleChangePage = (_: any, newPageNumber: number) => {
    setQueryParams(prevState => ({
      ...prevState,
      pageNumber: newPageNumber
    }))
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = parseInt(event.target.value);
    setQueryParams(prevState => ({
      ...prevState,
      pageNumber: 0,
      pageSize: newPageSize
    }))
  };

  return <>
    <Box py={1} display="flex" justifyContent="space-between" alignItems="center">
      <Tooltip title="Ver filtros">
        <FilterButton onClick={() => setShowFilters(!showFilters)}>
          <FilterIcon />
        </FilterButton>
      </Tooltip>
      {/*<Box>*/}
      {/*  {<Tooltip title="Descargar reporte en formato PDF">*/}
      {/*    <FilterButton*/}
      {/*      size='small'*/}
      {/*      sx={{ mx: 1, color: `${colorsBase.Naranja1} !important` }}*/}
      {/*    >*/}
      {/*      <FilePdfIcon style={{ width: 25, fill: colorsBase.Naranja1, marginRight: 2 }} />*/}
      {/*      Descargar PDF*/}
      {/*    </FilterButton>*/}
      {/*  </Tooltip>*/}
      {/*  <Tooltip title="Descargar reporte en formato Excel">*/}
      {/*    <FilterButton*/}
      {/*      size='small'*/}
      {/*      sx={{ mx: 1, color: `${colorsBase.Verde2} !important` }}*/}
      {/*      onClick={downloadReporteInscritosExcel}*/}
      {/*    >*/}
      {/*      <FilePdfIcon style={{ width: 25, fill: colorsBase.Verde2, marginRight: 2 }} />*/}
      {/*      Descargar Excel*/}
      {/*    </FilterButton>*/}
      {/*  </Tooltip>*/}
      {/*</Box>*/}
    </Box>
    <Box my={0}>
      {showFilters && departamentosResponse && (
        <>
          <Grid container alignItems={"center"} spacing={2} p={0} mb={2} >
            <Grid display={{xs:"none", md:"block"}} item md={2}>
              <Typography>Filtrar por Rango de Fechas: </Typography>
            </Grid>
            <Grid container item xs={12} md
                  alignItems={{xs:"start",md:"center"}}
                  flexDirection={{xs:"column", md:"row"}}
            >
              <Grid item display={{xs:"block", md:"none"}}>
                <Typography>Desde:</Typography>
              </Grid>
              <Grid item md={5} lg={3}>
                <CDatePicker
                  placeholder="Desde"
                  value={queryParams.startDate || null}
                  onChange={date => setQueryParams(prevState => {
                    const newDate = (dayjs(date).isValid() && dayjs(date).year() >= 2022)
                      ? dayjs(date).format("YYYY-MM-DD")
                      : undefined;
                    return ({...prevState, startDate: newDate});
                  })}
                  sx={{
                    backgroundColor: "#dedede",
                    "& input::placeholder": {
                      color: theme.palette.neutral.active,
                    },
                  }}
                />
              </Grid>
              <Grid item display={{xs:"block", md:"none"}}>
                <Typography>Hasta:</Typography>
              </Grid>
              <Grid item md={1} display={{xs:"none", md:"block"}}>
                <Typography align="center"> - </Typography>
              </Grid>
              <Grid item md={5} lg={3} >
                <CDatePicker
                  placeholder="Hasta"
                  value={queryParams.endDate || null}
                  onChange={date => setQueryParams(prevState => {
                    const newDate = (dayjs(date).isValid() && dayjs(date).year() >= 2022)
                      ? dayjs(date).format("YYYY-MM-DD")
                      : undefined;
                    return ({...prevState, endDate: newDate});
                  })}
                  sx={{
                    backgroundColor: "#dedede",
                    "& input::placeholder": {
                      color: theme.palette.neutral.active,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} spacing={2} p={0} mb={2}>
            <Grid item xs={12} md={2}>
              <Typography>Otros Filtros</Typography>
            </Grid>
            <Grid item >
              <CSelectForm
                placeholder="Curso"
                fullWidth
                value={String(queryParams.courseId ?? "")}
                items={courseSelectorItems}
                onChange={(event: SelectChangeEvent) => {
                  setQueryParams(prevState => ({
                    ...prevState,
                    courseId: event.target.value
                  }))
                }}
              />
            </Grid>
            <Grid item >
              <CSelectForm
                placeholder="Certificado"
                fullWidth
                value={String(queryParams.hasCertificate ?? "")}
                items={[{value: "true", label: "Con Certificado"}, {value: "false", label: "Sin Certificado"}]}
                onChange={(event: SelectChangeEvent) => {
                  setQueryParams(prevState => ({
                    ...prevState,
                    hasCertificate: event.target.value === "true"
                  }))
                }}
              />
            </Grid>
            <Grid item >
              <CSelectForm
                placeholder="Departamento"
                fullWidth
                value={String(queryParams.departamentoCod ?? "")}
                items={[
                  ...departamentosResponse.departamentos.map((item) => ({
                    value: item.departamento_cod,
                    label: item.nombre,
                  })),
                ]}
                onChange={(event: SelectChangeEvent) => {
                  setQueryParams(prevState => ({
                    ...prevState,
                    departamentoCod: Number(event.target.value),
                    municipioCod: undefined
                  }))
                }}
              />
            </Grid>
          </Grid>
          <Box mb={2}>
            <Button
              variant="outlined"
              //size={isMobile ? "small" : "medium"}
              sx={{ borderRadius: "10px", }}
              onClick={() => {
                setQueryParams(prevState => ({
                  ...prevState,
                  departamentoCod: undefined,
                  hasCertificate: undefined,
                  startDate: undefined,
                  endDate: undefined,
                  courseId: undefined
                }))
              }}
            >
              Limpiar
            </Button>
          </Box>
        </>
      )}
    </Box>
    {isLoading && <CircularProgress />}
    {status === "success" && <Paper elevation={0}>
      <TableContainer component={Box}>
        <Table sx={{
          minWidth: 700,
          tableLayout: 'auto',
          p: 0, m: 0
        }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Nro</StyledTableCell>
              <StyledTableCell align="center">Nombres</StyledTableCell>
              <StyledTableCell align="center">Primer Apellido</StyledTableCell>
              <StyledTableCell align="center">Segundo Apellido</StyledTableCell>
              <StyledTableCell align="center">Correo electrónico</StyledTableCell>
              <StyledTableCell align="center">Departamento</StyledTableCell>
              <StyledTableCell align="center">Municipio</StyledTableCell>
              <StyledTableCell align="center">Institución</StyledTableCell>
              <StyledTableCell align="center">Cursos incritos</StyledTableCell>
              <StyledTableCell align="center">Porcentaje de avance</StyledTableCell>
              <StyledTableCell align="center">Certificación</StyledTableCell>
              <StyledTableCell align="center">Fecha de inscripción</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { data?.content?.map((row, idxRow) => (
              <StyledTableRow key={row.enrolmentId}>
                <StyledTableCell align="center">
                  {idxRow + 1}
                </StyledTableCell>
                <StyledTableCell align="left">{row.participantName}</StyledTableCell>
                <StyledTableCell align="left">{row.participantLastName1}</StyledTableCell>
                <StyledTableCell align="left">{row.participantLastName2}</StyledTableCell>
                <StyledTableCell align="left">{row.participantEmail}</StyledTableCell>
                <StyledTableCell align="left">{row.participantDepartamentoDesc}</StyledTableCell>
                <StyledTableCell align="left">{row.municipioDesc}</StyledTableCell>
                <StyledTableCell align="left">{row.participantInstitution}</StyledTableCell>
                <StyledTableCell align="left">{row.courseName}</StyledTableCell>
                <StyledTableCell align="left">{row.unitProgressPercentage}</StyledTableCell>
                <StyledTableCell align="left">{row.enrolmentHasCertificate ? "Si" : "No"}</StyledTableCell>
                <StyledTableCell align="left">{dayjs(row.enrolmentDate).format("DD/MM/YYYY")}</StyledTableCell>
              </StyledTableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        labelRowsPerPage={
          <Typography
            component={"span"}
            sx={{ ...DesignSystem?.cuerpo3, [theme.breakpoints.down('sm')]: {
                ...DesignSystem?.cuerpo4
              } }}
          >
            Mostrar
          </Typography>
        }
        labelDisplayedRows={({ from, to, count }) => (
          <Typography
            component={"span"}
            sx={{ ...DesignSystem?.cuerpo3, [theme.breakpoints.down('sm')]: {
                ...DesignSystem?.cuerpo4
              } }}
          >
            Mostrando {from} a {to} de {count} filas
          </Typography>
        )}
        component="div"
        count={data?.pagination?.totalElements}
        rowsPerPage={data?.pagination?.pageSize}
        page={data?.pagination?.pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>}
  </>
}
