import {QuizFeedback} from "./data/StudentCourseApiModels";
import {Alert, Box, Button, Popover, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Alerts} from "../../../shared/hooks/useAlert";

type Props = {
    feedbackData: QuizFeedback;
    onRetry: () => void;
}

export function QuizFeedbackViewer({feedbackData, onRetry}: Props) {
    useEffect(() => {
        if (feedbackData.isApproved) {
            Alerts.showSuccess({
                title: "APROBADO",
                description: "¡Felicidades! Has superado el exámen con gran éxito.",
                confirmText: "REVISAR"
            })
        } else {
            Alerts.showWarning({
                title: "NO ALCANZASTE",
                description: "El aprendizaje viene con la práctica. ¡Vuelve a intentarlo y demuestra tu progreso!",
                confirmText: "REVISAR"
            })
        }
    }, []);

    // popover logic
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentPopover, setCurrentPopover] = useState<number | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        setAnchorEl(event.currentTarget);
        setCurrentPopover(index)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setCurrentPopover(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return <>
        <Stack spacing={2}>
            {feedbackData.questionDetails.map(({question, answerWasCorrect, givenAnswer, expectedAnswer}, i) => (
                <Box>
                    <Typography variant={"h6"}>
                        {question}
                    </Typography>
                    {answerWasCorrect && <Alert severity="success"> {givenAnswer} </Alert>}
                    {!answerWasCorrect && (
                        <Alert severity="error"
                               action={
                                   <Button color="inherit" size="medium" onClick={event => handleClick(event, i)}>
                                       Ver Respuesta Correcta
                                   </Button>
                               }
                        > {givenAnswer} </Alert>)
                    }
                    <Popover
                        id={id}
                        open={open && i === currentPopover}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{p: 2}}>{expectedAnswer}</Typography>
                    </Popover>
                </Box>
            ))}
        </Stack>
        {!feedbackData.isApproved && <Stack>
            <Button sx={{mt: 6}} variant="contained" size="large" onClick={onRetry}>
                Reintentar
            </Button>
        </Stack>}
    </>
}

