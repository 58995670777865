import { Box, Typography, styled } from "@mui/material";
import theme from "../../../../../../themes";
import { ResourceItemProps } from '../../../../../../types/ResourceItemType';


const StyledImg = styled('img')(() => ({
  borderRadius: '8px',
  width: "42px",
  height: "42px",
  [theme.breakpoints.down('md')]: {
    width: "40px",
    height: "40px",
  }
}));

const ResourceItem: React.FC<ResourceItemProps> = ({ icon, hoverIcon, text, handleSetResource, resource, tipoRecurso, handleSetFilterResource }) => {

  const handleSetParentResource = () => {
    if (text === resource) {
      handleSetResource("");
      handleSetFilterResource("");
    } else {
      handleSetResource(text);
      handleSetFilterResource(tipoRecurso);
    }
  }

  return (
    <Box 
      display="flex" 
      alignItems="center" 
      sx={{ width: '50%' }}
      onClick={handleSetParentResource}
    >
      <StyledImg
        src={text === resource ? hoverIcon : icon}
        alt="l icon"
      />
      <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: { xs: 16 }, color: theme.palette.neutral.active }} ml={'0.5em'}>
        {text}
      </Typography>
    </Box>
  )
};

export default ResourceItem;