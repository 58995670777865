import { Box, Typography, styled } from "@mui/material";
import theme from "../../../../themes";
import networkImg from "../../../../assets/WhoWeAre/network_banner.jpg";

const StyledBox = styled(Box)(() => ({
  margin: "6em 0 0 0",
  padding: 0,
  width: "100%",
  height: "70vh",
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url(${networkImg})`,
  backgroundAttachment: "fixed",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 6em 3em 1.5em",
  backgroundColor: "rgb(74,12,85)",
  opacity: "0.8",
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const NetworksBanner = () => {
  return (
    <StyledBox
      display="flex"
      justifyContent="center"
      id="redesDeLuchaContraLaViolencia"
    >
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledDivMediaCaption>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{
              height: "100%",
              margin: 0,
              padding: "0 1.5em 0 1.5em",
              position: "absolute",
              left: 0,
            }}
          >
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                fontFamily: "Barlow",
                fontSize: 22,
                color: "#e6ac0c",
              }}
            >
              Fondos Concursables
            </Typography>
            <Typography
              variant="h1"
              sx={{
                mt: ".3em",
                fontFamily: "Barlow",
                fontSize: 42,
                color: "#FFFFFF",
                lineHeight: "1",
              }}
            >
              Por una Vida Digna Sin Violencia para las Mujeres
            </Typography>
            <Typography
              sx={{
                mt: "1.5em",
                fontFamily: "Ubuntu",
                fontSize: 16,
                color: "#FFFFFF",
              }}
            >
              En el trabajo que ejercemos a favor de erradicar la violencia contra las mujeres, contamos con el esfuerzo y trabajo
              de instituciones públicas, organizaciones de la sociedad civil, medios de comunicación locales, universidades y 
              ciudadanos comprometidos con esta temática.
            </Typography>
          </Box>
        </StyledDivMediaCaption>
      </Box>
    </StyledBox>
  );
};

export default NetworksBanner;
