import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import theme from "../../../../themes";
import ExperienceCard from "./components/ExperienceCard";
import img1 from '../../../../assets/innovativeExperiences/img_1.jpg';
import img2 from '../../../../assets/innovativeExperiences/img_2.jpg';
import img3 from '../../../../assets/innovativeExperiences/img_33.png';
import { useHistory } from "react-router-dom";
import useAppContext from "../../../../shared/hooks/useAppContext";
import { useEffect, useState } from "react";
import ResourceModal from "../ResourceModal";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: '100%'
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Barlow',
  fontSize: 14,
  border: '1px solid #808080',
  borderRadius: '8px',
  color: '#808080',
  width: '200px',
  height: '50px',
  '&:hover': {
    backgroundColor: '#808080',
    color: '#E8E8E8',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

const WhiteExperiencesArea = () => {

  let history = useHistory();
  const { innovativeExperiences } = useAppContext();

  //Data to display
  const [slicedExperiences, setSlicedExperiences] = useState([]);
  // Resource modal
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [modalResourceType, setModalResourceType] = useState('');
  const [modalUrl, setModalUrl] = useState<string>('');

  useEffect(()=>{
    
    let tmpArray:any = innovativeExperiences.slice(0, 4);
    setSlicedExperiences(tmpArray);

  }, [innovativeExperiences])

  const handleOpenModal = () => {
    setIsResourceModalOpen(true);
  };

  const handleModalResource = (resourceType:string, modalUrl:string) =>{
    setModalResourceType(resourceType);
    setModalUrl(modalUrl);
  }

  const handleCloseModal = () => {
    setIsResourceModalOpen(false);
  };
  console.log("escurri",slicedExperiences);
  return (
    <StyledBox display="flex" justifyContent="center" id="maxExperiencias">
      <Box sx={{ width: {xs:'100%', md:'75%'}, height: '100%', margin: 0, padding: 0 }}>
        <Box display="flex" flexDirection="column" style={{ width: '100%' }} sx={{ padding: '3em 1.5em 1.5em 1.5em' }}>
          <Box sx={{ width: '100%' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
              pt={3}
              pb={10}
            >
              <Typography sx={{ fontFamily: 'Barlow', fontSize: 42, lineHeight: '1', color: theme.palette.neutral.main }}>
                Conoce más experiencias
              </Typography>
              <StyledButton variant="text" onClick={()=> history.push('/experiencias-innovadoras-todas')}>
                Ver todas
              </StyledButton>
            </Stack>
          </Box>
          <Grid container justifyContent="space-between" spacing={2} sx={{ width: '100%' }}>
            { slicedExperiences.map((experience:any, index) => {
              
                return(
                  <Grid item xs={12} md={4} mt={{ xs: '1.5em', md: 0 }} my={'1.5em'} key={index}>
                    <ExperienceCard 
                      title={experience.titulo ? experience.titulo : ''}
                      //TODO retornar imagen guardada 
                      image={experience.stockImageUrl ? experience.stockImageUrl : img1} 
                      dpto={experience.departamento_desc ? experience.departamento_desc : ''}
                      mcp={experience.municipio_desc?experience.municipio_desc:""}
                      text={experience.descripcion ? experience.descripcion : ''} 
                      customHeight={515} 
                      resourceType={experience.tipoRecurso ? experience.tipoRecurso : ''}
                      resourceUrl={experience.recurso ? experience.recurso : ''}
                      handleOpenModal={handleOpenModal}
                      handleModalResource = {handleModalResource} 
                    />
                  </Grid>      
                );
              })
            }
          </Grid>
        </Box>
      </Box>
      {isResourceModalOpen && (
        <ResourceModal
          open={isResourceModalOpen}
          onClose={handleCloseModal}
          modalResourceType={modalResourceType}
          modalUrl={modalUrl}
        />
      )}
    </StyledBox>
  )
};

export default WhiteExperiencesArea;