import { Settings } from "../../../../interfaces/CarouselBannerProps";

const MobileSettings: Settings = {
  autoPlay: true,
  animation: "slide",
  indicators: true,
  duration: 500,
  navButtonsAlwaysVisible: false,
  navButtonsAlwaysInvisible: true,
  cycleNavigation: true,
  fullHeightHover: true,
  swipe: true,
};

export default MobileSettings;
