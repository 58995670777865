import { Settings } from "../../../../interfaces/CarouselBannerProps";

export const DefaultSettings: Settings = {
  autoPlay: true,
  animation: "slide",
  indicators: false,
  duration: 500,
  navButtonsAlwaysVisible: true,
  navButtonsAlwaysInvisible: false,
  cycleNavigation: true,
  fullHeightHover: true,
  swipe: true,
};
