import Layout from "../../components/Layout";
import HomeCarousel from "./components/HomeCarousel";
// import KeyActors from './components/KeyActors';
import CourseCarousel from "./components/CourseCarousel";
import MunicipalPractices from "./components/MunicipalPractices";
import { useEffect } from "react";
import SolidarContent from "./components/SolidarContent";
import HistoryTimeline from "./components/HistoryTimeline";
import AboutUs from "./components/AboutUs";
import Footer from "../../components/Footer";
import News from "./components/News";
import useAppContext from "../../shared/hooks/useAppContext";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const Home = () => {
  const { pathname, hash, key } = useLocation();
  const { getHomeNews, getHomeCourses, getFeaturedNews } = useAppContext();

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [pathname, hash, key]);

  useEffect(() => {
    getHomeNews();
    getHomeCourses();
    getFeaturedNews();
    // getPressList();
    // getNewsList();
  }, [getHomeCourses, getHomeNews, getFeaturedNews]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home - Inicio",
    });
  }, []);

  return (
    <Layout>
      <HomeCarousel />
      <AboutUs />
      {/* <KeyActors /> */}
      <CourseCarousel />
      <MunicipalPractices />
      <News />
      <SolidarContent />
      <HistoryTimeline />
      <Footer />
    </Layout>
  );
};

export default Home;
