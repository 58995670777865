import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  SelectChangeEvent,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material"
import {colorsBase, DesignSystem} from "../../../themes/theme";
import {useState} from "react";
import {useDepartamentos, useGetReporteInscritosExcel, useReporteInscritos} from "../ReporteClient";
import dayjs from 'dayjs';
import {ReporteInscritosQueryParams} from "../ReporteApiModel";
import {FilterButton} from "../../../shared/components/CButton";
import {ReactComponent as FilterIcon} from "../../../assets/icons/filter.svg";
import CSelectForm from "../../../shared/components/form/CSelectForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${colorsBase.Texto2}`,
  [`&.${tableCellClasses.head}`]: {
    ...DesignSystem?.titulo3,
    color: colorsBase.Texto2,
    padding: 4,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const nivelesEducativos = [
  { value: "NINGUNO", label: "Ninguno" },
  {
    value: "PRIMARIA",
    label: "Educación primaria",
  },
  {
    value: "SECUNDARIA",
    label:
      "Educación secundaria",
  },
  {
    value: "SUPERIOR_INCOMPLETO",
    label:
      "Superior incompleto",
  },
  {
    value: "SUPERIOR_COMPLETO",
    label:
      "Superior completo",
  },
] as const;

export const ReporteInscritos = () => {
  const theme = useTheme();

  const [viewFilters, setViewFilters] = useState(false);

  const [queryParams, setQueryParams] = useState<ReporteInscritosQueryParams>({
    pageSize: 20,
    pageNumber: 0,
    nivelEducativo: undefined,
    departamentoCod: undefined,
    municipioCod: undefined
  })

  const { data, status, isLoading} = useReporteInscritos(queryParams);

  const { data: departamentosResponse } = useDepartamentos();
  const municipios = queryParams.departamentoCod && departamentosResponse &&
    departamentosResponse?.departamentos?.find(dpto => dpto.departamento_cod === queryParams.departamentoCod)?.municipios
    || []

  const getReporteInscritosExcel = useGetReporteInscritosExcel();

  async function downloadReporteInscritosExcel() {
    //const filterValues = getFilterValues();
    //printLog('Valores de form :: ', filterValues)
    await getReporteInscritosExcel({
      //...filterValues
    });
  }

  const handleChangePage = (_: any, newPageNumber: number) => {
    setQueryParams(prevState => ({
      ...prevState,
      pageNumber: newPageNumber
    }))
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = parseInt(event.target.value);
    setQueryParams(prevState => ({
      ...prevState,
      pageNumber: 0,
      pageSize: newPageSize
    }))
  };

  return <>
    <Box py={1} display="flex" justifyContent="space-between" alignItems="center">
      <Tooltip title="Ver filtros">
        <FilterButton onClick={() => setViewFilters(!viewFilters)}>
          <FilterIcon />
        </FilterButton>
      </Tooltip>
      {/*<Box>*/}
      {/*  {<Tooltip title="Descargar reporte en formato PDF">*/}
      {/*    <FilterButton*/}
      {/*      size='small'*/}
      {/*      sx={{ mx: 1, color: `${colorsBase.Naranja1} !important` }}*/}
      {/*    >*/}
      {/*      <FilePdfIcon style={{ width: 25, fill: colorsBase.Naranja1, marginRight: 2 }} />*/}
      {/*      Descargar PDF*/}
      {/*    </FilterButton>*/}
      {/*  </Tooltip>*/}
      {/*  <Tooltip title="Descargar reporte en formato Excel">*/}
      {/*    <FilterButton*/}
      {/*      size='small'*/}
      {/*      sx={{ mx: 1, color: `${colorsBase.Verde2} !important` }}*/}
      {/*      onClick={downloadReporteInscritosExcel}*/}
      {/*    >*/}
      {/*      <FilePdfIcon style={{ width: 25, fill: colorsBase.Verde2, marginRight: 2 }} />*/}
      {/*      Descargar Excel*/}
      {/*    </FilterButton>*/}
      {/*  </Tooltip>*/}
      {/*</Box>*/}
    </Box>
    <Box my={0}>
      {viewFilters && departamentosResponse && (
        <Grid container alignItems={"center"} spacing={2} p={0} mb={2}>
          <Grid item >
            <CSelectForm
              placeholder="Selecciona un departamento"
              fullWidth
              value={String(queryParams.departamentoCod ?? "")}
              items={[
                ...departamentosResponse.departamentos.map((item) => ({
                  value: item.departamento_cod,
                  label: item.nombre,
                })),
              ]}
              onChange={(event: SelectChangeEvent) => {
                setQueryParams(prevState => ({
                  ...prevState,
                  departamentoCod: Number(event.target.value),
                  municipioCod: undefined
                }))
              }}
            />
          </Grid>
          <Grid item>
            <CSelectForm
              placeholder="Selecciona un municipio"
              fullWidth
              value={String(queryParams.municipioCod ?? "")}
              items={municipios.map((item) => ({
                value: item.municipio_cod,
                label: item.municipio_desc,
              }))}
              onChange={(event: SelectChangeEvent) => {
                setQueryParams(prevState => ({
                  ...prevState,
                  municipioCod: Number(event.target.value),
                }))
              }}
            />
          </Grid>
          <Grid item>
            <CSelectForm
              placeholder={"Selecciona el nivel educativo"}
              fullWidth
              items={[...nivelesEducativos]}
              value={queryParams.nivelEducativo ?? ""}
              onChange={(event: SelectChangeEvent) => {
                setQueryParams(prevState => ({
                  ...prevState,
                  nivelEducativo: event.target.value
                }))
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              //size={isMobile ? "small" : "medium"}
              sx={{ borderRadius: "10px" }}
              onClick={() => {
                setQueryParams(prevState => ({
                  ...prevState,
                  departamentoCod: undefined,
                  municipioCod: undefined,
                  nivelEducativo: undefined
                }))
              }}
            >
              Limpiar
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
    {isLoading && <CircularProgress />}
    {status === "success" && <Paper elevation={0}>
      <TableContainer component={Box}>
        <Table sx={{
          minWidth: 700,
          tableLayout: 'auto',
          p: 0, m: 0
        }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Nro</StyledTableCell>
              <StyledTableCell align="center">Nombres</StyledTableCell>
              <StyledTableCell align="center">Primer Apellido</StyledTableCell>
              <StyledTableCell align="center">Segundo Apellido</StyledTableCell>
              <StyledTableCell align="center">Correo Electronico</StyledTableCell>
              <StyledTableCell align="center">Edad</StyledTableCell>
              <StyledTableCell align="center">Celular</StyledTableCell>
              <StyledTableCell align="center">Departamento</StyledTableCell>
              <StyledTableCell align="center">Municipio</StyledTableCell>
              <StyledTableCell align="center">Sexo</StyledTableCell>
              <StyledTableCell align="center">Estado Civil</StyledTableCell>
              <StyledTableCell align="center">Tiene Hijos</StyledTableCell>
              <StyledTableCell align="center">Numero de Hijos</StyledTableCell>
              <StyledTableCell align="center">Nivel Educativo</StyledTableCell>
              <StyledTableCell align="center">Institucion</StyledTableCell>
              <StyledTableCell align="center">Cargo</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { data?.content?.map((row, idxRow) => (
                <StyledTableRow key={idxRow}>
                  <StyledTableCell align="center">
                    {idxRow + 1}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.nombres}</StyledTableCell>
                  <StyledTableCell align="left">{row.primerApellido}</StyledTableCell>
                  <StyledTableCell align="left">{row.segundoApellido}</StyledTableCell>
                  <StyledTableCell align="left">{row.email}</StyledTableCell>
                  <StyledTableCell align="left">{dayjs().diff(dayjs(row.birthdate), "year")}</StyledTableCell>
                  <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                  <StyledTableCell align="left">{row.departamentoDesc}</StyledTableCell>
                  <StyledTableCell align="left">{row.municipioDesc}</StyledTableCell>
                  <StyledTableCell align="left">{row.sexo}</StyledTableCell>
                  <StyledTableCell align="left">{row.estadoCivil}</StyledTableCell>
                  <StyledTableCell align="left">{row.numeroHijos === 0 ? "No" : "Si"}</StyledTableCell>
                  <StyledTableCell align="left">{row.numeroHijos}</StyledTableCell>
                  <StyledTableCell align="left">{nivelesEducativos.find(nivel => nivel.value === row.nivelEducativo)?.value}</StyledTableCell>
                  <StyledTableCell align="left">{row.institution}</StyledTableCell>
                  <StyledTableCell align="left">{row.position}</StyledTableCell>
                  {/*<StyledTableCell align="center">{dayjs(row.fechaInscripcion).format('DD/MM/YYYY')}</StyledTableCell>*/}
                </StyledTableRow>
              )) }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        labelRowsPerPage={
          <Typography
            component={"span"}
            sx={{ ...DesignSystem?.cuerpo3, [theme.breakpoints.down('sm')]: {
              ...DesignSystem?.cuerpo4
            } }}
          >
            Mostrar
          </Typography>
        }
        labelDisplayedRows={({ from, to, count }) => (
          <Typography
            component={"span"}
            sx={{ ...DesignSystem?.cuerpo3, [theme.breakpoints.down('sm')]: {
              ...DesignSystem?.cuerpo4
            } }}
          >
            Mostrando {from} a {to} de {count} filas
          </Typography>
        )}
        component="div"
        count={data?.pagination?.totalElements}
        rowsPerPage={data?.pagination?.pageSize}
        page={data?.pagination?.pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>}
  </>
}
