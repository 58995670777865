import * as React from "react";
import {
  Select,
  SelectProps,
  selectClasses,
  Option,
  optionClasses,
  Popper,
} from "@mui/base";
// import Popper from '@mui/base/Popper';
import { alpha } from "@mui/material";
import { styled, Box } from "@mui/system";

const GeographicScope = (props: any) => {
  const [scope, setScope] = React.useState<string | null>("");

  React.useEffect(() => {
    setScope(props.geographicScope);
  }, [props.geographicScope]);

  return (
    <Box sx={{ width: "100%", md: "120px" }}>
      <CustomSelect
        defaultValue={scope}
        value={scope}
        id="geographic-scope-select"
        onChange={(event: any, newValue: any | null) => {
          newValue
            ? props.updateGeographicScope(newValue)
            : props.updateGeographicScope("");
          setScope(newValue);
        }}
      >
        <StyledOption value={""}>Alcance geográfico</StyledOption>
        <StyledOption value={"INTERNACIONAL"}>Internacional</StyledOption>
        <StyledOption value={"REGIONAL"}>Regional</StyledOption>
        <StyledOption value={"NACIONAL"}>Nacional</StyledOption>
      </CustomSelect>
    </Box>
  );
};

const CustomSelect = React.forwardRef(function CustomSelect<
  TValue extends {},
  Multiple extends boolean
>(
  props: SelectProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const slots: SelectProps<TValue, Multiple>["slots"] = {
    root: StyledButton,
    listbox: StyledListbox,
    // popper: StyledPopper,
    ...props.slots,
  };

  return <Select {...props} ref={ref} slots={slots} />;
}) as <TValue extends {}, Multiple extends boolean>(
  props: SelectProps<TValue, Multiple> & React.RefAttributes<HTMLButtonElement>
) => JSX.Element;

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Ubuntu;
  font-size: 16px;
  box-sizing: border-box;
  min-height: calc(1.5em + 30px);
  max-width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  text-align: left;
  line-height: 1;
  background: #dedede;
  width: inherit;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms; 
  color: ${theme.palette.neutral.active};

  &:hover {
    background: ${alpha(theme.palette.neutral.main, 0.4)};
    border-color: ${theme.palette.neutral.hover};
  }

  &.${selectClasses.focusVisible} {
    border-color: red;
    outline: 3px solid ${theme.palette.primary.main};
  }

  &.${selectClasses.expanded} {
    &::after {
      content: '▴';
      color:${theme.palette.primary.main}
    }
  }

  &::after {
    content: '▾';
    float: right;
    color:${theme.palette.primary.main}
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Ubuntu;
  font-size: 16px;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 100%;
  width: 100%;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 30px ${theme.palette.neutral.main};
  `
);

const StyledOption = styled(Option)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.primary.main};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.secondary.main};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.primary.main};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.neutral.main};
    color: ${theme.palette.neutral.active};
  }
  `
);

const StyledPopper = styled(Popper)`
  z-index: 1;
`;

export default GeographicScope;
