import { Box, IconButton, Typography, styled } from "@mui/material";
import FacebookIframe from "./FacebookIframe";

import InstagramColorIcon from "./../../../assets/Communication/socials/instagram-color.png";
import YoutubeColorIcon from "./../../../assets/Communication/socials/youtube-color.png";
import SolidarIcon from "./../../../assets/Communication/socials/solidar.png";
import { ComunicacionEnlaces } from "../constants";

const StyledBox = styled(Box)(() => ({
  margin: "0 0 0 0",
  padding: 0,
  width: "100%",
  backgroundColor: "#fcf8de",
}));

const StyledImg = styled("img")(() => ({
  maxWidth: "50px",
  height: "100%",
  objectFit: "contain",
}));

export const SocialsSection = () => {
  const goToLink = (enlace: string) => {
    const link = document.createElement("a");
    link.href = enlace;
    link.target = "_blank";
    link.click();
  };

  return (
    <StyledBox display="flex" justifyContent="center" id="campaign">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          margin: 0,
          padding: 0,
          pt: 5,
          px: 3,
          pb: 10,
        }}
      >
        <Typography
          variant="h4"
          textAlign={"center"}
          fontWeight={"bold"}
          color={"rgb(74,12,85,0.8)"}
        >
          SÍGUENOS EN REDES SOCIALES
        </Typography>
        <Box my={5} />
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <FacebookIframe />
        </Box>
        <Box>
          <Box
            sx={{
              py: 2,
              borderRadius: "8px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              px: 1,
            }}
          >
            <IconButton
              size="small"
              onClick={() => goToLink(ComunicacionEnlaces.INSTAGRAM)}
            >
              <StyledImg src={InstagramColorIcon} alt="noticia img" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => goToLink(ComunicacionEnlaces.YOUTUBE)}
            >
              <StyledImg src={YoutubeColorIcon} alt="youtube img" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => goToLink(ComunicacionEnlaces.SOLIDAR)}
            >
              <StyledImg src={SolidarIcon} alt="solidar img" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
};
