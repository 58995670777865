import {DatePicker, DatePickerProps, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {alpha, Box, FormControl, Typography} from "@mui/material";
import "dayjs/locale/es";
import theme from "../../../themes";
import {Dayjs} from "dayjs";

type CDatePickerProps = Omit<DatePickerProps<string | Dayjs>, "error"> & {
  error?: {
    type?: string;
    message?: string;
  }
  placeholder?: string;
}


const CDatePicker = (props: CDatePickerProps) => {
  const inputProps = Object.assign({}, props);
  delete inputProps.error;
  return (
    <FormControl fullWidth>
      <LocalizationProvider adapterLocale={"es"} dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            backgroundColor: "#ECECEC",
            margin: 0,
            borderRadius: "8px",
            borderWidth: 0.5,
            borderStyle: "solid",
            borderColor: props.error ? theme.palette.error.main : "#ECECEC",
          }}
        >
          <DatePicker
            {...inputProps}
            sx={{
              input: {
                color: theme.palette.common.black,
                "&::placeholder": {
                  color: alpha(theme.palette.common.black, 0.7),
                  opacity: 1,
                },
              },
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "standard",
                margin: "none",
                type: "text",
                // ...inputProps,
                style: {
                  padding: 10,
                },
                InputProps: { disableUnderline: true },
              },
            }}
          />
        </Box>
      </LocalizationProvider>
      {props.error && (
        <Typography
          component={"span"}
          style={{
            textAlign: "left",
            fontSize: ".8rem",
            color: theme.palette.error.main,
            paddingTop: "3px",
          }}
        >
          {props.error && props.error.type && props.error.type === "required"
            ? "* "
            : ""}
          {props.error && props.error.message ? props.error.message : ""}
        </Typography>
      )}
    </FormControl>
  );
};

export default CDatePicker;
