
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { printLog } from "../../utils";

type Props = {
  urlVideo: string;
};

export const YoutubePlayer = (props: Props) => {
  const { urlVideo } = props;
  // const theme = useTheme();
  const [youtubeID, setYoutubeID] = useState("");

  const youtubeParser = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[7].length === 11) {
      return match[7];
    } else{
      printLog("Url incorrecta");
      return '';
    }
  }

  useEffect(() => {
    printLog('urlVideo para parsear :: ', urlVideo);
    printLog('=======> ')
    const _idVideo = youtubeParser(urlVideo);
    printLog('desde youtubeParser :: idVideo :: ', _idVideo);
    printLog('=======> ')
    setYoutubeID(_idVideo);
    printLog('url generado :: ', `https://www.youtube.com/embed/${_idVideo}`);
  }, [youtubeID, urlVideo]);


  return (
    <Box mt={2} sx={{ alignSelf: 'center', textAlign: 'center' }}>
      {/* <YouTube videoId={youtubeID} opts={opts} onReady={_onReady} /> */}
      <iframe
        width="100%"
        height="450"
        src={`https://www.youtube.com/embed/${youtubeID}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </Box>
  );
};
