export const recursosCampaniaData = [
  {
    tipo: "video",
    titulo: "50 historias de lucha contra la violencia - Padcaya",
    enlace: "https://www.youtube.com/watch?v=sSqmVv6_6vk",
    imagen: "imagen_2024-06-27_005050761.png",
  },
  {
    tipo: "video",
    titulo: "50 historias de lucha contra la violencia - Villamontes",
    enlace: "https://www.youtube.com/watch?v=ZS8wvGdCwWU",
    imagen: "imagen_2024-06-27_010127527.png",
  },
  {
    tipo: "audio",
    titulo: "50 historias de lucha contra la violencia - Rurrenabaque",
    enlace:
      "https://soundcloud.com/vida-sinviolencia-954512150/50-historias-de-lucha-contra-la-violencia-rurrenabaque?si=c0dc475615a2480d85032853115acb2a&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    imagen: "50-historias.jpeg",
  },
  {
    tipo: "audio",
    titulo: "50 historias de lucha contra la violencia - Centro Creando Oportunidades",
    enlace:
      "https://soundcloud.com/vida-sinviolencia-954512150/50-historias-de-lucha-contra-la-violencia-centro-creando-oportunidades?si=c0dc475615a2480d85032853115acb2a&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    imagen: "50-historias.jpeg",
  },
  {
    tipo: "audio",
    titulo: "50 historias de lucha contra la violencia - Padcaya",
    enlace:
      "https://soundcloud.com/vida-sinviolencia-954512150/vdsv-2024-03-padcaya?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing&si=3c2aa35dc5df493f90f772b7cc261ca5",
    imagen: "50-historias.jpeg",
  },
  {
    tipo: "audio",
    titulo: "50 historias de lucha contra la violencia - Villamontes",
    enlace:
      "https://soundcloud.com/vida-sinviolencia-954512150/vdsv-2024-04-villamontes?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing&si=eef8663c08fb46109b5ccf9354a98123",
    imagen: "50-historias.jpeg",
  },
  {
    tipo: "archivo",
    titulo: "50 historias de lucha contra la violencia",
    enlace:
      "Primer Boletin VDSV.pdf",
    imagen: "50-historias.jpeg",
  },
];

export const recursosData = [
  {
    bloque: "REDES_LUCHA",
    tipo: "video",
    titulo: "Padcaya, una buena práctica",
    enlace: "https://www.youtube.com/watch?v=dggL3D7qz4o&t=7s",
    imagen: "imagen_2024-06-27_005050761.png",
    // imagen: "src/assets/Communication/imagen_2024-06-27_005050761.png",
  },
  {
    bloque: "REDES_LUCHA",
    tipo: "archivo",
    titulo:
      "Guía integral de servicios municipales por una vida digna sin violencia",
    enlace:
      "https://solidar-suiza.org.bo/wp-content/uploads/2023/11/Guia-Gestion.pdf",
    imagen: "Guia-Gestion_pages-to-jpg-0001.jpg",
  },
  {
    bloque: "MUJERES",
    tipo: "video",
    titulo: "Alcalá, una buena práctica",
    enlace: "https://www.youtube.com/watch?v=vH522EUyGSw&t=2s",
    imagen: "imagen_2024-06-27_005323703.png",
  },
  {
    bloque: "OTRAS_MASCULINIDADES",
    tipo: "video",
    titulo: "¿Te unes al equipo?",
    enlace: "https://www.youtube.com/watch?v=n5F6pHSLXnw",
    imagen: "imagen_2024-06-27_005624078.png",
  },
  {
    bloque: "OTRAS_MASCULINIDADES",
    tipo: "video",
    titulo: "¿Por qué trabajar otras masculinidades?",
    enlace:
      "https://www.instagram.com/reel/C4bMAjWJdRy/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    imagen: "imagen_2024-06-27_005905620.png",
  },
  {
    bloque: "OTRAS_MASCULINIDADES",
    tipo: "video",
    titulo: "Otras masculinidades son posibles, testimonios",
    enlace: "https://youtu.be/BaolBDGBpTI?si=Q21k_VwaClyA_W_W",
    imagen: "imagen_2024-06-27_010022075.png",
  },
  {
    bloque: "OTRAS_MASCULINIDADES",
    tipo: "video",
    titulo:
      "Otras masculinidades son posibles, una metodología para la transformación social",
    enlace: "https://youtu.be/tI05GQz4K9g?si=5LWAhltOpv9KMkZ3",
    imagen: "imagen_2024-06-27_010127527.png",
  },
  // {
  //   bloque: "OTRAS_MASCULINIDADES",
  //   tipo: "video",
  //   titulo: "Otras masculinidades son posibles, invitación",
  //   enlace: "https://www.youtube.com/watch?v=n5F6pHSLXnw",
  //   imagen: "",
  // },
  {
    bloque: "OTRAS_MASCULINIDADES",
    tipo: "video",
    titulo: "Las cosas por su nombre",
    enlace:
      "https://drive.google.com/file/d/1TLcp4L8IX2IFkDQ2EGbcPBcCHlWhgvJx/view?usp=drive_link",
    imagen: "imagen_2024-06-27_010402881.png",
  },
  {
    bloque: "OTRAS_MASCULINIDADES",
    tipo: "video",
    titulo: "Identificando las resistencias (Final)",
    enlace:
      "https://drive.google.com/file/d/1DnAyRIhGfTdnrDKJZdsmCpWUYW5DdU2q/view?usp=drive_link",
    imagen: "imagen_2024-06-27_010543750.png",
  },
  {
    bloque: "OTRAS_MASCULINIDADES",
    tipo: "archivo",
    titulo: "Guía para facilitadores",
    enlace:
      "https://drive.google.com/file/d/1ISSmAxbrPBfuTLVztwbGDuJNjRSidzKQ/view?usp=drive_link",
    imagen: "guia-otras-masculinidades-0001.jpg",
  },
  {
    bloque: "EDUACTIVISTAS",
    tipo: "video",
    titulo: "Mujer EduActivista",
    enlace:
      "https://www.instagram.com/reel/C1Hpa17u0Fp/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    imagen: "imagen_2024-06-27_010956403.png",
  },
  {
    bloque: "EDUACTIVISTAS",
    tipo: "archivo",
    titulo: "Guía de prevención con jóvenes EduActivistas",
    enlace:
      "https://drive.google.com/file/d/1eOBmV7qDftZITZkhbX5eCaz5hU7AZpXW/view?usp=drive_link",
    imagen: "tapa-eduactivistas2.png",
  },
];
