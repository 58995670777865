import Layout from "../../components/Layout";
import Footer from "../../components/Footer";
import MainBanner from "./components/MainBanner";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { CoursePrimaryButton } from "../Courses/shared/Elements";
import { useHistory } from "react-router-dom";
import useAppContext from "../../shared/hooks/useAppContext";
import { CourseCatalog } from "../Courses/types/CourseCatalog.type";
import { useEffect } from "react";
import { printLog } from "../../shared/utils";
import ReactGA from "react-ga4";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  background: "#cbcbcb",
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  backgroundColor: "black",
  height: 300,
  // height: '70%',
  overflow: "hidden",
  position: "relative",
  transition: "300ms",
  cursor: "pointer",
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  bottom: "0",
  padding: "15px",
  color: "white",
  width: "100%",
  // height: '55%',
  fontSize: "21px",
  fontWeight: "200",
  transition: "300ms",
  cursor: "pointer",
}));

const CoursesCatalog = () => {
  const theme = useTheme();
  const history = useHistory();
  const { homeCourses, getHomeCourses } = useAppContext();
  printLog("Home courses >>> ", JSON.stringify(homeCourses));
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getHomeCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/catalogo-cursos",
      title: "Catálogo Cursos",
    });
  }, []);

  return (
    <Layout bgColor="#cbcbcb">
      <MainBanner />
      <StyledBox display="flex" justifyContent="center">
        <Box
          sx={{
            width: {
              xs: "100%",
              md: theme.breakpoints.values.md,
              lg: theme.breakpoints.values.lg,
            },
            height: "100%",
            margin: 0,
            padding: 0,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            style={{ width: "100%" }}
            sx={{ padding: "0em 1.5em 6em 1.5em" }}
          >
            <Box sx={{ width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={2}
                pt={0}
                pb={10}
              >
                {/* <Button
                  size={isMobile ? "small" : "large"}
                  variant="outlined"
                  sx={{
                    fontFamily: 'Barlow',
                    borderRadius: '8px',
                    border: '1px solid #808080',
                    color: '#808080',
                    '&:hover': {
                      border: '1px solid #808080',
                      backgroundColor: '#808080',
                      color: '#E8E8E8',
                    },
                  }}
                >
                  Ver todos
                </Button> */}
              </Stack>
            </Box>
            <Grid container alignItems={"stretch"} spacing={4}>
              {(homeCourses as CourseCatalog[]).map(
                (courseCatalog, idxCourseCatalog) => (
                  <Grid item md={6} lg={4} xs={12} key={idxCourseCatalog}>
                    <Card
                      elevation={0}
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "8px",
                      }}
                    >
                      <StyledCardMedia
                        image={courseCatalog.urlImagen}
                        title={courseCatalog.nombre}
                      >
                        <Box
                          width="100%"
                          height="100%"
                          sx={{
                            background:
                              "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))",
                          }}
                        >
                          <StyledDivMediaCaption sx={{ pb: "1.3rem" }}>
                            <Typography
                              sx={{
                                fontSize: { xs: 25, lg: 32 },
                                fontFamily: "Barlow",
                                lineHeight: "1",
                              }}
                            >
                              {courseCatalog.nombre}
                            </Typography>
                          </StyledDivMediaCaption>
                        </Box>
                      </StyledCardMedia>
                      <CardContent sx={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "4",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {courseCatalog.descripcion}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{ mx: theme.spacing(1), mb: theme.spacing(1) }}
                      >
                        <CoursePrimaryButton
                          onClick={() =>
                            history.push("/catalogo-cursos/detalle", {
                              course: courseCatalog,
                              idxCourse: idxCourseCatalog,
                            })
                          }
                        >
                          {courseCatalog.nombre === "Prevención "
                            ? "PRÓXIMAMENTE"
                            : "VER MÁS"}
                        </CoursePrimaryButton>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Box>
      </StyledBox>
      <Footer />
    </Layout>
  );
};
export default CoursesCatalog;
