import { Box, Button, Typography, styled } from "@mui/material";
import theme from "../../../../themes";
import experienceImg from "../../../../assets/innovativeExperiences/img_more_experience_green.png";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  backgroundColor: theme.palette.success.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  borderRadius: "8px",
  backgroundColor: "transparent",
  color: theme.palette.common.white,
  border: "1px solid white",
  marginTop: "10px",
  "&:hover": {
    border: "1px solid #808080",
    backgroundColor: "#808080",
    color: theme.palette.common.white,
  },
  opacity: 1,
}));

const BoxT = styled(Box)(() => ({
  position: "relative",
  display: "inline-block",
  "&:after": {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "inline-block",
    background: "linear-gradient(0.25turn, #00c280, transparent, #00c280);",
  },
  // [theme.breakpoints.up('xs')]: {
  //   height:'160px'
  // },
  // [theme.breakpoints.up('md')]: {
  //   height:'auto'
  // }
}));

const StyledImg = styled("img")(() => ({
  maxWidth: "100%",
  height: "100%",
  objectFit: "cover",
  [theme.breakpoints.between("sm", "md")]: {},
}));

const GreenExperiencesArea = () => {
  return (
    <StyledBox display="flex" justifyContent="center">
      <Box sx={{ width: { xs: "100%", md: "75%" }, height: "100%" }}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          sx={{ height: "100%", margin: 0, padding: 0 }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "70%" },
              padding: "1.5em 1.5em 1.5em 1.5em",
            }}
          >
            <Typography
              variant="h1"
              style={{
                fontFamily: "Barlow",
                fontSize: 42,
                color: theme.palette.common.white,
              }}
            >
              Colectivo de Mujeres emprendiendo sus sueños “El Villar -
              Chuquisaca”.
            </Typography>
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Barlow",
                fontSize: 16,
                color: theme.palette.common.white,
                my: "1.5em",
              }}
            >
              Promovemos la autonomía económica, fomentando la colaboración,
              creatividad, resiliencia y realización de proyectos empresariales.
            </Typography>
            {/*<StyledButton variant="outlined">DESCARGA MATERIAL</StyledButton>*/}
          </Box>
          <BoxT className="fabia" sx={{ width: { xs: "100%", md: "30%" } }}>
            <StyledImg src={experienceImg} alt="img" />
          </BoxT>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default GreenExperiencesArea;
