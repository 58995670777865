import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useAuthContext } from "../shared/auth/AuthContext";
import Home from "./Home";
import InnovativeExperiences from "./InnovativeExperiences";
import GoodMunicipalPractices from "./GoodMunicipalPractices";
import Hemeroteca from "./Hemeroteca";
import WhoWeAre from "./WhoWeAre";
import CoursesCatalog from "./CoursesCatalog";
import CourseCatalogDetail from "./CoursesCatalog/Detail";
import Courses from "./Courses";
import StudentCourses from "./Courses/student";
import StudentCourseDetail from "./Courses/student/StudentCourseDetail";
import CoursesDetail from "./Courses/CourseDetail";
import { UnidadContenidoCursoPage } from "./Courses/student/UnidadContenidoCursoPage";
import InterestLink from "./Hemeroteca/components/InterestLink";
import NewsDetail from "./Hemeroteca/components/NewsDetail";
import { UserRecord } from "../shared/auth/Auth";
import AdminBoard from "./AdminBoard";
import LoggedInLayout from "../components/LoggedInLayout/LoggedInLayout";
import UsuarioPage from "./AdminUsuario/UsuarioPage";
import { NoticiaPage } from "./AdminNoticia/NoticiaPage";
import { ReportePage } from "./AdminReporte/ReportePage";
import { useFullScreenLoading } from "../context/ui/FullScreenLoadingProvider";
import { useSession } from "../shared/hooks/useSession";
import { printLog } from "../shared/utils";
import AllInnovativeExperiences from "./AllInnovativeExperiences";
import ScrollToTop from "../ScrollToTop";
import NoticiaOverview from "./AdminNoticia/components/NoticiaOverview";
import { IniciativaPage } from "./AdminIniciativas/IniciativaPage";
import IniciativaOverview from "./AdminIniciativas/components/IniciativaOverview";
import NumbersLocality from "./NumbersLocality";
import { NationalDirectory } from "./NationalDirectory";
import { DirectorioPage } from "./AdminDirectorio/DirectorioPage";
import CertificateCheck from "./Certificates";
import { DirectorioFormPage } from "./AdminDirectorio/DirectorioFormPage";
import { Help } from "./Help";
import { AdminCifrasPage } from "./AdminCifras";
import MachometroPage from "./Machometro";
import CommunicationPage from "./Communication";
import { AdminComunicacionPage } from "./AdminComunicacion";
import { CommunicationBannerPage } from "./AdminComunicacion/banner/BannerPage";
import { CommunicationCampaignPage } from "./AdminComunicacion/campaign/CampaignPage";
// import { ComunicacionRecursosPage } from "./AdminComunicacion/recursos/RecursosPage";
import DetailCampaignPage from "./AdminComunicacion/campaign/DetailCampaignPage";
import { CommunicationResourcesPage } from "./AdminComunicacion/resources/ResourcesPage";
// import { CampanaDetallePage } from "./AdminComunicacion/campania/CampaniaDetallePage";

function MonitorRoutes() {
  printLog("*** Render MONITOR ROUTES ***");
  return (
    <Router>
      <LoggedInLayout>
        <ScrollToTop />
        <Switch>
          <Route path="/admin" exact>
            <AdminBoard />
          </Route>
          <Route path="/admin/usuario" exact>
            <UsuarioPage />
          </Route>
          <Route path="/admin/iniciativa" exact>
            <IniciativaPage />
          </Route>
          <Route path="/admin/iniciativa/detalle" exact>
            <IniciativaOverview />
          </Route>
          <Route path="/admin/iniciativa/detalle/:noticiaId" exact>
            <IniciativaOverview />
          </Route>
          <Route path="/admin/noticia" exact>
            <NoticiaPage />
          </Route>
          <Route path="/admin/noticia/detalle" exact>
            <NoticiaOverview />
          </Route>
          <Route path="/admin/noticia/detalle/:noticiaId" exact>
            <NoticiaOverview />
          </Route>
          <Route path="/admin/reporte" exact>
            <ReportePage />
          </Route>
          <Route path="/admin/cursos" exact>
            <Courses />
          </Route>
          <Route path="/admin/cursos/detalle" exact>
            <CoursesDetail />
          </Route>
          <Route path="/admin/directorio" exact>
            <DirectorioPage />
          </Route>
          <Route path="/admin/cifras" exact>
            <AdminCifrasPage />
          </Route>
          <Route path="/admin/comunicacion" exact>
            <AdminComunicacionPage />
          </Route>
          <Route path="/admin/comunicacion/banner" exact>
            <CommunicationBannerPage />
          </Route>
          <Route path="/admin/comunicacion/campana" exact>
            <CommunicationCampaignPage />
          </Route>
          <Route path="/admin/comunicacion/campana/detalle" exact>
            <DetailCampaignPage />
          </Route>
          <Route path="/admin/comunicacion/recurso" exact>
            <CommunicationResourcesPage />
          </Route>
          <Route path={"/ayuda"} exact>
            <Help />
          </Route>
          <Route path="/admin/directorio/:municipio_cod" exact>
            <DirectorioFormPage />
          </Route>
          <Redirect to="/admin" />
        </Switch>
      </LoggedInLayout>
    </Router>
  );
}

function ParticipanteRoutes() {
  printLog("*** Render PARTICIPANTE ROUTES ***");
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/experiencias-innovadoras" exact>
          <InnovativeExperiences />
        </Route>
        <Route path="/cifras-municipio" exact>
          <NumbersLocality />
        </Route>
        <Route path="/directorio-municipal" exact>
          <NationalDirectory />
        </Route>
        <Route path="/experiencias-innovadoras-todas" exact>
          <AllInnovativeExperiences />
        </Route>
        <Route path="/buenas-practicas" exact>
          <GoodMunicipalPractices />
        </Route>
        <Route path="/hemeroteca" exact>
          <Hemeroteca />
        </Route>
        <Route path="/hemeroteca/link-de-interes/:id" exact>
          <InterestLink />
        </Route>
        <Route path="/hemeroteca/noticia/:id" exact>
          <NewsDetail />
        </Route>
        <Route path="/quienes-somos" exact>
          <WhoWeAre />
        </Route>
        <Route path="/catalogo-cursos" exact>
          <CoursesCatalog />
        </Route>
        <Route path="/catalogo-cursos/detalle" exact>
          <CourseCatalogDetail />
        </Route>
        <Route path="/cursos" exact>
          <StudentCourses />
        </Route>
        {/*<Route path="/cursos/detalle" exact>
          <CoursesDetail />
        </Route>*/}
        <Route path="/cursos/participante/detalle" exact>
          <StudentCourseDetail />
        </Route>
        <Route path="/cursos/participante/unidad/detalle" exact>
          <UnidadContenidoCursoPage />
        </Route>
        <Route path={"/certificate/:certificateId"} exact>
          <CertificateCheck />
        </Route>
        <Route path={"/ayuda"} exact>
          <Help />
        </Route>
        <Route path="/machometro" exact>
          <MachometroPage />
        </Route>
        <Route path="/comunicacion" exact>
          <CommunicationPage />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

function RoutesPorRol(props: { rol: string }) {
  switch (props.rol) {
    case "MONITOR":
      return (
        <MonitorRoutes />
        // <LoggedInLayout>
        // </LoggedInLayout>
      );
    case "PARTICIPANTE":
      return <ParticipanteRoutes />;
    default:
      return <ParticipanteRoutes />;
  }
}

function LoggedInRoutes(props: { rol: string }) {
  return (
    <RoutesPorRol rol={props.rol} />
    // <BrowserRouter>
    //   <LoggedInLayout>
    //     <RoutesPorRol rol={props.rol} />
    //   </LoggedInLayout>
    // </BrowserRouter>
  );
}

function LoggedOutRouter() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/experiencias-innovadoras" exact>
          <InnovativeExperiences />
        </Route>
        <Route path="/cifras-municipio" exact>
          <NumbersLocality />
        </Route>
        <Route path="/directorio-municipal" exact>
          <NationalDirectory />
        </Route>
        <Route path="/experiencias-innovadoras-todas" exact>
          <AllInnovativeExperiences />
        </Route>
        <Route path="/buenas-practicas" exact>
          <GoodMunicipalPractices />
        </Route>
        <Route path="/hemeroteca" exact>
          <Hemeroteca />
        </Route>
        <Route path="/hemeroteca/link-de-interes/:id" exact>
          <InterestLink />
        </Route>
        <Route path="/hemeroteca/noticia/:id" exact>
          <NewsDetail />
        </Route>
        <Route path="/quienes-somos" exact>
          <WhoWeAre />
        </Route>
        <Route path="/catalogo-cursos" exact>
          <CoursesCatalog />
        </Route>
        <Route path="/catalogo-cursos/detalle" exact>
          <CourseCatalogDetail />
        </Route>
        <Route path={"/certificate/:certificateId"} exact>
          <CertificateCheck />
        </Route>
        <Route path="/machometro" exact>
          <MachometroPage />
        </Route>
        <Route path="/comunicacion" exact>
          <CommunicationPage />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default function Main() {
  const authContext = useAuthContext();
  const { mostrarFullScreen, ocultarFullScreen } = useFullScreenLoading();
  const { requestSession } = useSession();
  const user = authContext.user;

  const [loading, setLoading] = useState(true);

  const verificarSesion = async () => {
    printLog("[Main][VERIFICAR_SESION] init.... ");
    try {
      mostrarFullScreen();
      const response = await requestSession({
        url: "/session/info",
        tipo: "GET",
      });
      printLog(" *** 👩‍🚀 *** current user > ", user);
      printLog("[Main] response de sesion info >>> ", response);
      if (!user && response.rol) {
        authContext.setRol({
          rol: response.rol,
          email: response.email,
          displayName: response.nombreCompleto,
        } as UserRecord);
      } else {
        // TODO: Fix infinite loop
        // TODO: determinar funcionalidad
        // localStorage.removeItem("user");
        // authContext.setRol(null);
      }
    } catch (error) {
      printLog("[Main] Error en la sesion info >>> ", error);
      // authContext.setRol(null)
      authContext.logout({
        onSuccess: () => {
          localStorage.removeItem("user");
          authContext.setRol(null);
        },
        onFailure: () => {},
      });
    } finally {
      ocultarFullScreen();
      printLog("[Main] finally >>>");
      printLog(" *** 👩‍🚀 *** current user > ", user);
    }
  };

  useEffect(() => {
    verificarSesion().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  console.log("CARLOS:::::user", user);

  if (loading) return <></>;

  return (
    <>
      {user && user.rol && <LoggedInRoutes rol={user.rol ?? ""} />}
      {!user?.rol && <LoggedOutRouter />}
    </>
  );
}
