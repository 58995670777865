import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CInputForm from "../../../shared/components/form/CInputForm";
import { colorsBase, DesignSystem } from "../../../themes/theme";
import { Editor } from "react-draft-wysiwyg";
import { CFilePicker } from "../../../shared/components/form/CFilePicker";
import { Adjunto, INoticiaForm, NoticiaDetalle } from "../NoticiaInterface";
import { ChangeEvent, useEffect, useState } from "react";
import {
  CancelButton,
  ConfirmButton,
} from "../../../shared/components/CButton";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { ReactComponent as RolIcon } from "../../../assets/icons/rol.svg";
import { ReactComponent as CancelCircleIcon } from "../../../assets/icons/cancelCircle.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useHttp from "../../../shared/hooks/useHttp";
import { useAuthContext } from "../../../shared/auth/AuthContext";
import CSelectForm from "../../../shared/components/form/CSelectForm";
import { printLog } from "../../../shared/utils";

export type NoticiaPayload = INoticiaForm & {
  archivos: File[];
  adjuntosEliminados: Adjunto[];
};

type FormNoticiaComponentProps = {
  noticia?: NoticiaDetalle;
  noticiaDialog: boolean;
  onClose: () => void;
  onSubmitNoticia: (data: NoticiaPayload) => void;
  loading: boolean;
  media: any;
  esEnlaceDeInteres?: boolean | undefined;
};

export const FormNoticiaComponent = ({
  noticia,
  noticiaDialog,
  onClose,
  onSubmitNoticia,
  loading,
  media,
  esEnlaceDeInteres,
}: FormNoticiaComponentProps) => {
  const [adjuntos, setAdjuntos] = useState<File[]>([]);
  const [currentAdjunto, setCurrentAdjunto] = useState<Adjunto | null>();
  const [deletedAdjuntos, setDeletedAdjuntos] = useState<Adjunto[]>([]);
  const [editorState, setEditorState] = useState(() => {
    if (noticia && esEnlaceDeInteres === false) {
      const parseado = JSON.parse(noticia.contenido);
      return EditorState.createWithContent(convertFromRaw(parseado));
    }
    return EditorState.createEmpty();
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useForm<INoticiaForm>();
  const http = useHttp();
  const authContext = useAuthContext();
  useEffect(() => {
    if (noticia) {
      console.log(noticia);

      const isLink = (Object.keys(noticia) as (keyof typeof noticia)[]).find(
        (key) => {
          return key === "prensa_cod";
        }
      );

      reset({
        titulo: noticia.titulo,
        tipo: isLink ? "ENLACE_INTERES" : "NOTICIA",
        categoria: noticia.categoria,
        alcanceGeografico: noticia.alcanceGeografico,
        esNoticiaSolidar: noticia.esNoticiaSolidar,
        estado: noticia.estado,
      });

      if (!isLink) setValue("esDestacada", noticia.esDestacada);

      isLink ? setValue("tipo", "ENLACE_INTERES") : setValue("tipo", "NOTICIA");

      if (isLink) setValue("url", noticia.prensa_enlace);

      if (isLink) setValue("medio_cod", noticia.medio_cod);

      // debugger;

      if (noticia.urlImagen) {
        let urlSplit = noticia.urlImagen.split("/");
        let fileName = urlSplit[urlSplit.length - 1];

        setCurrentAdjunto({
          nombre: fileName,
          url: noticia.urlImagen,
        });
      }
    }
  }, []);

  // const isNews = useWatch({
  //   control,
  //   name: "tipo",
  // });

  const isNews = watch("tipo");

  const onEditorStateChange = (editorState: EditorState) => {
    // printLog('seteando cambios en editor ::> ');
    setEditorState(editorState);
  };

  const handleChangePicker = ({ target }: ChangeEvent<HTMLInputElement>) => {
    // printLog('[NoticiaPage] >  Archivos seleccionados de FilePicker :: target.files ', target.files);
    if (target.files && target.files.length) {
      printLog(
        "[NoticiaPage] >  Archivos seleccionados de FilePicker :: target.files[0]",
        target.files![0]
      );
      // if (multiple)
      setAdjuntos([
        // ...adjuntos,
        ...target.files,
      ]);
    }
  };

  const handleSubmitPublicar: SubmitHandler<INoticiaForm> = async (data) => {
    printLog("PUBLICAR noticia :: ", data);
    printLog("Adjuntos de la noticia :: ", adjuntos);

    if (data.tipo === "NOTICIA") {
      const datos: NoticiaPayload = {
        tipo: data.tipo,
        titulo: data.titulo,
        categoria: data.categoria,
        alcanceGeografico: data.alcanceGeografico,
        esNoticiaSolidar: data.esNoticiaSolidar,
        esDestacada: data.esDestacada,
        contenido: convertToRaw(editorState.getCurrentContent()),
        estado: "PUBLICADO",
        archivos: adjuntos,
        adjuntosEliminados: deletedAdjuntos,
      };

      onSubmitNoticia(datos);
    } else {
      // debugger;
      const datos: any = {
        tipo: data.tipo,
        titulo: data.titulo,
        categoria: data.categoria,
        alcanceGeografico: data.alcanceGeografico,
        estado: "PUBLICADO",
        archivos: adjuntos,
        adjuntosEliminados: deletedAdjuntos,
        url: data.url,
        medio_cod: data.medio_cod,
      };

      onSubmitNoticia(datos);
    }
  };
  const handleSubmitBorrador: SubmitHandler<INoticiaForm> = async (data) => {
    printLog("BORRADOR noticia :: ", data);
    // printLog('editorState.getCurrentContent :: ', editorState.getCurrentContent());
    // printLog('editorState convertToRaw :: ', convertToRaw(editorState.getCurrentContent()));
    printLog("Adjuntos de la noticia :: ", adjuntos);
    if (data.tipo === "NOTICIA") {
      const datos: NoticiaPayload = {
        tipo: data.tipo,
        titulo: data.titulo,
        categoria: data.categoria,
        alcanceGeografico: data.alcanceGeografico,
        esNoticiaSolidar: data.esNoticiaSolidar,
        esDestacada: data.esDestacada,
        contenido: convertToRaw(editorState.getCurrentContent()),
        estado: "BORRADOR",
        archivos: adjuntos,
        adjuntosEliminados: deletedAdjuntos,
      };

      onSubmitNoticia(datos);
    } else {
      const datos: any = {
        tipo: data.tipo,
        titulo: data.titulo,
        categoria: data.categoria,
        alcanceGeografico: data.alcanceGeografico,
        estado: "BORRADOR",
        archivos: adjuntos,
        adjuntosEliminados: deletedAdjuntos,
        url: data.url,
        medio_cod: data.medio_cod,
      };

      onSubmitNoticia(datos);
    }
  };

  const handleCloseDialogNoticia = () => {
    setAdjuntos([]);
    setEditorState(() => EditorState.createEmpty());
    reset();
    onClose();
  };

  return (
    <Dialog
      open={noticiaDialog}
      fullWidth
      maxWidth="md"
      onClose={handleCloseDialogNoticia}
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: `1px solid ${colorsBase.BorderGray}` }}
        >
          <Typography component={"span"} sx={{ ...DesignSystem?.titulo1 }}>
            Creación de nueva noticia
          </Typography>
          <IconButton onClick={handleCloseDialogNoticia}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box component={"form"} onSubmit={handleSubmit(handleSubmitBorrador)}>
        <DialogContent>
          <Grid container spacing={3} sx={{ px: 2 }}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Título de la noticia*
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="titulo"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El campo Titulo es requerido",
                  }}
                  render={({ field }) => (
                    <CInputForm
                      inputIcon={<RolIcon />}
                      type="text"
                      placeholder="Escribe el título de la noticia"
                      {...field}
                      ref={null}
                      error={errors.titulo}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Tipo*
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="tipo"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CSelectForm
                      placeholder="Selecciona el tipo de noticia"
                      fullWidth
                      items={[
                        { value: "NOTICIA", label: "NOTICIA" },
                        { value: "ENLACE_INTERES", label: "ENLACE DE INTERES" },
                      ]}
                      {...field}
                      ref={null}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Categoria
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="categoria"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CSelectForm
                      placeholder="Selecciona la categoría"
                      fullWidth
                      items={[
                        { value: "POLITICO", label: "POLÍTICO" },
                        { value: "ECONOMICO", label: "ECONÓMICO" },
                        { value: "SOCIAL", label: "SOCIAL" },
                        { value: "TECNOLOGICO", label: "TECNOLÓGICO" },
                        { value: "ECOLOGICO", label: "ECOLÓGICO" },
                        { value: "LEGAL", label: "LEGAL" },
                        { value: "SALUD", label: "SALUD" },
                      ]}
                      {...field}
                      ref={null}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Alcance geografico
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="alcanceGeografico"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CSelectForm
                      placeholder="Seleccione el Alcance Geográfico"
                      fullWidth
                      items={[
                        { value: "INTERNACIONAL", label: "INTERNACIONAL" },
                        { value: "REGIONAL", label: "REGIONAL" },
                        { value: "NACIONAL", label: "NACIONAL" },
                      ]}
                      {...field}
                      ref={null}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>

            {isNews === "NOTICIA" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  Es noticia de SOLIDAR:
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controller
                    name="esNoticiaSolidar"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        ref={null}
                        //TODO add validation for news and interest link
                        // required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}

            {isNews === "NOTICIA" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  Es noticia destacada:
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controller
                    name="esDestacada"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        ref={null}
                        //TODO add validation for news and interest link
                        // required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}

            {isNews === "NOTICIA" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo2 }}
                  >
                    Contenido de la noticia*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Editor
                    placeholder="Comienza a escribir el contenido..."
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    // toolbarCustomButtons={[<MyCustomBlock>
                    //   <Typography>algo</Typography>
                    // </MyCustomBlock>, <CustomOpcion />]}
                    wrapperClassName="wrapperClass"
                    editorClassName="editorClass"
                    toolbarClassName="toolbarClass"
                    uploadCallback={(file: any) => {
                      const formData = new FormData();
                      formData.append("file", file as File);
                      const result = http
                        .post("/file", {
                          payload: formData,
                          // auth: authContext?.user?.token ?? undefined,
                          withFiles: true,
                        })
                        .then((value: unknown) => ({
                          data: {
                            link: encodeURI(
                              (value as { publicUrl: string }).publicUrl
                            ),
                          },
                        }));
                      return result;
                    }}
                    localization={{
                      locale: "es",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "image",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                        ],
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      image: {
                        uploadEnabled: true,
                        previewImage: true,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}
            {isNews === "ENLACE_INTERES" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo2 }}
                  >
                    Url de la noticia*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controller
                    name="url"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: isNews === "ENLACE_INTERES" ? true : false,
                    }}
                    render={({ field }) => (
                      <CInputForm
                        inputIcon={<RolIcon />}
                        type="text"
                        placeholder="Escribe la url de la noticia"
                        {...field}
                        ref={null}
                        error={errors.url}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
            {isNews === "ENLACE_INTERES" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo2 }}
                  >
                    Medio*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controller
                    name="medio_cod"
                    control={control}
                    defaultValue={undefined}
                    render={({ field }) => (
                      <CSelectForm
                        placeholder="Selecciona el medio"
                        fullWidth
                        items={media}
                        {...field}
                        ref={null}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Añadir adjunto
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Box display="flex" flexDirection="column">
                  <CFilePicker
                    multiple={false}
                    placeholder="Carga el archivo adjunto"
                    handleChange={(ev: ChangeEvent<HTMLInputElement>) =>
                      handleChangePicker(ev)
                    }
                  />
                  <Box>
                    {adjuntos.map((adjunto: File, indiceAdjunto: number) => (
                      <Chip
                        deleteIcon={
                          <IconButton
                            size="small"
                            sx={{ stroke: colorsBase.Rojo }}
                          >
                            <CancelCircleIcon width="20" height="20" />
                          </IconButton>
                        }
                        onDelete={() => {
                          const _adj = [...adjuntos];
                          _adj.splice(indiceAdjunto, 1);
                          setAdjuntos(_adj);
                        }}
                        label={adjunto.name ? `${adjunto.name}` : ""}
                        key={indiceAdjunto}
                        sx={{
                          margin: 0.5,
                          border: `1px solid ${colorsBase.Verde3}`,
                        }}
                      />
                    ))}
                  </Box>
                  <Box>
                    {!!currentAdjunto &&
                    currentAdjunto &&
                    adjuntos.length === 0 ? (
                      <Chip
                        deleteIcon={
                          <IconButton
                            size="small"
                            sx={{ stroke: colorsBase.Rojo }}
                          >
                            <CancelCircleIcon width="20" height="20" />
                          </IconButton>
                        }
                        onDelete={() => {
                          console.log("eliminando adjunto!!");
                          const _adj = { ...currentAdjunto };
                          setCurrentAdjunto(null);
                          const _deletedAdj = [...deletedAdjuntos];
                          _deletedAdj.push(_adj);
                          setDeletedAdjuntos(_deletedAdj);
                        }}
                        label={`${currentAdjunto.nombre}`}
                        sx={{
                          margin: 0.5,
                          border: `1px solid ${colorsBase.Verde3}`,
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            margin: 2,
            marginTop: 0,
            paddingTop: 1,
            borderTop: `1px solid ${colorsBase.BorderGray}`,
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <CancelButton onClick={handleCloseDialogNoticia}>
              Cancelar
            </CancelButton>
            <ConfirmButton
              form="form-noticia"
              onClick={handleSubmit(handleSubmitBorrador)}
              sx={{ ml: 2 }}
              loading={loading}
            >
              Guardar borrador
            </ConfirmButton>
            {/* <ConfirmButton disabled sx={{ ml: 2 }}>
            Vista previa
          </ConfirmButton> */}
            <ConfirmButton
              onClick={handleSubmit(handleSubmitPublicar)}
              sx={{ ml: 2 }}
              loading={loading}
            >
              Publicar
            </ConfirmButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
