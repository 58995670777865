import { Control, Controller, FieldValues, Path } from "react-hook-form";
import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { Variant } from "@mui/material/styles/createTypography";
import theme from "../../../themes";

type CInputRadioProps<T extends FieldValues> = {
  id: string;
  name: Path<T>;
  control: Control<T, object>;
  label: string;
  size?: "small" | "medium";
  options: any[];
  rules?:
    | Omit<
        RegisterOptions<T, Path<T>>,
        "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
      >
    | undefined;
  disabled?: boolean;
  row?: boolean;
  labelVariant?: Variant;
};

export const CInputRadio = <T extends FieldValues>({
  id,
  name,
  control,
  label,
  options,
  row = false,
  rules,
  disabled,
  labelVariant = "subtitle2",
}: CInputRadioProps<T>) => (
  <Box px={2} py={0} my={0}>
    <InputLabel htmlFor={id}>
      <Typography
        variant={labelVariant}
        sx={{ fontWeight: "fontWeightMedium", color: "text.primary" }}
      >
        {label}
      </Typography>
    </InputLabel>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <RadioGroup
          row={row}
          value={value}
          onChange={onChange}
          id={id}
          name={name}
        >
          {options.map((singleOption, index) => (
            <FormControlLabel
              key={index}
              disabled={disabled}
              value={singleOption.value}
              label={singleOption.label}
              control={<Radio />}
            />
          ))}
          {error && (
            <Typography
              component={"span"}
              sx={{
                textAlign: "left",
                fontSize: ".8rem",
                color: theme.palette.error.main,
              }}
            >
              {error && error.type && error.type === "required" ? "* " : ""}
              {error.message ? error.message : ""}
            </Typography>
          )}
        </RadioGroup>
      )}
      rules={rules}
    />
  </Box>
);
