import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useEffect, useState} from "react";
// } from "../../common/components/CButton";
import {tableCellClasses} from "@mui/material/TableCell";
//import { colorsBase, DesignSystem } from "../../common/theme";
import {colorsBase, DesignSystem} from "../../themes/theme";

// Iconos
import {ReactComponent as PencilIcon} from "../../assets/icons/pencil.svg";
import {ReactComponent as TrashIcon} from "../../assets/icons/trash.svg";
import {ReactComponent as SeeIcon} from "../../assets/icons/see.svg";
import {ExpandMore} from "@mui/icons-material";
import {ReactComponent as KeyboardBackspace} from "../../assets/icons/keyboard-backspace.svg";
import {Region, UsuarioTable,} from "./interfaces/UsuarioInterface";

import {
  FiltrosUsuario,
  useCreateUsuario,
  useDeleteUsuario,
  useGetUsuarioById,
  useGetUsuarios,
  useUpdateEstadoUsuario,
  useUpdateUsuario,
} from "./UsuarioClient";
import {useHistory} from "react-router-dom";
import {printLog} from "../../shared/utils";
// import { showAlertError, showAlertSuccess, showConfirm } from "../../common/hooks/useAlert";
import {Alerts} from "../../shared/hooks/useAlert";
import {MaterialUISwitch} from "./components/MuiSwitch";
import {UsuarioForm} from "./components/UsuarioForm";
import {UsuarioFormFields} from "./components/UsuarioFilters";
// import { useAuthContext } from "../../common/auth/AuthContext";
import {useAuthContext} from "../../shared/auth/AuthContext";
// import { TableSkeleton } from "../../common/components/CustomSkeleton";
import {TableSkeleton} from "../../shared/components/CustomSkeleton";
import {CoursePrimaryButton} from "../Courses/shared/Elements";

type UsuarioPageProps = {};

const cabeceras = [
  {
    id: "acciones",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
  {
    id: "nombreCompleto",
    numeric: false,
    disablePadding: false,
    label: "Nombre completo",
  },
  {
    id: "correo",
    numeric: false,
    disablePadding: false,
    label: "Correo electrónico",
  },
  {
    id: "rol",
    numeric: false,
    disablePadding: false,
    label: "Rol",
  },
  {
    id: "estado",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "cambioEstado",
    numeric: false,
    disablePadding: false,
    label: "Cambio de estado",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderTop: `1px solid ${colorsBase.BorderGray}`,
    borderBottom: `1px solid ${colorsBase.BorderGray}`,
  },
  [`&:first-of-type`]: {
    borderLeft: `1px solid ${colorsBase.BorderGray}`,
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  [`&:last-of-type`]: {
    borderRight: `1px solid ${colorsBase.BorderGray}`,
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface UsuariosDataProps {
  generos: string[];
  paginacion: {
    elementosPorPagina: number;
    paginaActual: number;
    totalFilas: number;
    totalPaginas: number;
  };
  pertenenciaEtnica: string[];
  roles: string[];
  regiones: Region[];
  usuarios: UsuarioTable[];
}

export default function UsuarioPage(_props: UsuarioPageProps) {
  const authContext = useAuthContext();
  // const [usuariosData, setUsuariosData] = useState<UsuariosDataProps>();
  const [usuariosData, setUsuariosData] = useState<[]>();
  const [viewFilters, setViewFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<Partial<FiltrosUsuario>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [userDialog, setUserDialog] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPaginas, setTotalPaginas] = useState<number>(0);
  const [usuario, setUsuario] = useState(null);
  const navigate = useHistory();
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('md'));

  /// from client
  const getUsuarios = useGetUsuarios();
  const createUsuario = useCreateUsuario();
  const getUsuario = useGetUsuarioById();
  const updateUsuario = useUpdateUsuario();
  const updateEstadoUsuario = useUpdateEstadoUsuario();
  const deleteUsuario = useDeleteUsuario();

  const mostrarTabla = !!(usuariosData?.length || filters) 
  const mostrarFooter = !!(usuariosData?.length)

  async function fetchUsuarios() {

    const fetchedUsuariosData = await getUsuarios();
    setUsuariosData(fetchedUsuariosData);
    
    //setPage(fetchedUsuariosData.paginacion.paginaActual);
    //setRowsPerPage(fetchedUsuariosData.paginacion.elementosPorPagina);
    //setTotalItems(fetchedUsuariosData.paginacion.totalFilas);
    //setTotalPaginas(fetchedUsuariosData.paginacion.totalPaginas);
    //printLog("seteando loading a false");
    setLoading(false);
  }

  printLog("render usuario page :: ");

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const createOrUpdate = async (data: any) => {
    printLog("DATA usuario :: ", data);
    
    if (!usuario) {
      const resultCreate = await createUsuario(data);
      if (resultCreate) {
        setLoading(true);
        await fetchUsuarios();
        handleCloseModalUsuario();
        // showAlertSuccess({
        //   btnText: "Aceptar",
        //   message: "¡El usuario fue creado exitosamente!",
        // });
        Alerts.showSuccess({
          description: "¡El usuario fue creado exitosamente!",
        })
      }
    } else {
      
      const dataUpdated = {
        ...data,
      };

      const resultUpdate = await updateUsuario(dataUpdated, usuario["id"]);
      
      if (resultUpdate) {
        setLoading(true);
        await fetchUsuarios();
        handleCloseModalUsuario();
        // showAlertSuccess({
        //   btnText: "Aceptar",
        //   message: "¡Los datos del usuario fueron actualizados exitosamente!",
        // });
        Alerts.showSuccess({
          description: "¡Los datos del usuario fueron actualizados exitosamente!",
        })
      }
    }
  }

  const handleChangePage = async (event: unknown, newPage: number) => {
    // await fetchUsuarios(filters, newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
    ) => {
    
    //await fetchUsuarios(filters, page, parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleEditarUsuario = async (usuarioId: string) => {
    const fetchedUsuario = await getUsuario(usuarioId);
    setUsuario(fetchedUsuario);
    setUserDialog(true);
  };

  const handleRestablecerContrasena = async (usuario: any) => {
    // showConfirm({
    Alerts.showConfirm({
      title: '¿Estás segura(o) de restablecer la contraseña del usuario?.',
      description: '',
      onConfirm: async () => {
        try {
          await authContext.resetPassword(usuario.email);
          // showAlertSuccess({
          //   btnText: 'Aceptar',
          //   title: 'Reestablecimiento de Contraseña',
          //   message: `En este momento estamos enviando instrucciones a ${usuario.email} para que pueda restablecer su contraseña.`,
          // });
          Alerts.showSuccess({
            confirmText: 'Aceptar',
            title: 'Reestablecimiento de Contraseña',
            description: `En este momento estamos enviando instrucciones a ${usuario.email} para que pueda restablecer su contraseña.`,
          });
        } catch (error) {
          printLog('Error al reestablecer :: ', error);
          // showAlertError({
          //   btnText: 'Continuar',
          //   title: 'Reestablecimiento de Contraseña',
          //   message: 'Ocurrió un error al intentar reestablecer la contraseña, por favor intente mas tarde.',
          // });
          Alerts.showError({
            confirmText: 'Continuar',
            title: 'Reestablecimiento de Contraseña',
            description: `Ocurrió un error al intentar reestablecer la contraseña, por favor intente mas tarde.`,
          });
        }
      }
    });
  }

  const handleEliminarUsuario = async (usuarioId: string) => {
    // showConfirm({
    Alerts.showConfirm({
      title: '¿Estás segura(o) de eliminar al usuario?',
      description: '',
      onConfirm: async () => {
        const userDeleted = await deleteUsuario(usuarioId);
        await fetchUsuarios();
        if (userDeleted) {
          // showAlertSuccess({
          //   btnText: "Aceptar",
          //   message: "¡El usuario fue eliminado exitosamente!",
          // });
          Alerts.showSuccess({
            confirmText: 'Aceptar',
            description: `¡El usuario fue eliminado exitosamente!`,
          });
        }
      }
    })
  }

  const handleCloseModalUsuario = () => {
    setUsuario(null);
    setUserDialog(false);
  };

  const handleChangeEstado = (
    event: React.ChangeEvent<HTMLInputElement>,
    usuarioId: string
  ) => {
    // printLog('Nuevo estado ::: ', event.target.checked);
    const habilitarUsuario = event.target.checked;

    Alerts.showConfirm({
      title: `¿Estás seguro de ${
        habilitarUsuario ? "habilitar" : "inhabilitar"
      } al usuario?`,
      description: '',
      onConfirm: async () => {
        if (habilitarUsuario) {
          printLog("Cambiar el estado del usuario a ACTIVO");
          const resultActivo = await updateEstadoUsuario(true, usuarioId);
          printLog(
            "Resultado de actualizacion de estado usuario :: ",
            resultActivo
          );
          if (resultActivo) {
            setLoading(true);
            await fetchUsuarios();
            handleCloseModalUsuario();
            // showAlertSuccess({
            //   btnText: "Aceptar",
            //   message: "¡El usuario fue habilitado exitosamente!",
            // });
            Alerts.showSuccess({
              confirmText: 'Aceptar',
              description: "¡El usuario fue habilitado exitosamente!",
            })
          }
        } else {
          printLog("Cambiar el estado del usuario a INACTIVO");
          const resultInactivo = await updateEstadoUsuario(
            false,
            usuarioId
          );
          printLog(
            "Resultado de actualizacion de estado usuario :: ",
            resultInactivo
          );
          if (resultInactivo) {
            setLoading(true);
            await fetchUsuarios();
            handleCloseModalUsuario();
            // showAlertSuccess({
            //   btnText: "Aceptar",
            //   message: "¡El usuario fue inhabilitado exitosamente!",
            // });
            Alerts.showSuccess({
              confirmText: 'Aceptar',
              description: "¡El usuario fue inhabilitado exitosamente!",
            })
          }
        }
      }
    })
  };

  const handleBuscarUSuario = (data: UsuarioFormFields) => {
    // setFilters(data);
    fetchUsuarios();
  }
  const handleResetFilters =() => {
    setFilters({});
    fetchUsuarios();
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={6}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate.push("/", {
                replace: true,
              })
            }
          >
            <KeyboardBackspace />
          </IconButton>
          <Typography
            component={"span"}
            sx={{ ...DesignSystem?.titulo1, pl: 2 }}
          >
            Usuarios
          </Typography>
        </Box>
        <Box>
          <CoursePrimaryButton
            onClick={() => {
              setUserDialog(true);
            }}
          >
            Agregar +
          </CoursePrimaryButton>
        </Box>
      </Box>
      {loading && <>
        <LinearProgress />
        <TableSkeleton filas={5} columnas={7} />
      </>}
      {!loading && usuariosData && (
        <>
          <Box sx={{ width: "100%" }} mb={15}>
            <Paper sx={{ width: "100%", mb: 2 }} elevation={0}>
              {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
              {/* <Box sx={{ p: 3 }}>
                <FilterButton onClick={() => setViewFilters(!viewFilters)}>
                  <img src={filterIcon} alt="filter-icon" />
                </FilterButton>
              </Box> */}
              {/* <Box sx={{ px: 4 }}>
                {viewFilters  && (
                  <UsuarioFilters
                    onSearch={handleBuscarUSuario}
                    onReset={handleResetFilters}
                    roles={usuariosData.roles}
                    regiones={usuariosData.regiones}
                    defaultValues={filters}
                  />
                )}
              </Box> */}
              {/* <Toolbar>
              </Toolbar> */}
              { !matchesPhone && mostrarTabla && <>
                <TableContainer sx={{ px: 3, py: 1 }}>
                  <Table
                    sx={{ minWidth: 750, borderCollapse: "separate" }}
                    size="medium"
                  >
                    <TableHead>
                      <TableRow>
                        {cabeceras.map((headCell) => (
                          <StyledTableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                          >
                            <Typography
                              component={"span"}
                              sx={{ ...DesignSystem?.titulo3 }}
                            >
                              {headCell.label}
                            </Typography>
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usuariosData.map((row: any, index) => {
                        return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell>
                                <IconButton
                                  size="small"
                                  onClick={() => handleEditarUsuario(row.id)}
                                  sx={{ fill: colorsBase.Azul1 }}
                                >
                                  <PencilIcon />
                                </IconButton>
                                {/* <IconButton
                                  size="small"
                                  sx={{ fill: colorsBase.Azul1 }}
                                  onClick={() => handleRestablecerContrasena(row)}
                                >
                                  <img src={lockOpenIcon} alt="icon-lock" />
                                </IconButton> */}
                                <IconButton
                                  size="small"
                                  onClick={() => handleEliminarUsuario(row.id)}
                                  sx={{ fill: colorsBase.Azul1 }}
                                >
                                  <TrashIcon />
                                </IconButton>
                                {!row.lastName1 && <IconButton
                                  size="small"
                                  onClick={() => {
                                  }}
                                  sx={{fill: colorsBase.Naranja1}}
                                >
                                  <Tooltip title={"Por favor verificar el nombre y apellidos del usuario"}>
                                    <SeeIcon/>
                                  </Tooltip>
                                </IconButton>}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.cuerpo3 }}
                                >
                                  {row.fullName}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.cuerpo3 }}
                                >
                                  {row.email}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.cuerpo3 }}
                                >
                                  {row.rol}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.cuerpo3 }}
                                >
                                  {row.enabled ? "ACTIVO" : "INACTIVO"}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <MaterialUISwitch
                                  sx={{ m: 1 }}
                                  checked={row.enabled}
                                  onChange={(ev) =>
                                    handleChangeEstado(ev, row.id)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>}
              { matchesPhone && mostrarTabla && <>
                {usuariosData.map((row: any, index) => {
                    return (
                      <Card key={index} sx={{ px: 2, py: .5, borderBottom: `1px solid ${colorsBase.BorderGray}`} } elevation={0}>
                        <Accordion elevation={0}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.titulo4 }}
                                >
                                  Nombre Completo
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.cuerpo3 }}
                                >
                                  {row.fullName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails sx={{ my: 0, py: 0 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.titulo4 }}
                                >
                                  Correo Electrónico
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.cuerpo3 }}
                                >
                                  {row.email}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.titulo4 }}
                                >
                                  Rol
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.cuerpo3 }}
                                >
                                  {row.rol}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.titulo4 }}
                                >
                                  Estado
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.cuerpo3 }}
                                >
                                  {row.enabled ? "ACTIVO" : "INACTIVO"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.titulo4 }}
                                >
                                  Cambio de Estado
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <MaterialUISwitch
                                  size="small"
                                  sx={{ m: 0 }}
                                  checked={row.enabled}
                                  onChange={(ev) =>
                                    handleChangeEstado(ev, row.id)
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography
                                  component={"span"}
                                  sx={{ ...DesignSystem?.titulo4 }}
                                >
                                  Acciones
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <IconButton
                                  size="small"
                                  onClick={() => handleEditarUsuario(row.id)}
                                  sx={{ fill: colorsBase.Azul1 }}
                                >
                                  <PencilIcon />
                                </IconButton>
                                {/*<IconButton*/}
                                {/*  size="small"*/}
                                {/*  sx={{ fill: colorsBase.Azul1 }}*/}
                                {/*  onClick={() => handleRestablecerContrasena(row)}*/}
                                {/*>*/}
                                {/*  <img src={lockOpenIcon} alt="icon-lock" />*/}
                                {/*</IconButton>*/}
                                {/*<IconButton*/}
                                {/*  size="small"*/}
                                {/*  onClick={() => handleEliminarUsuario(row.id)}*/}
                                {/*  sx={{ fill: colorsBase.Azul1 }}*/}
                                {/*>*/}
                                {/*  <TrashIcon />*/}
                                {/*</IconButton>*/}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Card>
                    );
                  })}
              </>}
              {/* {mostrarFooter && <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                labelRowsPerPage={
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo3, [theme.breakpoints.down('sm')]: {
                      ...DesignSystem?.cuerpo4
                    } }}
                  >
                    Mostrar
                  </Typography>
                }
                labelDisplayedRows={({ from, to, count }) => (
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo3, [theme.breakpoints.down('sm')]: {
                      ...DesignSystem?.cuerpo4
                    } }}
                  >
                    Mostrando {from} a {to} de {count} filas
                  </Typography>
                )}
                component="div"
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />} */}
            </Paper>
          </Box>
        </>
      )}

      {userDialog &&
        <UsuarioForm
          userDialog={userDialog}
          usuario={usuario}
          onClose={handleCloseModalUsuario}
          onCreateOrUpdate={createOrUpdate}
          usuariosData={usuariosData}
        />
      }

    </>
  );
}
