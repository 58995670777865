import axios, { AxiosRequestConfig, Method } from "axios";
// import { useAuthContext } from "./auth/AuthContext";
import dayjs from "dayjs";
import { printLog } from "../utils";
// import { Alerts } from "./useAlert";
// configurations
// import { API_URL } from '../config/app-config';

// const urlBase = API_URL;
const urlBase = "";

const getHeaders = async (auth?: string, withFiles?: boolean): Promise<any> => {
  const DEFAULT_HEADERS = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: auth ? `Bearer ${auth}` : "",
  };
  const DEFAULT_FILES_HEADERS = {
    Authorization: auth ? `Bearer ${auth}` : "",
    "Content-type": "multipart/form-data",
  };
  return new Promise((resolve) => {
    if (withFiles) return resolve(DEFAULT_FILES_HEADERS);
    return resolve(DEFAULT_HEADERS);
  });
};

const _http = async (
  method: Method,
  url: string,
  data?: any,
  auth?: string,
  withFiles?: boolean
) => {
  const newHeaders = await getHeaders(auth, withFiles);
  return new Promise((resolve, reject) => {
    url = urlBase + url;
    const config: AxiosRequestConfig = {
      method,
      url,
      data,
      headers: newHeaders,
    };

    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        printLog("Error :: tst :: ", error.response);
        if (error.response?.data?.error) {
          // Alerts.showError({
          //   description: error.response?.data?.error,
          //   confirmText: "Aceptar",
          // });
          printLog(error.response?.data?.error);
          reject(error.response?.data);
          return;
        }
        if (error.response?.data?.message) {
          // Alerts.showError({
          //   description: error.response?.data?.message,
          //   confirmText: "Aceptar",
          // });
          printLog(error.response?.data?.error);
          reject(error.response?.data);
          return;
        }
        // reject(error.response?.data);
      });
  });
};

type RequestParams<T> = {
  payload?: T;
  auth?: string;
  withFiles?: boolean;
};

const useHttpExternal = function () {
  // const authContext = useAuthContext();
  // const authToken = authContext?.user?.token;

  return {
    async file(url: string, nombreReporte: string) {
      return axios({
        url: urlBase + url,
        method: "GET",
        // headers: {
        //   'Authorization': 'Bearer ' + await authContext.getCurrentTokenID() || ''
        // },
        responseType: "blob", // very important -> no tocar
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${nombreReporte.replace(" ", "-")}-${dayjs().format(
            "YYYY-MM-DD-HH:mm:ss"
          )}.xlsx`
        );
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
      });
    },

    async get<T>(url: string, params: RequestParams<T>) {
      // const token = await authContext.getCurrentTokenID();
      // printLog('token refrescado !!! ', token)
      // const _auth = await authContext.getCurrentTokenID() || '';
      return _http(
        "get",
        url,
        params.payload,
        // _auth ?? authToken ?? params.auth ?? undefined,
        undefined,
        false
      );
    },

    async post<T>(url: string, params: RequestParams<T>) {
      // const _auth = await authContext.getCurrentTokenID() || '';
      return _http(
        "post",
        url,
        params.payload,
        // _auth?? authToken ?? undefined,
        undefined,
        params.withFiles
      );
    },

    async put<T>(url: string, params: RequestParams<T>) {
      // const _auth = await authContext.getCurrentTokenID() || '';
      return _http(
        "put",
        url,
        params.payload,
        // _auth ?? authToken ?? undefined,
        undefined,
        params.withFiles
      );
    },

    async patch<T>(url: string, params: RequestParams<T>) {
      // const _auth = await authContext.getCurrentTokenID() || '';
      return _http(
        "patch",
        url,
        params.payload,
        // _auth ?? authToken ?? undefined,
        undefined,
        params.withFiles
      );
    },

    async delete<T>(url: string, params: RequestParams<T>) {
      // const _auth = await authContext.getCurrentTokenID() || '';
      return _http(
        "delete",
        url,
        params.payload,
        // _auth ?? authToken ?? undefined
        undefined
      );
    },
  };
};

export default useHttpExternal;
