import { Backdrop, Box, CircularProgress, Typography, alpha } from '@mui/material'
import theme from '../../themes';

type CLoaderProps = {
  message: string;
}

const Cloader = ({
  message
}: CLoaderProps) => {
  return <Backdrop
    sx={{
      background: alpha('#f8f5f7af', 0.9),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      color: theme.palette.primary.main,
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }}
    open
  >
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: alpha(theme.palette.common.black, 0.15),
      position: 'absolute',
      top: 0, bottom: 0, left: 0, right: 0
    }}>
      <CircularProgress color="inherit" size={80} thickness={5} />
      <Typography component={'p'} sx={{
        mt: 3,
        fontSize: '1.2rem',
        fontWeight: 500,
        color: theme.palette.primary.main,
      }}>
        { message }
      </Typography>
    </Box>
  </Backdrop>
}

export default Cloader;