import {
  IconButton,
  Chip,
  Box,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import CInputForm from "../../../shared/components/form/CInputForm";
import { useCourseContext } from "../context";
import { ChangeEvent } from "react";
import { CFilePicker } from "../../../shared/components/form/CFilePicker";
import { CancelRounded } from "@mui/icons-material";
import { CourseNeutralButton, CoursePrimaryButton } from "../shared/Elements";
import { printLog } from "../../../shared/utils";

type GeneralCourseDataProps = {
  onCancel: () => void;
  onNextStep: () => void;
};

function GeneralCourseData({ onCancel, onNextStep }: GeneralCourseDataProps) {
  const theme = useTheme();
  const [course, dispatch] = useCourseContext();
  printLog("[Detail][GeneralCourseData] > course > ", course);

  const handleImagenCursoChange = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "SET_IMAGEN_CURSO", file: target.files![0] });
  };

  const handleInputChange =
    (prop: "nombre" | "descripcion") =>
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: "EDIT_VALORES_CURSO",
        payload: {
          [prop]: target.value,
        },
      });
    };

  const handleImagenCursoDelete = () =>
    dispatch({ type: "DELETE_IMAGEN_CURSO" });

  return (
    <>
      <Box sx={{ flexGrow: 1 }} py={2}>
        <Box sx={{ textAlign: "center" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
          >
            <Typography component={"span"}>
              DATOS GENERALES DEL CURSO
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            px={5}
            pt={5}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item container xs={12} md={6}>
                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                  <Typography component={"span"}>Imagen del curso</Typography>
                </Grid>
                {course?.urlImagen && (
                  <Grid item xs={12} md={9}>
                    <Box
                      sx={{
                        width: "50%",
                        [theme.breakpoints.down("md")]: {
                          width: "50px",
                        },
                      }}
                    >
                      <img
                        src={course?.urlImagen}
                        style={{
                          padding: 0,
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                        alt={`img-plantilla-${course?.id}`}
                      />
                    </Box>
                  </Grid>
                )}
                {
                  <Grid item container direction="column" xs={12} md={9}>
                    {course.imagenCurso === undefined && (
                      <Grid item>
                        <CFilePicker
                          placeholder="Carga la imagen del curso"
                          name="imagenCurso"
                          handleChange={handleImagenCursoChange}
                        />
                      </Grid>
                    )}
                    {course.imagenCurso !== undefined && (
                      <Grid item alignSelf="flex-start">
                        <Chip
                          deleteIcon={
                            <IconButton size="small" sx={{ stroke: "red" }}>
                              <CancelRounded width="20" height="20" />
                            </IconButton>
                          }
                          onDelete={handleImagenCursoDelete}
                          label={course.imagenCurso.name}
                          sx={{
                            margin: 0.5,
                            border: `1px solid green`,
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                }
              </Grid>

              <Grid item container xs={12} md={6}>
                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                  <Typography component={"span"}>Nombre del curso</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <CInputForm
                    type="text"
                    placeholder="Escribe el nombre del curso"
                    name="nombre"
                    value={course.nombre}
                    onChange={handleInputChange("nombre")}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} md={6}>
                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                  <Typography component={"span"}>
                    Descripción del curso
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <CInputForm
                    type="text"
                    placeholder="Escribe la descripción del curso"
                    multiline
                    rows={3}
                    name="descripcion"
                    value={course.descripcion}
                    onChange={handleInputChange("descripcion")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box display="flex" gap={2} justifyContent="flex-end" mt={5}>
          <CourseNeutralButton onClick={onCancel}>Cancelar</CourseNeutralButton>
          <CoursePrimaryButton onClick={onNextStep}>
            Continuar
          </CoursePrimaryButton>
        </Box>
      </Box>
    </>
  );
}

export default GeneralCourseData;
