import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import MainBanner from "./components/MainBanner";
import Rights from "./components/Rights";
import MunicipalitiesWhiteArea from "./components/MunicipalitiesWhiteArea";
import TimelineWhoWeAre from "./components/TimelineWhoWeAre";
import Footer from "../../components/Footer";
import GrayDescription from "./components/GrayDescription";
import ReactGA from "react-ga4";

const WhoWeAre = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (id === "nuestraHistoria") {
          if (element) {
            const offset = element.offsetTop - 80;
            window.scrollTo({ top: offset, behavior: "smooth" });
          }
        } else {
          if (element) {
            element.scrollIntoView();
          }
        }
      }, 0);
    }

    window.scrollTo(0, 0);
  }, [pathname, hash, key]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/quienes-somos",
      title: "Quienes somos",
    });
  }, []);

  return (
    <Layout>
      <MainBanner />
      <GrayDescription />
      <Rights />
      {/*<KeyActorsBanner />*/}
      <MunicipalitiesWhiteArea />
      <TimelineWhoWeAre />
      <Footer />
    </Layout>
  );
};

export default WhoWeAre;
