import { Box, Typography, styled } from "@mui/material";
import SquareIcon from '@mui/icons-material/Square';
import theme from "../../../../../../themes";
import useAppContext from "../../../../../../shared/hooks/useAppContext";
import { useEffect, useState } from "react";

const BoxT = styled(Box)(() => ({
  '&:hover': {
    backgroundColor: '#FFFFFF',
    padding: '0 0em 0 0em',
    borderRadius: '8px',
    width: '80%'
  }
}));

const DepartmentList = () => {
  const { 
    goodPractices
  } = useAppContext();

  const [count, setCount] = useState({
    lpz: 0,
    cbba: 0,
    oru: 0,
    pnd: 0,
    psi: 0,
    scz: 0,
    tja: 0,
    bni: 0,
    chq: 0,
  });

  useEffect(() => {
    
    if (goodPractices.length === 0) {
      setCount({
        lpz: 0,
        cbba: 0,
        oru: 0,
        pnd: 0,
        psi: 0,
        scz: 0,
        tja: 0,
        bni: 0,
        chq: 0,
      });
      return;
    }

    let lpz = 0;
    let cbba = 0;
    let oru = 0;
    let pnd = 0;
    let psi = 0;
    let scz = 0;
    let tja = 0;
    let bni = 0;
    let chq = 0;

    for(let i=0; i<goodPractices.length; i++) {
      let obj = goodPractices[i].departamento_desc;

      if(obj === 'LA PAZ'){
        lpz++;
      }
      else if(obj === 'COCHABAMBA'){
        cbba++;
      }
      else if(obj === 'ORURO'){
        oru++;
      }
      else if(obj === 'PANDO'){
        pnd++;
      }
      else if(obj === 'POTOSI'){
        psi++;
      }
      else if(obj === 'SANTA CRUZ'){
        scz++;
      }
      else if(obj === 'TARIJA'){
        tja++;
      } 
      else if(obj === 'BENI'){
        bni++;
      }
      else if(obj === 'CHUQUISACA'){
        chq++;
      }
      setCount({lpz, cbba, oru, pnd, psi, scz, tja, bni, chq})
    }

  }, [goodPractices]);

  return (
    <Box display="flex" flexDirection="column" sx={{ padding: '0.5em 0 0.5em 0' }}>
      <Box display="flex" sx={{ width: '100%' }}>
        <Box display="flex" sx={{ width: '50%', padding: '0 1.5em 0.em 0em' }}>
          <BoxT display="flex">
            <Box display="flex" alignItems="center" height="100%" sx={{ fontSize: '10px' }} pl='0.5em' pr="0.8em">
              <SquareIcon fontSize="inherit" color="primary" />
            </Box>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16 }}>
              La Paz
            </Typography>
            <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }} pl="0.5em">
               {`(${count['lpz']})`}
            </Typography>
          </BoxT>
        </Box>
        <Box display="flex" sx={{ width: '50%', padding: '0 1.5em 0.em 0em' }}>
          <BoxT display="flex">
            <Box display="flex" alignItems="center" height="100%" sx={{ fontSize: '10px' }} pl='0.5em' pr="0.8em">
              <SquareIcon fontSize="inherit" color="primary" />
            </Box>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16 }}>
              Tarija
            </Typography>
            <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }} pl="0.5em">
              {`(${count['tja']})`}
            </Typography>
          </BoxT>
        </Box>
      </Box>
      <Box display="flex" sx={{ width: '100%' }}>
        <Box display="flex" sx={{ width: '50%', padding: '0 1.5em 0.em 0em' }}>
          <BoxT display="flex">
            <Box display="flex" alignItems="center" height="100%" sx={{ fontSize: '10px' }} pl='0.5em' pr="0.8em">
              <SquareIcon fontSize="inherit" color="primary" />
            </Box>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16 }}>
              Oruro
            </Typography>
            <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }} pl="0.5em">
              {`(${count['oru']})`}
            </Typography>
          </BoxT>
        </Box>
        <Box display="flex" sx={{ width: '50%', padding: '0 1.5em 0.em 0em' }}>
          <BoxT display="flex">
            <Box display="flex" alignItems="center" height="100%" sx={{ fontSize: '10px' }} pl='0.5em' pr="0.8em">
              <SquareIcon fontSize="inherit" color="primary" />
            </Box>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16 }}>
              Santa Cruz
            </Typography>
            <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }} pl="0.5em">
              {`(${count['scz']})`}
            </Typography>
          </BoxT>
        </Box>
      </Box>
      <Box display="flex" sx={{ width: '100%' }}>
        <Box display="flex" sx={{ width: '50%', padding: '0 1.5em 0.em 0em' }}>
          <BoxT display="flex">
            <Box display="flex" alignItems="center" height="100%" sx={{ fontSize: '10px' }} pl='0.5em' pr="0.8em">
              <SquareIcon fontSize="inherit" color="primary" />
            </Box>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16 }}>
              Potosí
            </Typography>
            <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }} pl="0.5em">
              {`(${count['psi']})`}
            </Typography>
          </BoxT>
        </Box>
        <Box display="flex" sx={{ width: '50%', padding: '0 1.5em 0.em 0em' }}>
          <BoxT display="flex">
            <Box display="flex" alignItems="center" height="100%" sx={{ fontSize: '10px' }} pl='0.5em' pr="0.8em">
              <SquareIcon fontSize="inherit" color="primary" />
            </Box>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16 }}>
              Beni
            </Typography>
            <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }} pl="0.5em">
              {`(${count['bni']})`}
            </Typography>
          </BoxT>
        </Box>
      </Box>
      <Box display="flex" sx={{ width: '100%' }}>
        <Box display="flex" sx={{ width: '50%', padding: '0 1.5em 0.em 0em' }}>
          <BoxT display="flex">
            <Box display="flex" alignItems="center" height="100%" sx={{ fontSize: '10px' }} pl='0.5em' pr="0.8em">
              <SquareIcon fontSize="inherit" color="primary" />
            </Box>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16 }}>
              Chuquisaca
            </Typography>
            <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }} pl="0.5em">
              {`(${count['chq']})`}
            </Typography>
          </BoxT>
        </Box>
        <Box display="flex" sx={{ width: '50%', padding: '0 1.5em 0.em 0em' }}>
          <BoxT display="flex">
            <Box display="flex" alignItems="center" height="100%" sx={{ fontSize: '10px' }} pl='0.5em' pr="0.8em">
              <SquareIcon fontSize="inherit" color="primary" />
            </Box>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16 }}>
              Pando
            </Typography>
            <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }} pl="0.5em">
              {`(${count['pnd']})`}
            </Typography>
          </BoxT>
        </Box>
      </Box>
      <Box display="flex" sx={{ width: '100%' }}>
        <Box display="flex" sx={{ width: '50%', padding: '0 1.5em 0.em 0em' }}>
          <BoxT display="flex">
            <Box display="flex" alignItems="center" height="100%" sx={{ fontSize: '10px' }} pl='0.5em' pr="0.8em">
              <SquareIcon fontSize="inherit" color="primary" />
            </Box>
            <Typography sx={{ fontFamily: 'Ubuntu', fontSize: 16 }}>
              Cochabamba
            </Typography>
            <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }} pl="0.5em">
              {`(${count['cbba']})`}
            </Typography>
          </BoxT>
        </Box>
      </Box>
    </Box>
  )
};

export default DepartmentList;