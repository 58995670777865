import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { CircleBox, PrimaryButtonText } from "../shared/Elements";
import unidadIcon from "./../../../../assets/course/subir_archivo.png";
import { printLog } from "../../../../shared/utils";
import {
  AddRounded,
  AudiotrackRounded,
  DeleteOutlineRounded,
  DescriptionRounded,
  EditRounded,
  PlayCircleRounded,
} from "@mui/icons-material";
import { DialogCampaignResourceForm } from "./DialogCampaignResourceForm";
import { useCampaignContext } from "../context";
import { Alerts } from "../../../../shared/hooks/useAlert";

export const DetailCampaignResources = () => {
  const [campaign, dispatch] = useCampaignContext();
  const [modalRecurso, setModalRecurso] = useState<boolean>(false);
  const [idxRecursoSelected, setIdxRecursoSelected] = useState<string | null>(
    null
  );

  const handleOpenModalRecurso = () => {
    setModalRecurso(true);
  };

  const handleCloseModalRecurso = () => {
    setModalRecurso(false);
    setIdxRecursoSelected(null);
  };

  return (
    <React.Fragment>
      <Box p={3}>
        <Box sx={{ textAlign: "center" }}>
          <Box display="flex">
            <Typography variant="h6" fontWeight={"bold"}>
              RECURSOS DE LA CAMPAÑA
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
          >
            <Box
              sx={{
                height: "5rem",
                borderRadius: "10px",
                overflow: "auto",
                display: "flex",
              }}
            >
              <img
                src={unidadIcon}
                width={"100%"}
                height={"100%"}
                alt="unidad-icon"
              />
            </Box>
            <PrimaryButtonText onClick={handleOpenModalRecurso}>
              <CircleBox sx={{ mr: 0.8 }}>
                <AddRounded sx={{ fontSize: "14pt" }} />
              </CircleBox>
              Nuevo recurso
            </PrimaryButtonText>
          </Box>
          <Box my={5} />
          <Box my={2} display={"flex"} flexDirection={"column"} gap={3}>
            {campaign.recursos &&
              !!campaign.recursos.length &&
              campaign.recursos.map((recurso, idxRecurso) => (
                <Box
                  key={idxRecurso}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={2}
                >
                  {recurso.tipo === "VIDEO" && (
                    <PlayCircleRounded fontSize="large" />
                  )}
                  {recurso.tipo === "AUDIO" && (
                    <AudiotrackRounded fontSize="large" />
                  )}
                  {recurso.tipo === "ARCHIVO" && (
                    <DescriptionRounded fontSize="large" />
                  )}
                  <Box
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #cacaca",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography component="p" sx={{ paddingLeft: 1 }}>
                        {recurso.titulo}
                      </Typography>
                      <Box>
                        <IconButton
                          size="small"
                          sx={{ fill: "red" }}
                          onClick={() => {
                            printLog("handle editar recurso > ", recurso);
                            setIdxRecursoSelected(`${idxRecurso}`);
                            setModalRecurso(true);
                          }}
                        >
                          <EditRounded />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{ fill: "red" }}
                          onClick={() => {
                            printLog("handle eliminar recurso > ", recurso.id);
                            Alerts.showConfirm({
                              title: "¿Estás segura(o) de eliminar el recurso?",
                              description:
                                "Tenga en cuenta que no podrá recuperar los datos del recurso eliminado luego de confirmar.",
                              onConfirm: () => {
                                dispatch({
                                  type: "DELETE_RESOURCE",
                                  idxResource: idxRecurso,
                                });
                              },
                            });
                          }}
                        >
                          <DeleteOutlineRounded />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>

      {modalRecurso && (
        <DialogCampaignResourceForm
          open={modalRecurso}
          onClose={handleCloseModalRecurso}
          idxResource={idxRecursoSelected}
        />
      )}
    </React.Fragment>
  );
};
