import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from '../../components/Layout';
import MainBanner from './components/MainBanner';
import WhiteExperiencesArea from './components/WhiteExperiencesArea';
import Footer from '../../components/Footer';
import useAppContext from "../../shared/hooks/useAppContext";

const AllInnovativeExperiences = () => {

  const { pathname, hash, key } = useLocation();

  const { 
    getInnovativeExperiences
  } = useAppContext();

  useEffect(() => {
    getInnovativeExperiences();
  }, [getInnovativeExperiences]);

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }

    window.scrollTo(0, 0);
  }, [pathname, hash, key]);

  return (
    <Layout>
      <MainBanner />
      <WhiteExperiencesArea />
      <Footer />
    </Layout>
  )
};

export default AllInnovativeExperiences;