// import { useAuthContext } from "../../common/auth/AuthContext";
// import useHttp from "../../common/useHttp";
import {useAuthContext} from "../../shared/auth/AuthContext";
import useHttp from "../../shared/hooks/useHttp";
import {
  CurrentLoginResult,
  Genero,
  PertenenciaEtnica,
  RolUsuario,
  UsuarioDetalles,
} from "./interfaces/UsuarioInterface";
import dayjs from "dayjs";

export interface FiltrosUsuario {
  nombres: string;
  primerApellido: string;
  segundoApellido: string;
  rol: RolUsuario;
  estado: "ACTIVO" | "INACTIVO" | "";
  regionId: string;
}

type UsuariosGetParams = Partial<FiltrosUsuario> & {
  page: number;
  rowsPerPage: number;
};

// export function useGetUsuarios(): (params: UsuariosGetParams) => Promise<any> {
//   const http = useHttp();
//   return async (params) => {
//     printLog("params :: ", params);
//     const { page, rowsPerPage, ...filtros } = params;
//     const filtrosQuery = new URLSearchParams(filtros as Record<string, string>);

//     // /api/usuario?paginaActual=1&elementosPorPagina=10
//     return await http.get(
//       `/usuario?paginaActual=${page || 1}&elementosPorPagina=${
//         rowsPerPage || 10
//       }&${filtrosQuery}`,
//       {}
//     );
//   };
// }

export function useGetUsuarios(): () => Promise<any> {
  const http = useHttp();
  return async () => {
    return await http.get(`/admin/usuario`, {});
  };
}

// export function useGetUsuarioById(): (usuarioId: string) => Promise<any> {
//   const http = useHttp();
//   const authContext = useAuthContext();
//   return async (usuarioId: string) => {
//     const result: any = await http.get(`/usuario/${usuarioId}`, {
//       auth: authContext?.user?.token ?? undefined,
//     });
//     /// TODO: devolver la fecha parseado desde base
//     if (result["fechaNacimiento"]) {
//       const nuevaFecha = result["fechaNacimiento"].split("/");
//       result["fechaNacimiento"] = dayjs(
//         `${nuevaFecha[2]}-${nuevaFecha[1]}-${nuevaFecha[0]}`
//       );
//     }
//     return result;
//   };
// }

export function useGetUsuarioById(): (id: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (id: string) => {
    const result: any = await http.get(`/admin/usuario/${id}`, {
      auth: authContext?.user?.token ?? undefined,
    });
    /// TODO: devolver la fecha parseado desde base
    if (result["birthDate"]) {
      const nuevaFecha = result["birthDate"].split("/");
      result["birthDate"] = dayjs(
        `${nuevaFecha[2]}-${nuevaFecha[1]}-${nuevaFecha[0]}`
      );
    }
    return result;
  };
}

// POST /api/usuario
type CreacionUsuarioParams = {
  email: string;
  rol: RolUsuario;
  nombres: string;
  primerApellido: string;
  segundoApellido: string | null;
  pertenenciaEtnica: PertenenciaEtnica | null;
  telefono: string;
  numeroCedula: string | null;
  direccion: string;
  fechaNacimiento: string;
  genero: Genero;
  regionId: string | null;
  contrasena: string;
  confirmacionContrasena: string;
};

// export function useCreateUsuario(): (
//   // params: CreateUsuarioConRolParamsType
//   params: UsuarioDetalles
// ) => Promise<any> {
//   const http = useHttp();
//   return async (params: UsuarioDetalles) => {
//     const data: CreacionUsuarioParams = {
//       ...params,
//       fechaNacimiento: dayjs(params.fechaNacimiento).format("DD/MM/YYYY"),
//     };
//     return await http.post<CreacionUsuarioParams>("/usuario/", {
//       payload: data,
//     });
//   };
// }

type CreateAdminUsuarioParams = {
  email: string;
  fullName: string;
  password: string;
  confirmPassword: string;
  birthDate: string;
  phoneNumber: string;
  departamento_cod: number;
  municipio_cod: number;
  institution: string;
  position: string;
  rol: string;
};

export function useCreateUsuario(): (
  params: CreateAdminUsuarioParams
) => Promise<any> {
  const http = useHttp();
  return async (params: CreateAdminUsuarioParams) => {
    return await http.post<CreateAdminUsuarioParams>("/admin/usuario", {
      payload: params,
    });
  };
}

export interface ModificacionUsuarioParams extends UsuarioDetalles {
  // contrasena: string | null; // TODO: no deberia enviarse en este flujo
  // confirmacionContrasena: string | null;
  estado: boolean;
}

// export function useUpdateUsuario(): (
//   params: UsuarioDetalles,
//   usuarioId: string
// ) => Promise<any> {
//   const http = useHttp();
//   return async (params: any, usuarioId: string) => {
//     const data = {
//       ...params,
//       fechaNacimiento: dayjs(params.fechaNacimiento).format("DD/MM/YYYY"),
//       contrasena: null, // TODO: no deberia enviarse en este flujo
//       confirmacionContrasena: null, // TODO: no deberia enviarse en este flujo
//     };
//     // printLog('in update :: dayjs(params.fechaNacimiento).format(DD/MM/YYYY) :: ', dayjs(params.fechaNacimiento).format('DD/MM/YYYY'));
//     return await http.put<ModificacionUsuarioParams>(`/usuario/${usuarioId}`, {
//       payload: data,
//     });
//   };
// }
type UpdateAdminUsuarioParams = {
  id: string;
  email: string;
  fullName: string;
  password: string;
  confirmPassword: string;
  birthDate: string;
  phoneNumber: string;
  municipio_cod: number;
  institution: string;
  position: string;
  rol: string;
};

export function useUpdateUsuario(): (
  params: UpdateAdminUsuarioParams,
  id: string
) => Promise<any> {
  const http = useHttp();
  return async (params: UpdateAdminUsuarioParams, id: string) => {
    return await http.put<UpdateAdminUsuarioParams>(`/admin/usuario/${id}`, {
      payload: params,
    });
  };
}

export function useUpdateEstadoUsuario(): (
  estado: boolean,
  usuarioId: string
) => Promise<any> {
  const http = useHttp();
  return async (estado: boolean, usuarioId: string) => {
    return await http.put(`/admin/usuario/${usuarioId}/estado`, {
      payload: {
        estado,
      },
    });
  };
}

export function useGetSessionInfo(): () => Promise<CurrentLoginResult> {
  const http = useHttp();
  return async () => {
    return (await http.get<CurrentLoginResult>(
      "/current_login/",
      {}
    )) as CurrentLoginResult;
  };
}

// export function useDeleteUsuario(): (usuarioId: string) => Promise<any> {
//   const http = useHttp();
//   return async (usuarioId: string) => {
//     return await http.delete(`/usuario/${usuarioId}`, {});
//   };
// }

export function useDeleteUsuario(): (id: string) => Promise<any> {
  const http = useHttp();
  return async (id: string) => {
    return await http.delete(`/admin/usuario/${id}`, {});
  };
}
